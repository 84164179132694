/* eslint-disable */
import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
} from "../../store/functions";

class pageReport_EInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      BoldToken : [{
        Token : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImluZm9Ac2hpbmV3ZWxsaW5ub3ZhdGlvbi5jb20iLCJuYW1laWQiOiIxIiwidW5pcXVlX25hbWUiOiIzMTZiYmI3Ny1kOWIxLTQwNWYtYWRiYy01YzYyN2I0M2YwOWQiLCJJUCI6IjQ5LjQzLjE3OS4xMjEiLCJpc3N1ZWRfZGF0ZSI6IjE2ODQyMzI2NzAiLCJuYmYiOjE2ODQyMzI2NzAsImV4cCI6MTY4NDgzNzQ3MCwiaWF0IjoxNjg0MjMyNjcwLCJpc3MiOiJodHRwOi8vc2hpbmV3ZWxsc29mdHRlY2guY28uaW46NTA2OTMvcmVwb3J0aW5nL3NpdGUvc2l0ZTEiLCJhdWQiOiJodHRwOi8vc2hpbmV3ZWxsc29mdHRlY2guY28uaW46NTA2OTMvcmVwb3J0aW5nL3NpdGUvc2l0ZTEifQ.-s1nlWRTt9C774XiXS1sWIGM14mTnmdnl92VnjV75JM'
      }],
      IsIRN : false
  
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "City";
    this.modalTitle = "Company Details";
    this.rtPage_Add = "AddCity";
    this.rtPage_Edit = "EditCity";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/City";

    //Event Binding

  }
  componentDidMount() {

    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
  }
  
  
  render() {

    var parameters = [{
      name: 'IsIRN',
      values: [this.state.IsIRN]
      }
    ];
    return (
      <div className="page-content" style={{height:'700px'}}>
      <BoldReportViewerComponent id="reportviewer-container"
    reportServiceUrl = {'http://shinewellinnovation.com:59357/reporting/reportservice/api/Viewer'}
    reportServerUrl= {'http://shinewellinnovation.com:59357/reporting/api/site/site1'}
    serviceAuthorizationToken = {'bearer '+this.state.BoldToken[0].Token}
    reportPath = {'/TrueCartoons/EInvoiceReport'}
    parameters = {parameters}
    >
    </BoldReportViewerComponent>
      </div>
    );
  }
}
export default compose(container)(pageReport_EInvoice);
