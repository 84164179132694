import { API_WEB_URLS } from "constants/constAPI";
import { element } from "prop-types";
import React, { useEffect, useRef } from "react";
import Select from "react-select";

function TableRows({
  rowsData,
  deleteTableRows,
  handleChange,
  itemType,
  ply,
  printdesc,
  calculationtype,
  addTableRows,
  plyquantity,
  Unit,
  CustomerId,
}) {
  const Code = useRef([]);
  const Item = useRef([]);

  const Plys = useRef([]);

  const Lengths = useRef([]);
  const LengthSoots = useRef([]);
  const Widths = useRef([]);
  const WidthSoots = useRef([]);
  const Heights = useRef([]);
  const HeightSoots = useRef([]);
  const CalculationTypes = useRef([]);
  const Qts = useRef([]);
  const RmgQtys = useRef([]);
  const Rates = useRef([]);
  const PrintDescs = useRef([]);
  const PrintRates = useRef([]);
  const Amounts = useRef([]);
  const Adds = useRef([]);
  const Deletes = useRef([]);
  const Descriptions = useRef([]);

  const handleFocus = event => event.target.select();

  const handler2 = (index, evnt, indo) => {
    if (evnt.key == "Enter") {
      const rowsInput = [...rowsData];

      if (
        rowsInput[indo]["ItemType"] == 33 ||
        rowsInput[indo]["ItemType"] == "33"
      ) {
        if (index == "Code") Code.current[indo].focus();
        if (index == "Items") Item.current[indo].focus();
        else if (index == "Plys") Plys.current[indo].focus();
        else if (index == "Lengths") Lengths.current[indo].focus();
        else if (index == "LengthSoots") LengthSoots.current[indo].focus();
        else if (index == "Widths") Widths.current[indo].focus();
        else if (index == "WidthSoots") WidthSoots.current[indo].focus();
        else if (index == "Heights") Qts.current[indo].focus();
        else if (index == "RmgQtys") RmgQtys.current[indo].focus();
        else if (index == "Rates") Rates.current[indo].focus();
        else if (index == "PrintDescs") PrintDescs.current[indo].focus();
        else if (index == "PrintRates") PrintRates.current[indo].focus();
        else if (index == "Amounts") Amounts.current[indo].focus();
        else if (index == "Adds") Adds.current[indo].focus();
        else if (index == "Deletes") Deletes.current[indo].focus();
        else if (index == "Descriptions") Descriptions.current[indo].focus();
      } else {
        if (evnt.target.name == "Ply") {
          if (
            rowsInput[indo]["ItemType"] != "1" &&
            rowsInput[indo]["ItemType"] != "9"
          ) {
            if (index == "Code") Code.current[indo].focus();
            if (index == "Items") Item.current[indo].focus();
            else if (index == "Plys") Plys.current[indo].focus();
            else if (index == "Lengths") Qts.current[indo].focus();
            else if (index == "RmgQtys") RmgQtys.current[indo].focus();
            else if (index == "Rates") Rates.current[indo].focus();
            else if (index == "PrintDescs") PrintDescs.current[indo].focus();
            else if (index == "PrintRates") PrintRates.current[indo].focus();
            else if (index == "Amounts") Amounts.current[indo].focus();
            else if (index == "Adds") Adds.current[indo].focus();
            else if (index == "Deletes") Deletes.current[indo].focus();
            else if (index == "Descriptions")
              Descriptions.current[indo].focus();
          } else {
            if (index == "Code") Code.current[indo].focus();
            if (index == "Items") Item.current[indo].focus();
            else if (index == "Plys") Plys.current[indo].focus();
            else if (index == "Lengths") Lengths.current[indo].focus();
            else if (index == "LengthSoots") LengthSoots.current[indo].focus();
            else if (index == "Widths") Widths.current[indo].focus();
            else if (index == "WidthSoots") WidthSoots.current[indo].focus();
            else if (index == "Heights") Heights.current[indo].focus();
            else if (index == "HeightSoots") HeightSoots.current[indo].focus();
            else if (index == "CalculationTypes")
              CalculationTypes.current[indo].focus();
            else if (index == "Qts") Qts.current[indo].focus();
            else if (index == "RmgQtys") RmgQtys.current[indo].focus();
            else if (index == "Rates") Rates.current[indo].focus();
            else if (index == "PrintDescs") PrintDescs.current[indo].focus();
            else if (index == "PrintRates") PrintRates.current[indo].focus();
            else if (index == "Amounts") Amounts.current[indo].focus();
            else if (index == "Adds") Adds.current[indo].focus();
            else if (index == "Deletes") Deletes.current[indo].focus();
            else if (index == "Descriptions")
              Descriptions.current[indo].focus();
          }
        } else {
          if (index == "Code") Code.current[indo].focus();
          if (index == "Items") Item.current[indo].focus();
          else if (index == "Plys") Plys.current[indo].focus();
          else if (index == "Lengths") Lengths.current[indo].focus();
          else if (index == "LengthSoots") LengthSoots.current[indo].focus();
          else if (index == "Widths") Widths.current[indo].focus();
          else if (index == "WidthSoots") WidthSoots.current[indo].focus();
          else if (index == "Heights") Heights.current[indo].focus();
          else if (index == "HeightSoots") HeightSoots.current[indo].focus();
          else if (index == "CalculationTypes")
            CalculationTypes.current[indo].focus();
          else if (index == "Qts") Qts.current[indo].focus();
          else if (index == "RmgQtys") RmgQtys.current[indo].focus();
          else if (index == "Rates") Rates.current[indo].focus();
          else if (index == "PrintDescs") PrintDescs.current[indo].focus();
          else if (index == "PrintRates") PrintRates.current[indo].focus();
          else if (index == "Amounts") Amounts.current[indo].focus();
          else if (index == "Adds") Adds.current[indo].focus();
          else if (index == "Deletes") Deletes.current[indo].focus();
          else if (index == "Descriptions") Descriptions.current[indo].focus();
        }
      }
      evnt.preventDefault();
    }
  };
  return rowsData.map((data, index) => {
    const options = [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      ,
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
    ];

    const {
      IsDirectInvoice,
      CodeNo,
      ItemType,
      Ply,
      Length,
      LengthSoot,
      Width,
      WidthSoot,
      Height,
      HeightSoot,
      CalculationType,
      Qty,
      RmgQty,
      Rate,
      PrintDesc,
      PrintRate,
      Amount,
      IsBatta,
      DescriptionL,
    } = data;
    return (
      <tr key={index}>
        <td>{index + 1}</td>

        <td>
          <input
            onKeyPress={evnt => handler2("Code", evnt, index)}
            type="checkbox"
            name="IsDirectInvoice"
            checked={IsDirectInvoice}
          ></input>
        </td>
        <td>
          <input
            className="form-control"
            type="text"
            style={{ width: "80px", height: "36px" }}
            name="CodeNo"
            //  ref ={Code[index]}
            ref={el => (Code.current[index] = el)}
            onKeyPress={evnt => handler2("Items", evnt, index)}
            value={CodeNo}
            onChange={evnt => handleChange(index, evnt)}
          />{" "}
        </td>

        <td>
          {/* <Select
      
              defaultValue={{ label: "Select", value: 0 }}
              options={options}
            /> */}

          {API_WEB_URLS.Customer == 3 ||
          API_WEB_URLS.Customer == 1 ||
          API_WEB_URLS.Customer == 7 ||
          API_WEB_URLS.Customer == 8 ? (
            <select
              style={{ width: "213px" }}
              ref={el => (Item.current[index] = el)}
              onKeyPress={evnt => handler2("Plys", evnt, index)}
              value={ItemType}
              name="ItemType"
              onChange={evnt => handleChange(index, evnt)}
              className="form-select"
            >
              <option value={0} defaultValue label={"Select"} />
              {itemType
                ? itemType.map((option, key) => (
                    <option
                      key={option.Id}
                      id={option.IGST}
                      value={option.Id}
                      label={option.Name}
                    />
                  ))
                : null}
            </select>
          ) : (
            <select
              style={{ width: "213px" }}
              ref={el => (Item.current[index] = el)}
              onKeyPress={evnt => handler2("Descriptions", evnt, index)}
              value={ItemType}
              name="ItemType"
              onChange={evnt => handleChange(index, evnt)}
              className="form-select"
            >
              <option value={0} defaultValue label={"Select"} />
              {itemType
                ? itemType.map((option, key) => (
                    <option
                      key={option.Id}
                      id={option.IGST}
                      value={option.Id}
                      label={option.Name}
                    />
                  ))
                : null}
            </select>
          )}
        </td>

        {API_WEB_URLS.Customer == 2 ||
        API_WEB_URLS.Customer == 4 ||
        API_WEB_URLS.Customer == 5 ||
        API_WEB_URLS.Customer == 6 ||
        API_WEB_URLS.Customer == 9 ||
        API_WEB_URLS.Customer == 10 ? (
          <td>
            <input
              className="form-control"
              type="text"
              style={{ width: "200px", height: "36px" }}
              name="DescriptionL"
              ref={el => (Descriptions.current[index] = el)}
              onKeyPress={evnt => handler2("Plys", evnt, index)}
              value={DescriptionL}
              onChange={evnt => handleChange(index, evnt)}
            />{" "}
          </td>
        ) : null}

        {IsBatta ? (
          <td>
            <input
              type="text"
              style={{ width: "100px" }}
              value={Ply}
              ref={el => (Plys.current[index] = el)}
              onKeyPress={evnt => handler2("Lengths", evnt, index)}
              name="Ply"
              onChange={evnt => handleChange(index, evnt)}
              className="form-control"
            />
          </td>
        ) : (
          <td>
            <select
              style={{ width: "140px" }}
              value={Ply}
              ref={el => (Plys.current[index] = el)}
              onKeyPress={evnt => handler2("Lengths", evnt, index)}
              name="Ply"
              onChange={evnt => handleChange(index, evnt)}
              className="form-select"
            >
              <option value={-1} defaultValue label={"Select"} />
              {ply
                ? ply.map((option, key) => (
                    <option
                      key={option.Id}
                      id={option.Rate}
                      value={option.Id}
                      label={option.Name}
                    />
                  ))
                : null}
            </select>
          </td>
        )}

        <td>
          <input
            style={{ width: "60px", height: "36px" }}
            ref={el => (Lengths.current[index] = el)}
            onKeyPress={evnt => handler2("LengthSoots", evnt, index)}
            className="form-control "
            type="text"
            onFocus={handleFocus}
            name="Length"
            value={Length}
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <input
            style={{ width: "50px", height: "36px" }}
            ref={el => (LengthSoots.current[index] = el)}
            onKeyPress={evnt => handler2("Widths", evnt, index)}
            className="form-control "
            type="text"
            onFocus={handleFocus}
            name="LengthSoot"
            value={LengthSoot}
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <input
            style={{ width: "60px", height: "36px" }}
            ref={el => (Widths.current[index] = el)}
            onKeyPress={evnt => handler2("WidthSoots", evnt, index)}
            className="form-control "
            type="text"
            name="Width"
            onFocus={handleFocus}
            value={Width}
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <input
            style={{ width: "50px", height: "36px" }}
            ref={el => (WidthSoots.current[index] = el)}
            onKeyPress={evnt => handler2("Heights", evnt, index)}
            className="form-control"
            type="text"
            name="WidthSoot"
            onFocus={handleFocus}
            value={WidthSoot}
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <input
            style={{ width: "60px", height: "36px" }}
            ref={el => (Heights.current[index] = el)}
            onKeyPress={evnt => handler2("HeightSoots", evnt, index)}
            className="form-control"
            type="text"
            name="Height"
            onFocus={handleFocus}
            value={Height}
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <input
            style={{ width: "50px", height: "36px" }}
            ref={el => (HeightSoots.current[index] = el)}
            onKeyPress={evnt => handler2("CalculationTypes", evnt, index)}
            className="form-control "
            type="text"
            name="HeightSoot"
            onFocus={handleFocus}
            value={HeightSoot}
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <select
            ref={el => (CalculationTypes.current[index] = el)}
            onKeyPress={evnt => handler2("Qts", evnt, index)}
            name="CalculationType"
            value={CalculationType}
            onChange={evnt => handleChange(index, evnt)}
            style={{ width: "100px" }}
            className="form-select"
          >
            <option value={-1} defaultValue label={"Select"} />
            {calculationtype
              ? calculationtype.map((option, key) => (
                  <option
                    key={option.id}
                    id={option.PinningFleb}
                    value={option.Id}
                    label={option.Name}
                  />
                ))
              : null}
          </select>
        </td>

        <td>
          <input
            style={{ width: "60px", height: "36px" }}
            ref={el => (Qts.current[index] = el)}
            onKeyPress={evnt => handler2("Rates", evnt, index)}
            className="form-control "
            type="text"
            name="Qty"
            onFocus={handleFocus}
            value={Qty}
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>
        {API_WEB_URLS.Customer == 2 ? (
          <td>
            <select
              ref={el => (RmgQtys.current[index] = el)}
              onKeyPress={evnt => handler2("Rates", evnt, index)}
              style={{ width: "100px" }}
              name="RmgQty"
              value={RmgQty}
              onChange={evnt => handleChange(index, evnt)}
              className="form-select"
            >
              <option value={0} defaultValue label={"Select"} />
              {Unit
                ? Unit.map((option, key) => (
                    <option
                      key={option.Id}
                      id={option.Id}
                      value={option.Id}
                      label={option.Name}
                    />
                  ))
                : null}
            </select>
          </td>
        ) : null}

        <td>
          <input
            style={{ width: "80px", height: "36px" }}
            ref={el => (Rates.current[index] = el)}
            onKeyPress={evnt => handler2("PrintDescs", evnt, index)}
            className="form-control "
            type="text"
            value={Rate}
            name="Rate"
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <select
            ref={el => (PrintDescs.current[index] = el)}
            onKeyPress={evnt => handler2("PrintRates", evnt, index)}
            style={{ width: "100px" }}
            name="PrintDesc"
            value={PrintDesc}
            onChange={evnt => handleChange(index, evnt)}
            className="form-select"
          >
            <option value={-1} defaultValue label={"Select"} />
            {printdesc
              ? printdesc.map((option, key) => (
                  <option
                    key={option.Id}
                    id={option.Rate}
                    value={option.Id}
                    label={option.Name}
                  />
                ))
              : null}
          </select>
        </td>

        <td>
          <input
            style={{ width: "60px", height: "36px" }}
            ref={el => (PrintRates.current[index] = el)}
            onKeyPress={evnt => handler2("Deletes", evnt, index)}
            className="form-control "
            type="text"
            value={PrintRate}
            onFocus={handleFocus}
            name="PrintRate"
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <input
            style={{ width: "100px", height: "36px" }}
            ref={el => (Amounts.current[index] = el)}
            onKeyPress={evnt => handler2("Deletes", evnt, index)}
            className="form-control "
            type="text"
            value={Amount}
            name="Amount"
            disabled
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <button
            ref={el => (Deletes.current[index] = el)}
            onKeyPress={evnt => handler2("Adds", evnt, index)}
            className="btn btn-outline-danger"
            type="button"
            onClick={() => deleteTableRows(index)}
          >
            x
          </button>
        </td>
        <td>
          <button
            ref={el => (Adds.current[index] = el)}
            onKeyPress={evnt => handler2("Code", evnt, index + 1)}
            type="button"
            className="btn btn-outline-success"
            onClick={addTableRows}
          >
            +
          </button>
        </td>
      </tr>
    );
  });
}
export default TableRows;
