import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Masters
import pageAddEdit_ItemType from "pages/Masters/pageAddEdit_ItemType";
import pageList_ItemType from "pages/Masters/pageList_ItemType";
import pageList_Ply from "pages/Masters/pageList_Ply";
import pageAddEdit_Ply from "pages/Masters/pageAddEdit_Ply";
import PlyAdjustment from "pages/Masters/PlyAdjustment";
import pageList_PlyGroup from "pages/Masters/pageList_PlyGroup";
import pageAddEdit_PlyGroup from "pages/Masters/pageAddEdit_PlyGroup";
import pageList_PaperType from "pages/Masters/pageList_PaperType";
import pageAddEdit_PaperType from "pages/Masters/pageAddEdit_PaperType";
import pageList_PrintDiscription from "pages/Masters/pageList_PrintDiscription";
import pageAddEdit_PrintDiscription from "pages/Masters/pageAddEdit_PrintDiscription";
import pageList_Type from "pages/Masters/pageList_Type";
import pageAddEdit_Type from "pages/Masters/pageAddEdit_Type";
import pageList_Tax from "pages/Masters/pageList_Tax";
import pageAddEdit_Tax from "pages/Masters/pageAddEdit_Tax";
import pageList_GSTGroupType from "pages/Masters/pageList_GSTGroupType";
import pageAddEdit_GSTGroupType  from "pages/Masters/pageAddEdit_GSTGroupType";
import pageList_Ledger from "pages/Masters/pageList_Ledger";
import pageAddEdit_Ledger  from "pages/Masters/pageAddEdit_Ledger";
import pageList_City from "pages/Masters/pageList_City";
import pageAddEdit_City  from "pages/Masters/pageAddEdit_City";
import pageList_State from "pages/Masters/pageList_State";
import pageAddEdit_State  from "pages/Masters/pageAddEdit_State";
import pageList_Country from "pages/Masters/pageList_Country";
import pageAddEdit_Country  from "pages/Masters/pageAddEdit_Country";
import pageList_ConsigneeAddress from "pages/Masters/pageList_ConsigneeAddress";
import pageAddEdit_ConsigneeAddress  from "pages/Masters/pageAddEdit_ConsigneeAddress";
import pageList_CalculationType from "pages/Masters/pageList_CalculationType";
import pageList_HeightCalculation from "pages/Masters/pageList_HeightCalculation";
import pageAddEdit_HeightCalculation  from "pages/Masters/pageAddEdit_HeightCalculation";
import pageList_LengthCalculation from "pages/Masters/pageList_LengthCalculation";
import pageAddEdit_LengthCalculation  from "pages/Masters/pageAddEdit_LengthCalculation";
import pageList_ManageUser from "pages/Masters/pageList_ManageUser";

import pageAddEdit_UserMaster  from "pages/Masters/pageAddEdit_UserMaster";
import pageAddEdit_PrintPartyDiscription from "pages/Masters/pageAddEdit_PrintPartyDiscription";
import pageAddEdit_PlyPartyDiscription from "pages/Masters/pageAddEdit_PlyPartyDiscription";
import pageAddEdit_GlobalOptions from "pages/Masters/pageAddEdit_GlobalOptions";
import pageAddEdit_SheetSizing from "pages/Transaction/pageAddEdit_SheetSizing";
import pageReport_EInvoice from "pages/Masters/pageReport_EInvoice";
import pageList_Unit from "pages/Masters/pageList_Unit";
import pageAddEdit_UnitMaster from "pages/Masters/pageAddEdit_UnitMaster";
import pageAddEdit_CalculationType from "pages/Masters/pageAddEdit_CalculationType";





import ChangePassword from "../pages/Authentication/ChangePassword";



//Transaction
import pageAddEdit_SaleInvoice from "pages/Transaction/pageAddEdit_SaleInvoice";
import pageAddEdit_SaleInvoice_new from "pages/Transaction/pageAddEdit_SaleInvoice_new";
import pageAddEdit_EstimateInvoice from "pages/Transaction/pageAddEdit_EstimateInvoice";
import pageAddEdit_GroupMaster from "pages/Masters/pageAddEdit_GroupMaster";
import pageList_Group from "pages/Masters/pageList_Group";
import pageList_Company from "pages/Masters/pageList_Company";
import pageAddEdit_CompanyMaster from "pages/Masters/pageAddEdit_CompanyMaster";
import pageAddEdit_Voucher from "pages/Voucher/pageAddEdit_Voucher";
import pageAddEdit_Cash from "pages/Voucher/pageAddEdit_Cash";
import pageAddEdit_VoucherDouble from "pages/Voucher/pageAddEdit_VoucherDouble";
import reports_BalanceSheet from "pages/Reports/reports_BalanceSheet";
import reports_BalanceLedger from "pages/Reports/reports_BalanceLedger";
import pageAddEdit_SubLedger from "pages/Masters/pageAddEdit_SubLedger";
import pageList_Voucher from "pages/Voucher/pageList_Voucher";
import reports_PAndL from "pages/Reports/reports_PAndL";
import pageList_Usersmaster from "pages/Voucher/pageList_Usersmaster";
import DemonishingReport from "pages/Reports/DemonishingReport";
import pageList_SubGroup from "pages/Voucher/pageList_SubGroup";
import OTP from "pages/Authentication/OTP";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/changepassword", component: ChangePassword },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },


  //Master

  //AddTest
  { path: "/AddItemType", component: pageAddEdit_ItemType },

  { path: "/EInvoiceReport", component: pageReport_EInvoice },

  { path: "/SheetSizing", component: pageAddEdit_SheetSizing },

  //EditTest
  { path: "/EditItemType/:id", component: pageAddEdit_ItemType },

  //TestList
  { path: "/ItemType", component: pageList_ItemType},

  { path: "/pandl", component: reports_PAndL},

  //PlyList
  { path: "/Ply", component: pageList_Ply },

  //AddPly
  { path: "/AddPly", component: pageAddEdit_Ply },

  //EditPly
  { path: "/EditPly/:id", component: pageAddEdit_Ply },

  //PlyAdjustment
  { path: "/PlyAdjustment", component: PlyAdjustment },

  //PlyGroup
  { path: "/PlyGroup", component: pageList_PlyGroup},
  { path: "/subgroup", component: pageList_SubGroup},

  //AddPly
  { path: "/AddPlyGroup", component: pageAddEdit_PlyGroup },

  //EditPly
  { path: "/EditPlyGroup/:id", component: pageAddEdit_PlyGroup},

  //PaperType
  { path: "/PaperType", component: pageList_PaperType},

  //AddPaperType
  { path: "/AddPaperType", component: pageAddEdit_PaperType},

  //EditPaperType
  { path: "/EditPaperType/:id", component: pageAddEdit_PaperType},

  //PrintDiscription
  { path: "/PrintDiscription/", component: pageList_PrintDiscription},

  //AddPrintDiscription
  { path: "/AddPrintDiscription", component: pageAddEdit_PrintDiscription},

  //EditPrintDiscription
  { path: "/EditPrintDiscription/:id", component: pageAddEdit_PrintDiscription},

  //Type
  { path: "/Type", component: pageList_Type},

  //AddType
  { path: "/AddType", component: pageAddEdit_Type},

  //EditType
  { path: "/EditType/:id", component: pageAddEdit_Type},
  //

  //Tax
  { path: "/Tax", component: pageList_Tax},

  //AddTax
  { path: "/AddTax", component: pageAddEdit_Tax},

  //EditTax
  { path: "/EditTax/:id", component: pageAddEdit_Tax},


  { path: "/EditCalculationType/:id", component: pageAddEdit_CalculationType},

  //GSTGroupType
  { path: "/GSTGroupType", component: pageList_GSTGroupType},

  //AddGSTGroupType
  { path: "/AddGSTGroupType", component: pageAddEdit_GSTGroupType},

  //EditGroupType
  { path: "/EditGSTGroupType/:id", component: pageAddEdit_GSTGroupType},

  //Ledger
  { path: "/Ledger", component: pageList_Ledger},
  { path: "/SubLedger", component: pageAddEdit_SubLedger},
  { path: "/EditSubGroup/:id", component: pageAddEdit_SubLedger},

  //AddLedger
  { path: "/AddLedger", component: pageAddEdit_Ledger},
  
  //EditLedger
  { path: "/EditLedger/:id", component: pageAddEdit_Ledger},
  { path: "/Voucher", component: pageList_Voucher},

  //AddLedger
  { path: "/AddVoucher", component: pageAddEdit_Voucher},
  
  //EditLedger
  { path: "/EditVoucher/:id", component: pageAddEdit_Voucher},
  //voucher
  // { path: "/VoucherMaster", component: pageAddEdit_Voucher},
  { path: "/VoucherNew", component: pageAddEdit_VoucherDouble},
  { path: "/cash", component: pageAddEdit_Cash},
  { path: "/balancesheet", component: reports_BalanceSheet},
  { path: "/balanceLedger", component: reports_BalanceLedger},
  { path: "/balanceLedger/:id", component: reports_BalanceLedger},
  { path: "/demonishingReport", component: DemonishingReport},
  { path: "/UserMaster", component: pageAddEdit_UserMaster},
  { path: "/users", component: pageList_Usersmaster},
  { path: "/EditUserMaster/:id", component: pageAddEdit_UserMaster},


  { path: "/AddUnit", component: pageAddEdit_UnitMaster},
  
  //EditLedger
  { path: "/EditUnit/:id", component: pageAddEdit_UnitMaster},
  //groupMaster
  { path: "/GroupMaster", component: pageList_Group},
  { path: "/AddGroup", component: pageAddEdit_GroupMaster},
  { path: "/EditGroup/:id", component: pageAddEdit_GroupMaster},
  //CompanyMaster
  { path: "/CompanyMaster", component: pageList_Company},
  { path: "/AddCompany", component: pageAddEdit_CompanyMaster},
  { path: "/EditCompany/:id", component: pageAddEdit_CompanyMaster},
   
 //City
 { path: "/City", component: pageList_City},

 //AddCity
 { path: "/AddCity", component: pageAddEdit_City},

 //EditCity
 { path: "/EditCity/:id", component: pageAddEdit_City},

 //State
 { path: "/State", component: pageList_State},

 //AddState
 { path: "/AddState", component: pageAddEdit_State},

 //EditState
 { path: "/EditState/:id", component: pageAddEdit_State},

 //Country
 { path: "/Country", component: pageList_Country},

 //AddState
 { path: "/AddCountry", component: pageAddEdit_Country},

 //EditState
 { path: "/EditCountry/:id", component: pageAddEdit_Country},

 //ConsigneeAddress
 { path: "/ConsigneeAddress", component: pageList_ConsigneeAddress},

 //AddConsigneeAddress
 { path: "/AddConsigneeAddress", component: pageAddEdit_ConsigneeAddress},

 //EditConsigneeAddress
 { path:  "/EditConsigneeAddress/:id", component: pageAddEdit_ConsigneeAddress},

 //CalculationType
 { path: "/CalculationType", component: pageList_CalculationType},
 
 //HeightCalculation
 { path: "/HeightCalculation", component: pageList_HeightCalculation},

 //AddHeightCalculation
 { path: "/AddHeightCalculation", component: pageAddEdit_HeightCalculation},

 //EditHeightCalculation
 { path:  "/EditHeightCalculation/:id", component: pageAddEdit_HeightCalculation},

 //LengthCalculation
 { path: "/LengthCalculation", component: pageList_LengthCalculation},

 //AddLengthCalculation
 { path: "/AddLengthCalculation", component: pageAddEdit_LengthCalculation},

 //EditLengthCalculation
 { path:  "/EditLengthCalculation/:id", component: pageAddEdit_LengthCalculation},

 //ManageUser
 { path: "/ManageUser", component: pageList_ManageUser},

 //UserMaster
//  { path: "/UserMaster", component: pageList_UserMaster},

 { path: "/UnitMaster", component: pageList_Unit},

 //AddUserMaster
 { path: "/AddUserMaster", component: pageAddEdit_UserMaster},

 //EditUserMaster
 { path:  "/EditUserMaster/:id", component: pageAddEdit_UserMaster},

 
 //PrintPartyDiscription
 { path:  "/PrintPartyDiscription", component: pageAddEdit_PrintPartyDiscription},
 
 //PrintPartyDiscription
 { path:  "/PrintPartyDiscription", component: pageAddEdit_PrintPartyDiscription},

 //PlyPartyDiscription
 { path:  "/PlyPartyDiscription", component: pageAddEdit_PlyPartyDiscription},
 
 //PlyPartyDiscription
 { path:  "/PlyPartyDiscription", component: pageAddEdit_PlyPartyDiscription},


  
  //Transaction
  
  { path: "/addinvoice", component: pageAddEdit_SaleInvoice },
  { path: "/addinvoice_new", component: pageAddEdit_SaleInvoice_new },
  { path: "/estimateinvoice", component: pageAddEdit_EstimateInvoice },


  //help
  { path: "/globaloptions", component: pageAddEdit_GlobalOptions },
  

];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/otp", component: OTP },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
