import React  , {useRef} from "react";





function TableRowsTax({rowsData }) {

  
    return(
        
        rowsData.map((data, index)=>{
            const {tax , amount}= data;
            return(
                <tr key={index}>

                    <td><input
                          className="form-control form-control-lg"
                          type="text"
                          style={{"width" : "80px","margin":'0px',"height":'5px'}}
                          name="tax"
                         
                         disabled
                          value={tax} 
                        /> </td>


                            <td><input style={{"width" : "100px","margin":'0px',"height":'5px'}}
                             disabled
                          className="form-control form-control-lg"
                          type="text"
                          name="amount"
                          value={amount}
                       
                        />
                         </td>

                         
            </tr>
            )
        })
   
    )
    
}
export default TableRowsTax;