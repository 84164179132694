const BASE = window.MANIFEST_DATA.data[0].BaseUrl;
const CustomerId = window.MANIFEST_DATA.data[0].CustomerId;


export const API_WEB_URLS = {
  // BASE: "http://apitruecartoons.shinewellsofttech.co.in/api/V1/",
  //  BASE: "http://apicgshreenath.shinewellsofttech.co.in/api/V1/",
  // BASE: "http://apianna.shinewellsofttech.co.in/api/V1/",
  // BASE: "http://apiusha.shinewellsofttech.co.in/api/V1/",
  // BASE: "http://apiankit.shinewellsofttech.co.in/api/V1/",
  // BASE: "http://apishanti.shinewellsofttech.co.in/api/V1/",

  // BASE: "http://apicgdemo.shinewellsofttech.co.in/api/V1/",

  // BASE: "http://apicgkrishna.shinewellsofttech.co.in/api/V1/",
  //BASE: "https://localhost:44362/api/V1/",
  // BASE: "https://localhost:44362/api/V1/",
    BASE: "https://api.bhuwal.in/api/V1/",
  // BASE: "https://localhost:44379/api/V1/",
  LOGIN: "Authentication/Login",
  //
  MASTER: "Masters",
  MASTER_COUNTRY: "CountryMaster",

  //1 TrueCartoons
  //2 Shreenath
  //3 Anna
  //4 Usha
  //5 Ankit
  //6 Shanti
  //7 Demo
  //8 Krishna
  //9 Sakshi
  //10 Shree

  Customer: CustomerId,

  //
  BalanceSheet:'BalanceSheet',
  BalanceLedger:'GetLedger',
  GetVouchers :'GetVouchers',
  DemonishingReport:'ctlDemonishingReport',
  BalanceSheetAssest:'BalanceSheetAssest',
  INVOICEL: "InvoiceL",
  INVOICEH: "InvoiceH",
  INVOICETAX: "InvoiceTax",
  ESTIMATEL: "EstimateL",
  ESTIMATEH: "EstimateH",
  ESTIMATETAX: "EstimateTax",
  INVOICECHARGE: "InvoiceCharge",
  INVOICETAXABLE: "InvoiceTaxableAmount",

  ITEMTYPEMASTER: "ItemTypeMaster",
  Ply: "Ply",
  AddEdit: "AddEdit_Master",
  UnitMaster : 'UnitMaster',
  GroupMaster : 'GroupMaster',
  CompanyMaster : 'CompanyMaster',
  Unit: "UnitMaster",
  State: "State",
  City: "City",
  SubLedger: "SubLedger",

  CalculationType: "CalculationType",
  PaperType: "PaperType",
  ConAdd: "ConAdd",
  HeightCal: "HeightCal",
  LengthCal: "LengthCal",
  Tax: "Tax",
  Ledger: "Ledger",
  GSTGroupType: "GSTGroupType",
  User: "User",
  UserMaster: "UserMaster",
  GLOBALOPTIONS: "UpdateGlobalOptions",
  CHANGEPASSWORD: "ChangePassword",

  // PRODUCT_CATEGORY: "Masters/ProductCategory",
  // PRODUCT_BRAND: "Masters/ProductBrand",
  // PRODUCT_ATTRIBUTE: "Masters/ProductAttribute"
};
