import React, { Component } from "react";

// Import Breadcrumb
import { Row, Col, Card, CardBody, Button } from "reactstrap";
// Constants
import { API_WEB_URLS } from "../../constants/constAPI";
// Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
} from "../../store/functions";
import "../Masters/datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as XLSX from 'xlsx';

function getLastMonth() {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

class pageList_Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      formData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      page: 1,
      sizePerPage: 10,
      productData: [],
      openingBalance: {},
      closingBalance: {}
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Ledger";
    this.modalTitle = "Company Details";
    this.rtPage_Add = "AddLedger";
    this.rtPage_Edit = "EditLedger";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Ledger";
    this.API_URL_SAVE = API_WEB_URLS.DemonishingReport + "/0/token";

    // Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
  }

  exportToExcel = () => {


    const orderAndFilter = (arr) => {
      return arr.map((item) => {
  
       
        return {
          VoucherDate: item.VoucherDate,
          VoucherNo: item.VoucherNo,
          VoucherMode: item.VoucherMode,
          PartyName: item.PartyName,
          AccountNo: item.AccountNo,
          "500N": item['500'],
          "200N": item['200'],
          "100N": item['100'],
          "50N": item['50'],
          "20N": item['20'],
          "10N": item['10'],
          "Coin": item['Coin']
        
        }
      });
    };
    
    // Apply the order and filter function to your original array
    const orderedAndFilteredArray = orderAndFilter(this.state.gridData1);
    const worksheet = XLSX.utils.json_to_sheet(orderedAndFilteredArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DemonishingReport');
    XLSX.writeFile(workbook, `DemonishingReport.xlsx`);
  };

  componentDidMount() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    // Fn_FillListData(this.obj, "gridData", this.API_URL + "/Id/0");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.gridData !== this.state.gridData) {
      
    }
  }
  

  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }

  btnEdit_onClick(Id) {
    this.props.history.push(`${this.rtPage_Edit}/${Id}`, {});
  }

  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }

  btnSave_onClick(event, formData) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));

    this.setState({ FromDate: formData.FromDate });

    let vformData = new FormData();

    vformData.append("From", formData.FromDate);
    vformData.append("To", formData.ToDate);

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      this.API_URL_SAVE,
      "gridData1",
      true
    );
  }

  render() {
    const columns = [
      {
        dataField: "VoucherDate",
        text: "VoucherDate",
        sort: true,
      },
      {
        dataField: "VoucherNo",
        text: "VoucherNo",
        sort: true,
      },
      {
        dataField: "VoucherMode",
        text: "VoucherMode",
        sort: true,
      },
      {
        dataField: "PartyName",
        text: "PartyName",
        sort: true,
      },
      {
        dataField: "500",
        text: "500",
        sort: true,
      },
      {
        dataField: "200",
        text: "200",
        sort: true,
      },
      {
        dataField: "100",
        text: "100",
        sort: true,
      },
      {
        dataField: "50",
        text: "50",
        sort: true,
      },
      {
        dataField: "20",
        text: "20",
        sort: true,
      },
      {
        dataField: "10",
        text: "10",
        sort: true,
      },
      {
        dataField: "Coin",
        text: "Coin",
        sort: true,
      },
      {
        dataField: "Amount",
        text: "Amount",
        sort: true,
      },
      {
        dataField: "Narration",
        text: "Narration",
        sort: true,
      },
    ];




    const columns2 = [
      {
        dataField: "VoucherDate",
        text: "VoucherDate",
        sort: true,
      },
      {
        dataField: "VoucherNo",
        text: "VoucherNo",
        sort: true,
      },
      {
        dataField: "VoucherMode",
        text: "VoucherMode",
        sort: true,
      },
      {
        dataField: "PartyName",
        text: "PartyName",
        sort: true,
      },
      {
        dataField: "500",
        text: "500",
        sort: true,
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
        )
      },
      {
        dataField: "200",
        text: "200",
        sort: true,
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
        )
      },
      {
        dataField: "100",
        text: "100",
        sort: true,
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
        )
      },
      {
        dataField: "50",
        text: "50",
        sort: true,
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
        )
      },
      {
        dataField: "20",
        text: "20",
        sort: true,
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
        )
      },
      {
        dataField: "10",
        text: "10",
        sort: true,
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
        )
      },
      {
        dataField: "Coin",
        text: "Coin",
        sort: true,
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
        )
      },
      {
        dataField: "Amount",
        text: "Amount",
        sort: true,
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
        )
      },
      {
        dataField: "Narration",
        text: "Narration",
        sort: true,
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
        )
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.gridData.length, // replace later with size(customers),
      custom: true,
    };

    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <div className="page-content">
        <Row>
          <Col lg="12">
            <Card style={{ width: "100vw" }}>
              <CardBody>
                <div className="wizard clearfix">
                  <div className="content clearfix">
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.btnSave_onClick}
                    >
                      <Row>
                        <Col sm="1" className="mb-3">
                          <label htmlFor="tilldatee" className="col-form-label">
                            {" "}
                            From Date
                          </label>
                        </Col>
                        <Col sm="3" className="mb-3">
                          <AvField
                            name="FromDate"
                            label=""
                            value={
                              this.state.formData.FromDate === undefined
                                ? getCurrentDate()
                                : this.state.formData.FromDate
                            }
                            placeholder="From Date"
                            errorMessage="Select Date "
                            validate={{ required: { value: true } }}
                            type="date"
                            className="form-control"
                          />
                        </Col>
                        <Col sm="1" className="mb-3">
                          <label htmlFor="tilldatee" className="col-form-label">
                            {" "}
                            To Date
                          </label>
                        </Col>
                        <Col sm="3" className="mb-3">
                          <AvField
                            name="ToDate"
                            label=""
                            value={
                              this.state.formData.ToDate === undefined
                                ? getCurrentDate()
                                : this.state.formData.ToDate
                            }
                            placeholder="to Date"
                            errorMessage="Select Date "
                            validate={{ required: { value: true } }}
                            type="date"
                            className="form-control"
                          />
                        </Col>

                        <Col sm="3" className="mb-3">
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-1 waves-effect waves-light"
                          >
                            {" "}
                            View{" "}
                          </Button>
                          <Button
                            type="Button"
                            onClick={this.exportToExcel}
                            color="primary"
                            className="mr-1 waves-effect waves-light"
                          >
                            {" "}
                            Excel{" "}
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Opening Balance</h4>
                <div className="table-responsive">
                  <BootstrapTable
                    keyField={"id"}
                    data={[this.state.openingBalance]}
                    columns={columns2}
                    bordered={false}
                    striped={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <PaginationProvider
          pagination={paginationFactory({
            ...pageOptions,
            sizePerPageList: [
              { text: '25', value: 25 },
              { text: '50', value: 50 },
              { text: '100', value: 100 },
            ],
          })}
          keyField="id"
          columns={columns}
          data={this.state.gridData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={this.state.gridData}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"id"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title">Closing Balance</h4>
                <div className="table-responsive">
                  <BootstrapTable
                    keyField={"id"}
                    data={[this.state.closingBalance]}
                    columns={columns2}
                    bordered={false}
                    striped={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(container)(pageList_Ledger);
