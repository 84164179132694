import React  , {useRef} from "react";





function TableRowsCharges({rowsData  , Ledger, edit}) {

  


  const charge = useRef([]);
  const rat = useRef([]);


  
  const handler2 = (index ,evnt , indo) => {
  
     

    if (evnt.key == 'Enter') {
      if (index == 'Rate')
      rat.current.focus();
      evnt.preventDefault();
    }
      



};

    return(
        
        rowsData.map((data, index)=>{
            const {charges , amount}= data;
            return(
                <tr key={index}>

                    <td> <select ref={charge} onKeyPress={(evnt)=>(handler2('Rate' ,evnt))} onChange={(evnt)=>(edit(index, evnt))}   style={{"width" : "150px"}}  value={charges} name="charges"   className="form-select">
<option value={0} defaultValue label={"Select"} />
                                            {Ledger
                                              ? Ledger.map(
                                                  (option, key) => (
                                                    <option key={option.Id}  value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}

                                              
              </select>
 </td>


                            <td><input style={{"width" : "80px" , "height"  : "36px"}}
                             onChange={(evnt)=>(edit(index, evnt))}
                          className="form-control form-control-lg"
                          type="text"
                          name="amount"
                          value={amount}
                          ref={rat}
                       
                        />
                         </td>

                         
            </tr>
            )
        })
   
    )
    
}
export default TableRowsCharges;