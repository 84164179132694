import { API_WEB_URLS } from "constants/constAPI";
import { element } from "prop-types";
import React, { useEffect, useRef } from "react";
import Select from "react-select";

function TableRows_new({
  rowsData,
  deleteTableRows,
  handleChange,
  itemName,
  GroupMaster,
  UnitMaster,

  addTableRows,
  plyquantity,
  TaxName,
  CustomerId,
}) {
  const Code = useRef([]);
  const Groups = useRef([]);
  const Item = useRef([]);
  const Units = useRef([]);

 

  const Qtys = useRef([]);

  const Rates = useRef([]);
  const Mrps = useRef([]);
  const TaxNames = useRef([]);
  const Discs = useRef([]);

  const Amounts = useRef([]);
  const Adds = useRef([]);
  const Deletes = useRef([]);
 
  useEffect(()=>{
    console.log(rowsData)
  },[rowsData])
  const handleFocus = event => event.target.select();

  const handler2 = (index, evnt, indo) => {
    if (evnt.key == "Enter") {
      const rowsInput = [...rowsData];

    
        if (index == "Code") Code.current[indo].focus();
        if (index == "Items") Item.current[indo].focus();
        if (index == "Groups") Groups.current[indo].focus();
        if (index == "Units") Units.current[indo].focus();
        if (index == "Qtys") Qtys.current[indo].focus();
        else if (index == "Rates") Rates.current[indo].focus();
        else if (index == "Mrps") Mrps.current[indo].focus();
        else if (index == "Amounts") Amounts.current[indo].focus();
        else if (index == "TaxNames") TaxNames.current[indo].focus();
        else if (index == "Discs") Discs.current[indo].focus();
        else if (index == "Amounts") Amounts.current[indo].focus();
        else if (index == "Adds") Adds.current[indo].focus();
        else if (index == "Deletes") Deletes.current[indo].focus();
     
 
      evnt.preventDefault();
    }
  };
  return rowsData.map((data, index) => {
    

    const {
      IsDirectInvoice,
      CodeNo,
      ItemName,
      F_GroupMaster,
      Qty,
      F_UnitMaster,
      Rate,
      Disc,
      Amount,
      TaxName,
 
    } = data;
    return (
      <tr key={index}>
        <td>{index + 1}</td>

        {/* <td>
          <input
            onKeyPress={evnt => handler2("Code", evnt, index)}
            type="checkbox"
            name="IsDirectInvoice"
            checked={IsDirectInvoice}
          ></input>
        </td> */}
        <td>
          <input
            className="form-control"
            type="text"
            style={{ width: "60px", height: "36px" }}
            name="CodeNo"
            //  ref ={Code[index]}
            ref={el => (Code.current[index] = el)}
            onKeyPress={evnt => handler2("Items", evnt, index)}
            value={CodeNo}
            onChange={evnt => handleChange(index, evnt)}
          />{" "}
        </td>

        <td>
          <select
            style={{ width: "210px" }}
            ref={el => (Item.current[index] = el)}
            onKeyPress={evnt => handler2("Qtys", evnt, index)}
            value={F_GroupMaster}
            name="F_GroupMaster"
            onChange={evnt => handleChange(index, evnt)}
            className="form-select"
          >
            <option value={"-1"} defaultValue label={"Select"} />
            {GroupMaster
              ? GroupMaster.map((option, key) => (
                  <option
                    key={option.Id}
                    
                    value={option.Id}
                    label={option.Name}
                  />
                ))
              : null}
          </select>
        </td>
        <td>
          <select
            style={{ width: "210px" }}
            ref={el => (Item.current[index] = el)}
            onKeyPress={evnt => handler2("Qtys", evnt, index)}
            value={ItemName}
            name="ItemName"
            onChange={evnt => handleChange(index, evnt)}
            className="form-select"
          >
            <option value={"-1"} defaultValue label={"Select"} />
            {itemName
              ? itemName.map((option, key) => (
                  <option
                    key={option.Id}
                    id={option.IGST}
                    value={option.Id}
                    label={option.ItemName}
                  />
                ))
              : null}
          </select>
        </td>
        <td>
          <select
            style={{ width: "80px",padding:0, height: "36px" }}
            ref={el => (Item.current[index] = el)}
            onKeyPress={evnt => handler2("Qtys", evnt, index)}
            value={F_UnitMaster}
            name="F_UnitMaster"
            onChange={evnt => handleChange(index, evnt)}
            className="form-select"
          >
            <option value={"-1"} defaultValue label={"Select"} />
            {UnitMaster
              ? UnitMaster.map((option, key) => (
                  <option
                    key={option.Id}
                    
                    value={option.Id}
                    label={option.Name}
                  />
                ))
              : null}
          </select>
        </td>

        <td>
          <input
            style={{ width: "50px", height: "36px" }}
            ref={el => (Qtys.current[index] = el)}
            onKeyPress={evnt => handler2("Rates", evnt, index)}
            className="form-control "
            type="text"
            name="Qty"
            onFocus={handleFocus}
            value={Qty}
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

      
        <td>
          <input
            style={{ width: "60px", height: "36px" }}
            ref={el => (Rates.current[index] = el)}
            onKeyPress={evnt => handler2("Discs", evnt, index)}
            className="form-control "
            type="text"
            value={Rate}
            name="Rate"
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>
        <td>
          <input
            style={{ width: "40px", height: "36px" }}
            ref={el => (Discs.current[index] = el)}
            onKeyPress={evnt => handler2("Amounts", evnt, index)}
            className="form-control "
            type="text"
            value={Disc}
            name="Disc"
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>

        <td>
          <input
            style={{ width: "80px", height: "36px" }}
            ref={el => (Amounts.current[index] = el)}
            onKeyPress={evnt => handler2("TaxNames", evnt, index)}
            className="form-control "
            type="text"
            value={Amount}
            name="Amount"
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>
        <td>
        <input
            style={{ width: "80px", height: "36px", padding:0 }}
            ref={el => (TaxNames.current[index] = el)}
            onKeyPress={evnt => handler2("Deletes", evnt, index)}
            className="form-control "
            type="text"
            value={TaxName}
            name="TaxName"
            disabled
            onChange={evnt => handleChange(index, evnt)}
          />
        </td>
        <td>
          <button
            ref={el => (Deletes.current[index] = el)}
            onKeyPress={evnt => handler2("Adds", evnt, index)}
            className="btn btn-outline-danger"
            type="button"
            onClick={() => deleteTableRows(index)}
          >
            x
          </button>
        </td>
        <td>
          <button
            ref={el => (Adds.current[index] = el)}
            onKeyPress={evnt => handler2("Code", evnt, index + 1)}
            type="button"
            className="btn btn-outline-success"
            onClick={addTableRows}
          >
            +
          </button>
        </td>
      </tr>
    );
  });
}
export default TableRows_new;
