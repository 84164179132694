import React, { Component } from "react";

// Import Breadcrumb
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
// Constants
import { API_WEB_URLS } from "../../constants/constAPI";
// Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
} from "../../store/functions";
import "../Masters/datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

class pageList_Voucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      page: 1,
      sizePerPage: 10,
      productData: [],
      FromDate: getCurrentDate(),
      ToDate: getCurrentDate(),
      noDataFound: false, // New state property
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Ledger";
    this.modalTitle = "Company Details";
    this.rtPage_Add = "AddVoucher";
    this.rtPage_Edit = "EditVoucher";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/GetVoucherHDetails";
    this.API_URL_SAVE = API_WEB_URLS.GetVouchers + "/0/token";
    // Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    Fn_FillListData(this.obj, "gridData", this.API_URL + "/Id/0");
  }

  handleDateChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit() {
    // Use this.state.FromDate and this.state.ToDate in your submit logic
    console.log("From Date:", this.state.FromDate);
    console.log("To Date:", this.state.ToDate);

    let vformData = new FormData();
    vformData.append("From", this.state.FromDate);
    vformData.append("To", this.state.ToDate);

    try {
      const response = await Fn_GetReport(
        this,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        "gridData",
        true
      );

      if (response && response.length === 0) {
        this.setState({ noDataFound: true, gridData: [] });
      } else {
        this.setState({ noDataFound: false, gridData: response });
      }

      console.log("Reports fetched successfully");
    } catch (error) {
      this.setState({ noDataFound: true });
      // Handle any errors from the report fetching
      console.error("Error fetching reports:", error);
    }
  }

  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }

  btnEdit_onClick(Id) {
    this.props.history.push(`${this.rtPage_Edit}/${Id}`, {});
  }

  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }

  render() {
    const columns = [
      {
        dataField: "VoucherNo",
        text: "VoucherNo",
        sort: true,
      },
      {
        dataField: "VoucherDate",
        text: "VoucherDate",
        sort: true,
      },
      {
        dataField: "Narration",
        text: "Narration",
        sort: true,
      },
      {
        dataField: "TotalAmount",
        text: "TotalAmount",
        sort: true,
      },
      {
        dataField: "Remarks",
        text: "Remarks",
        sort: true,
      },
      {
        dataField: "DoneBy",
        text: "DoneBy",
        sort: true,
      },
      {
        dataField: "Edit",
        text: "Edit",
        formatter: (cellContent, row) => {
          return row.VoucherNoAuto != null ? (
            " "
          ) : (
            <button
              type="button"
              className="btn btn-danger btn-xs"
              onClick={() => {
                this.btnEdit_onClick(row.Id);
              }}
            >
              Edit
            </button>
          );
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 25,
      totalSize: this.state.gridData.length, // Updated to length of gridData
      custom: true,
    };

    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <div className="page-content">
        <PaginationProvider
          pagination={paginationFactory({
            ...pageOptions,
            sizePerPageList: [
              { text: '25', value: 25 },
              { text: '50', value: 50 },
              { text: '100', value: 100 },
            ],
          })}
          keyField="id"
          columns={columns}
          data={this.state.gridData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={this.state.gridData}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="12">
                      <Row>
                        <Col sm="2">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col style={{ maxWidth: "4%" }} className="mb-3">
                          <label
                            htmlFor="tilldatee"
                            className="col-form-label"
                          >
                            {" "}
                            From 
                          </label>
                        </Col>
                        <Col sm="2" className="mb-3">
                          <input
                            name="FromDate"
                            value={this.state.FromDate}
                            placeholder="From Date"
                            type="date"
                            className="form-control"
                            onChange={this.handleDateChange}
                          />
                        </Col>
                        <Col style={{ maxWidth: "4%" }} className="mb-3">
                          <label
                            htmlFor="tilldatee"
                            className="col-form-label"
                          >
                            {" "}
                            To 
                          </label>
                        </Col>
                        <Col sm="2" className="mb-3">
                          <input
                            name="ToDate"
                            value={this.state.ToDate}
                            placeholder="To Date"
                            type="date"
                            className="form-control"
                            onChange={this.handleDateChange}
                          />
                        </Col>
                        <Col sm="2" className="mb-3">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <Button
                                type="button"
                                onClick={this.btnAdd_onClick}
                                color="success"
                                className="btn-rounded waves-effect waves-light mb-2 mr-2"
                              >
                                <i className="mdi mdi-plus mr-1"></i> Add New
                              </Button>
                              {" "}
                              <Button
                                type="button"
                                onClick={this.handleSubmit}
                                color="info"
                                className="btn-rounded waves-effect waves-light mb-2 mr-2"
                              >
                                <i className="mdi mdi-file mr-1"></i> View
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      {this.state.noDataFound ? (
                        <div className="no-data-found">No data found</div>
                      ) : (
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </div>
    );
  }
}

export default compose(container)(pageList_Voucher);
