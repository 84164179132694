import React from "react";
import Select from "react-select";

// Function to map Ledger array to Select options
const mapLedgerToSelectOptions = (ledger) =>
  (ledger || []).map(item => ({
    value: item.Id,
    label: item.Name,
  }));

function TableRowsVoucher({ rowsData, add, LedgerA, LedgerR, edit, del }) {
  // Map Ledger arrays to Select options, handling null or undefined
  const ledgerAOptions = mapLedgerToSelectOptions(LedgerA);
  const ledgerROptions = mapLedgerToSelectOptions(LedgerR);

  return rowsData.map((data, index) => {
    const {
      Id,
      F_LedgerMasterCrA,
      F_LedgerMasterDrA,
      F_LedgerMasterCrR,
      F_LedgerMasterDrR,
      Amount,
      Remarks,
    } = data;

    // Find default options
    const defaultCrAOption = ledgerAOptions.find(option => option.value === F_LedgerMasterCrA) || null;
    const defaultDrAOption = ledgerAOptions.find(option => option.value === F_LedgerMasterDrA) || null;
    const defaultCrROption = ledgerROptions.find(option => option.value === F_LedgerMasterCrR) || null;
    const defaultDrROption = ledgerROptions.find(option => option.value === F_LedgerMasterDrR) || null;

    return (
      <tr key={index}>
        <td
          style={{
            width: "300px",
            height: "auto",
            overflowY: "auto",
            whiteSpace: "normal",
            zIndex: "99999",
          }}
        >
          <Select
            options={ledgerAOptions}
            onChange={evnt => edit(index, 'F_LedgerMasterCrA', evnt.value)}
            styles={{
              container: (provided) => ({
                ...provided,
                height: "auto", // Ensure container height adjusts automatically
                overflowY: "auto", // Allow vertical scrolling if content overflows
                whiteSpace: "normal", // Allow text to wrap
              }),
              singleValue: (provided) => ({
                ...provided,
                whiteSpace: "normal", // Allow text to wrap within single value container
                overflow: "hidden", // Hide any overflow
                textOverflow: "ellipsis", // Add ellipsis for overflow text
              }),
              menu: (provided) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                top: "100%", // Position below the input field
                maxHeight: "200px", // Adjust as needed for maximum height
                overflowY: "auto", // Allow vertical scrolling in the dropdown
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            value={defaultCrAOption}
            menuPortalTarget={document.body}
          />
        </td>

        <td
          style={{
            width: "300px",
            height: "auto",
            overflowY: "auto",
            whiteSpace: "normal",
            zIndex: "99999",
          }}
        >
          <Select
            options={ledgerAOptions}
            onChange={evnt => edit(index, 'F_LedgerMasterDrA', evnt.value)}
            styles={{
              container: (provided) => ({
                ...provided,
                height: "auto", // Ensure container height adjusts automatically
                overflowY: "auto", // Allow vertical scrolling if content overflows
                whiteSpace: "normal", // Allow text to wrap
              }),
              singleValue: (provided) => ({
                ...provided,
                whiteSpace: "normal", // Allow text to wrap within single value container
                overflow: "hidden", // Hide any overflow
                textOverflow: "ellipsis", // Add ellipsis for overflow text
              }),
              menu: (provided) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                top: "100%", // Position below the input field
                maxHeight: "200px", // Adjust as needed for maximum height
                overflowY: "auto", // Allow vertical scrolling in the dropdown
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            value={defaultDrAOption}
            menuPortalTarget={document.body}
          />
        </td>

        <td
          style={{
            width: "300px",
            height: "auto",
            overflowY: "auto",
            whiteSpace: "normal",
            zIndex: "99999",
          }}
        >
          <Select
            options={ledgerROptions}
            onChange={evnt => edit(index, 'F_LedgerMasterCrR', evnt.value)}
            styles={{
              container: (provided) => ({
                ...provided,
                height: "auto", // Ensure container height adjusts automatically
                overflowY: "auto", // Allow vertical scrolling if content overflows
                whiteSpace: "normal", // Allow text to wrap
              }),
              singleValue: (provided) => ({
                ...provided,
                whiteSpace: "normal", // Allow text to wrap within single value container
                overflow: "hidden", // Hide any overflow
                textOverflow: "ellipsis", // Add ellipsis for overflow text
              }),
              menu: (provided) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                top: "100%", // Position below the input field
                maxHeight: "200px", // Adjust as needed for maximum height
                overflowY: "auto", // Allow vertical scrolling in the dropdown
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            value={defaultCrROption}
            menuPortalTarget={document.body}
          />
        </td>

        <td
          style={{
            width: "300px",
            height: "auto",
            overflowY: "auto",
            whiteSpace: "normal",
            zIndex: "99999",
          }}
        >
          <Select
            options={ledgerROptions}
            onChange={evnt => edit(index, 'F_LedgerMasterDrR', evnt.value)}
            styles={{
              container: (provided) => ({
                ...provided,
                height: "auto", // Ensure container height adjusts automatically
                overflowY: "auto", // Allow vertical scrolling if content overflows
                whiteSpace: "normal", // Allow text to wrap
              }),
              singleValue: (provided) => ({
                ...provided,
                whiteSpace: "normal", // Allow text to wrap within single value container
                overflow: "hidden", // Hide any overflow
                textOverflow: "ellipsis", // Add ellipsis for overflow text
              }),
              menu: (provided) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                top: "100%", // Position below the input field
                maxHeight: "200px", // Adjust as needed for maximum height
                overflowY: "auto", // Allow vertical scrolling in the dropdown
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            value={defaultDrROption}
            menuPortalTarget={document.body}
          />
        </td>

        <td>
          <input
            style={{ width: "100px" }}
            onChange={evnt => edit(index, 'Amount', evnt.target.value)}
            value={Amount}
            name="Amount"
            type="number"
            className="form-control"
          />
        </td>

        <td>
          <input
            style={{ width: "100px" }}
            onChange={evnt => edit(index, 'Remarks', evnt.target.value)}
            value={Remarks}
            name="Remarks"
            type="text"
            className="form-control"
          />
        </td>

        <td>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={() => del(Id, index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

export default TableRowsVoucher;
