import React ,{useState , useRef} from "react";
import TableRows from "./TableRow";
import TableRows_new from "./TableRow_new";
function AddDeleteTableRows({ itemName , ply , Mrp, calculationtype ,GroupMaster, data , add , edit , deleter , plyquantity, TaxName, CustomerId, Code,UnitMaster}){



   
  


 

   






    return (
      <div className="table-responsive">
        <table className="" style={{ padding: "0", marginBottom: "10px" }}>
          <thead>
            <tr>
              <th>Sr.</th>
            
              <th>Code No.</th>
              <th>Group Name</th>
              <th>Item Name</th>
              <th>Unit</th>
              <th>Qty</th>
         
              <th>Rate</th>
              <th>Disc</th>
              <th>Amount</th>
              <th>Tax</th>
              <th>Del</th>
              <th>Add</th>
              {/* <th><button type="button" className="btn btn-outline-success" onClick={addTableRows} >+</button></th> */}
            </tr>
          </thead>
          <tbody>
            <TableRows_new
              rowsData={data}
              deleteTableRows={deleter}
              handleChange={edit}
              addTableRows={add}
              itemName= {itemName}
              GroupMaster={GroupMaster}
              UnitMaster={UnitMaster}
             
          
              
              Code={Code}
            />
          </tbody>
        </table>
      </div>
    );
}
export default AddDeleteTableRows