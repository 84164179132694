import React ,{useState , useRef} from "react";
import TableRowsTax from "./TableRowTax";
function AddDeleteTableRowsTax({  data  }){










    return(
        <div className="table-responsive">
                <table className="table">
                    <thead>
                      <tr>
                      <th>Tax</th>
                            <th>Amount</th>
                      </tr>
                    </thead>
                   <tbody>
                   <TableRowsTax rowsData={data}   />
                   </tbody> 
                </table>
              
        </div>
    )
}
export default AddDeleteTableRowsTax