import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import Switch from "react-switch"
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";




// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_ChangeStateValue } from "../../store/functions";
import pageAddEdit_UserMaster from "./pageAddEdit_UserMaster";

export const DateString = (dd) =>{

let d1 =  dd.replace('-', '');
let d2 = d1.replace('-', '');

return d2;

}

class pageAddEdit_LengthCalculation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      
      formData: {},
       activeTab: 1,
       passedSteps: [1],
       BrowseImage1 : '',
       BrowseImage2 : '',
       UserImage : '',
       Signature : '',
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid : 0,
      name : "",
      email : "",
      aid : 0,
      fo3 : false,
      Gender : 0,
      MaritalStatus : 0,
      success_msg : false,
      F_MISScheme : 0,
      IsOvertime : false,
      EmpBasic : []
    };
    this.obj = this;
    this.formTitle = "LengthCalculation";
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/LengthCalculation";
    this.API_URL_SAVE = API_WEB_URLS.LengthCal + "/0/token";
    this.pushFormName = "/LengthCalculation";
    this.rtPage_Print = "/membersynopsisprint";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.btnGoBack_onClick = this.btnGoBack_onClick.bind(this);

  }

  componentDidMount() {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });
 
   //Filling DropDowns
    //Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");
       // Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }   
  }

  btnSave_onClick(event, formData) {
   
    let vformData = new FormData();
    
    vformData.append("Name", formData.Name);
    vformData.append("IsDefault", formData.Default);
    
   
    if (!this.state.id)    {
      Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true , "memberid");
   
    }
    else
      Fn_AddEditData(this.obj, { arguList: { id: this.state.id, formData: vformData } }, this.API_URL_SAVE, this.pushFormName, true , "kuku");  
 
  }

  btnGoBack_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };


  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                          <TabContent activeTab={this.state.activeTab} className="body">
                            <TabPane tabId={1}>
                                <Row>
                                  <Col lg="8">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4"></h4>   
                                      <Row>
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Name</label>
                                        </Col>
                                        <Col sm="4">
                                          <AvField name="Name" label="" value={this.state.formData.Name === null ? ''   : this.state.formData.Name} placeholder=" Name" errorMessage="Enter Name" validate={{ required: { value: true } }} type="Text" className="form-control" />
                                        </Col>  
                                        
                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Default</label>
                                        </Col>
                                        <Col sm="2">
                                          <AvField name="Default" label="" value={this.state.formData.IsDefault === null ? '-1'   : this.state.formData.IsDefault} errorMessage="Select Default" validate={{ required: { value: false } }} type="select" className="form-select">
                                            <option value={-1} defaultValue label={"Select"}/>
                                            <option value={1} defaultValue label={"Yes"} />
                                            <option value={0} defaultValue label={"No"} />                                           
                                            </AvField>                                        
                                            </Col>                                                                                                                         
                                      </Row>                                                                                                                 
                                    </CardBody>
                                  </Card>
                                  </Col>              
                                </Row>                              
                            </TabPane>                          
                          </TabContent>
                          <div>
                          <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Save
                        </Button> 
                         
                    &nbsp; 

                         <Button
                          type="button"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                          onClick={this.btnGoBack_onClick}
                        >
                          Go Back
                        </Button>

                          </div>
                        </AvForm>
                      </div>                    
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_LengthCalculation);