import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "react-switch";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import AddDeleteTableRowsPly from "pages/Voucher/AddDeleteTableRowPly";

import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  Fn_ChangeStateValue,
  Fn_DeleteData,
  Fn_GetReport,
} from "store/functions";
import AddDeleteTableRowVoucher from "./AddDeleteTableRowVoucher";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import Loader from "pages/Reports/loader";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
export const DateString = dd => {
  let d1 = dd.replace("-", "");
  let d2 = d1.replace("-", "");

  return d2;
};

function getCurrentTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
}

class pageAddEdit_VoucherDouble extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      VoucherNo: [{}],
      Time: getCurrentTime(),

      save: false,
      cancel: false,
      add: true,

      //dropdowns

      VoucherL: [
        {
          F_LedgerMasterCrA: "",
          F_LedgerMasterDrA: "",
          F_LedgerMasterCrR: "",
          F_LedgerMasterDrR: "",
          Amount: "",
          Remarks: "",
        },
      ],
      F_VoucherH: 0,
      confirm_alert: false,
      isloading: false,
    };
    this.obj = this;
    this.formTitle = "Manage Voucher";
    this.breadCrumbTitle = "Vouchers";
    this.breadCrumbItem = " " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/City";
    this.API_URL_SAVE = API_WEB_URLS.AddEdit + "/0/token";
    this.pushFormName = "/dashboard";
    this.rtPage_Print = "/#";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.btnGoBack_onClick = this.btnGoBack_onClick.bind(this);
    this.addrow = this.addrow.bind(this);
    this.editrow = this.editrow.bind(this);
    this.deleterow = this.deleterow.bind(this);
    this.onVoucherNoChange = this.onVoucherNoChange.bind(this);
    this.OnVoucherChange = this.OnVoucherChange.bind(this);
    this.btnDelete = this.btnDelete.bind(this);
    this.OnVoucherTypeChange = this.OnVoucherTypeChange.bind(this);
    this.fileHandler = this.fileHandler.bind(this);
  }

  btnDelete() {
    Fn_DeleteData(
      this.obj,
      this.state.id,
      API_WEB_URLS.MASTER + "/0/token/Vouchers",
      ""
    );
    // Fn_GetReport(this.obj, { arguList: { id: 0 } }, "SearchVoucher/0/token", "vouchers", true);
    this.props.history.push(this.pushFormName);
  }

  componentDidUpdate(prevProps, prevState) {
  
    if (prevState.VoucherL !== this.state.VoucherL) {
      const totalAmount = this.state.VoucherL.reduce(
        (total, voucher) => total + parseInt(voucher.Amount || 0, 10),
        0
      );
      this.setState({ TotalAmount: totalAmount });
    }
  }

  componentDidMount() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    console.log(obj);
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid });

    Fn_FillListData(
      this.obj,
      "LedgerA",
      API_WEB_URLS.MASTER + "/0/token/LedgerForAccounting/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "LedgerR",
      API_WEB_URLS.MASTER + "/0/token/LedgerForRseva/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "vouchertype",
      API_WEB_URLS.MASTER + "/0/token/VoucherType/Id/0"
    );

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0 } },
      "SearchVoucher/0/token",
      "vouchers",
      true
    );

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(
        this.obj,
        id,
        API_WEB_URLS.MASTER + "/0/token/GetVoucherHDetails" + "/Id"
      );

      // Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }

  fileHandler = () => {
    this.setState({ VoucherL: [{}] });
    let fileObj = this.state.File;

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const columns = resp.rows[0];
        const data = resp.rows.slice(1);

        const transformedData = data.map(row => {
          const rowData = {};
          columns.forEach((column, index) => {
            rowData[column] = row[index];
          });
          return rowData;
        });

        this.setState({ VoucherL: transformedData });
      }
    });
  };

  onVoucherNoChange(event) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    let vformData = new FormData();
    vformData.append("Search", event.target.value);
    console.log("Coming in voucher search");
    Fn_GetReport(
      this.obj,
      { arguList: { id: this.state.aid, formData: vformData } },
      "SearchVoucher/0/token",
      "vouchers",
      true
    );
  }

  OnVoucherChange(event) {
    this.setState({ F_VoucherH: event.target.value });
    Fn_DisplayData(
      this.obj,
      event.target.value,
      API_WEB_URLS.MASTER + "/0/token/GetVoucherHDetails" + "/Id",
      true
    );
  }

  OnVoucherTypeChange(event) {
    Fn_FillListData(
      this.obj,
      "VoucherNo",
      API_WEB_URLS.MASTER + "/0/token/VoucherNo/Id/" + event.target.value
    );
  }

  btnSave_onClick(event, formData) {
    this.setState({ isloading: true });
    let vformData = new FormData();

    vformData.append("Data", JSON.stringify(this.state.VoucherL));
    vformData.append("UserId", this.state.aid);

    Fn_AddEditData(
      this.obj,
      { arguList: { id: this.state.id, formData: vformData } },
      "BothVoucherL/0/token",
      this.pushFormName,
      true
    );
  }

  deleterow(Id, index) {
    const rows = [...this.state.VoucherL];
    rows.splice(index, 1);
    this.setState({ VoucherL: rows });
  }

  btnGoBack_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  btnCancel_onClick = event => {
    this.setState({
      id: 0,
      VoucherL: [
        {
          F_LedgerMasterCrA: "",
          F_LedgerMasterDrA: "",
          F_LedgerMasterCrR: "",
          F_LedgerMasterDrR: "",
          Amount: "",
          Remarks: "",
        },
      ],
      F_VoucherH: 0,
      TotalAmount: 0,
      formData: {
        F_VoucherH: -1,
        SearchVoucherNo: "",
        F_VoucherTypeMaster: -1,
        VoucherDate: getCurrentDate(),
        VoucherNo: "",
        Remarks: "",
      },
    });
  };

  addrow() {
    let VoucherL = {
      F_LedgerMasterCrA: "",
      F_LedgerMasterDrA: "",
      F_LedgerMasterCrR: "",
      F_LedgerMasterDrR: "",
      Amount: "",
      Remarks: "",
    };
    this.setState({ VoucherL: [...this.state.VoucherL, VoucherL] });
  }

  add() {
    this.setState({ save: false, cancel: false, add: true });
    this.setState({
      VoucherL: [
        {
          F_LedgerMasterCrA: "",
          F_LedgerMasterDrA: "",
          F_LedgerMasterCrR: "",
          F_LedgerMasterDrR: "",
          Amount: "",
          Remarks: "",
        },
      ],
    });
  }

  editrow = (index, name, value) => {
    const rowsInput = [...this.state.VoucherL];
  
    // Update the appropriate field in the row
    rowsInput[index][name] = value;
  
    this.setState({ VoucherL: rowsInput });
  
    // Calculate total amount
    const totalAmount = rowsInput.reduce(
      (total, voucher) => total + parseInt(voucher.Amount, 10) || 0,
      0
    );
    this.setState({ TotalAmount: totalAmount });
  };
  

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.isloading ? <Loader /> : null}
          <Container fluid >
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Card >
                  <CardBody>
            <Row>
              <Col lg="14">
                
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="firstName"
                                        className="col-form-label"
                                      >
                                        {" "}
                                        Choose File
                                      </label>
                                    </Col>
                                    <Col sm="3" className="mb-3">
                                      <input
                                        name="file"
                                        label=""
                                        value={this.state.file}
                                        onChange={event =>
                                          Fn_ChangeStateValue(
                                            this.obj,
                                            "File",
                                            event.target.files[0]
                                          )
                                        }
                                        type="file"
                                        className="form-control"
                                      />
                                    </Col>
                                    <Col sm="2" className="mb-3">
                                    <Button
                                      type="button"
                                      onClick={this.fileHandler}
                                      color="info"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      View Excel Data
                                    </Button>
                                    </Col>
                                    </Row>
                                    <AddDeleteTableRowVoucher
                                      data={this.state.VoucherL}
                                      LedgerA={this.state.LedgerA}
                                      LedgerR={this.state.LedgerR}
                                      add={this.addrow}
                                      edit={this.editrow}
                                      del={this.deleterow}
                                    />

                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="lastName"
                                        className="col-form-label"
                                      >
                                        Total Amount
                                      </label>
                                    </Col>
                                    <Col sm="3">
                                      <AvField
                                        name="TotalAmount"
                                        label=""
                                        value={this.state.TotalAmount}
                                        placeholder="TotalAmount"
                                        disabled
                                        type="number"
                                        className="form-control"
                                      />
                                    </Col>
                                
                           
                          <div>
                            <Button
                              type="submit"
                              disabled={this.state.isloading}
                              color="success"
                              className="mr-1 waves-effect waves-light"
                            >
                              Save
                            </Button>
                            &nbsp;
                            <Button
                              type="button"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                              onClick={this.btnGoBack_onClick}
                            >
                              Go Back
                            </Button>
                            &nbsp;
                            <Button
                              type="button"
                              color="warning"
                              className="mr-1 waves-effect waves-light"
                              onClick={this.btnCancel_onClick}
                            >
                              Cancel
                            </Button>
                            &nbsp;
                            <Button
                              type="button"
                              color="danger"
                              className="mr-1 waves-effect waves-light"
                              onClick={() =>
                                this.setState({ confirm_alert: true })
                              }
                            >
                              Delete
                            </Button>
                            {this.state.confirm_alert ? (
                              <SweetAlert
                                title="Are you sure to Delete?"
                                warning
                                showCancel
                                confirmBtnText="Yes, Delete it!"
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                onConfirm={() => this.btnDelete()}
                                onCancel={() =>
                                  this.setState({ confirm_alert: false })
                                }
                              >
                                It wil Never Come back!
                              </SweetAlert>
                            ) : null}
                          </div>
                        </AvForm>
                      
                  
              </Col>
            </Row>
            </CardBody>
                </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_VoucherDouble);
