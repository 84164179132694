import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import Switch from "react-switch"
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";




// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_ChangeStateValue } from "../../store/functions";

export const DateString = (dd) =>{

let d1 =  dd.replace('-', '');
let d2 = d1.replace('-', '');


return d2;


}


class PlyAdjustment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      
      formData: {},
       activeTab: 1,
       passedSteps: [1],
       BrowseImage1 : '',
       BrowseImage2 : '',
       UserImage : '',
       Signature : '',
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid : 0,
      name : "",
      email : "",
      aid : 0,
      fo3 : false,
      Gender : 0,
      MaritalStatus : 0,
      success_msg : false,
      F_MISScheme : 0,
      IsOvertime : false,
      EmpBasic : []
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Ply Adjustment";
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = " " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/ItemType";
    this.API_URL_SAVE = API_WEB_URLS.MEMBER + "/0/token";
    this.pushFormName = "/memberships";
    this.rtPage_Print = "/membersynopsisprint";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.AddBasic =  this.AddBasic.bind(this);
  }



  componentDidMount() {

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role });
 



    //Filling DropDowns
    //Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");
       // Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");

    

    const { id } = this.props.match.params;
    if (id) {
     
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
     
    }


    
  }

  btnSave_onClick(event, formData) {

    var F_MISScheme
   if(formData.F_MISScheme > 0){
     F_MISScheme =  formData.F_MISScheme;
   }

   else{
    F_MISScheme  =  this.state.F_MISScheme;
   }
       

  }


  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }



  onChange =()=> {

    if (this.state.IsOvertime == true){
      this.setState({ IsOvertime: false });
    }

    else {
      this.setState({ IsOvertime: true });
    }

    

  }



  AddBasic () {


    var Type = this.state.switch9;

    if (Type  ==  true){
      Type  = "Daily"
    }

    else {
      Type  = "Monthly";
    }

    this.setState({ EmpBasic: [{
      

      id : 1 , BasicSalary : 500   , EffectDate : "13/09/2022"  , Change : "13/09/2022" , BasicType : Type , Action : <Link to="#" onClick={() =>
        this.setState({ EmpBasic: [] })
      } className="text-danger"  >
      <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip"></i>
      <UncontrolledTooltip placement="top" target="deletetooltip">
          Delete
      </UncontrolledTooltip >
  </Link>
    }] });

  }
  

  render() {

    const JoinProduct = [
      { id: 1, JoinDate: "01/09/2022", JoinType: "Join", LeftDate: "__/__/____" },
    ]
    
    const JoinColumns = [
     
      {
        dataField: "JoinDate",
        text: "JoinDate",
      },
      {
        dataField: "JoinType",
        text: "JoinType",
      },
      {
        dataField: "LeftDate",
        text: "Left Date",
      },

      {
        dataField: "ReasonOfLeft",
        text: "Reason Of Left",
      },
    ]



    const BasicColumns = [
     
      {
        dataField: "BasicSalary",
        text: "Basic Salary",
      },
      {
        dataField: "EffectDate",
        text: "Effect. Date",
      },
      {
        dataField: "Change",
        text: "Change",
      },
      {
        dataField: "BasicType",
        text: "Basic Type",
      },

      {
       dataField: "Action",
        text: "Action",
      },
    ]

    const DepartmentColumns = [
     
      {
        dataField: "Department",
        text: "Department",
      },
      {
        dataField: "Designation",
        text: "Designation",
      },
      {
        dataField: "EffectDate",
        text: "Effect Date",
      }
    
    ]


    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          No
        </div>
      )
    }


    const Offsymbolb = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          ML
        </div>
      )
    }


    const OnSymbolb = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          DL
        </div>
      )
    }

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Yes
        </div>
      )
    }




   

    
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({
                              current: this.state.activeTab === 1,
                            })}
                          >
                            
                          </NavItem>
                          <NavItem

className={classnames({
  current: this.state.activeTab === 2,
})}
>



                        
                          </NavItem>



                          <NavItem
className={classnames({
  current: this.state.activeTab === 3,
})}
>



                            
                          </NavItem>
                          <NavItem

                          
className={classnames({
  current: this.state.activeTab === 4,
})}
>

                          
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                          <TabContent activeTab={this.state.activeTab} className="body">
                            <TabPane tabId={1}>
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4"></h4>
                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Search</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Search" label="" value={this.state.formData.CountryId === null ? '-1'   : this.state.formData.CountryId} errorMessage="Select" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>

                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Ply</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Ply" label="" value={this.state.formData.CountryId === null ? '-1'   : this.state.formData.CountryId} errorMessage="Select" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>                 
                                                 
                                      
                                     
                                      
                                     
                                      
                                    </CardBody>
                                  </Card>
                                  </Col>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4"></h4>
                                                                                                                                                                                                                                                         
                                    
                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Type</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="Type" label="" value={this.state.formData.CountryId === null ? '-1'   : this.state.formData.CountryId} errorMessage="Select Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.nationality 
                                              ? this.state.nationality .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>       

                                                                                                         
                                    </CardBody>
                                  </Card>
                                  </Col>
                                </Row>
                                <Row>
                                </Row>
                                <Row>
                                 
                                  
                                </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                                  <Row>
                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                          <h4 className="card-title mb-4">Joinig Detail</h4>
                                          
                                         
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        data={JoinProduct}
                        columns={JoinColumns}
                        cellEdit={cellEditFactory({ mode: "click" })}
                      />
                    </div>


                    <h4 className="card-title mb-4">Departments</h4>
                                          
                                         
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        data={JoinProduct}
                        columns={DepartmentColumns}
                        cellEdit={cellEditFactory({ mode: "click" })}
                      />
                    </div>
                                         
                                         
                                          
                                        </CardBody>
                                      </Card>
                                    </Col>




                                    <Col lg="6">
                                      <Card>
                                        <CardBody>


                                          <h4 className="card-title mb-4">Emp Basic</h4>
                                          

                                          <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Nationality" className="col-form-label">Basic Salary</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="BasicSalary" label="" value={this.state.formData.BasicSalary === null ? ''   : this.state.formData.BasicSalary} placeholder="Enter BasicSalary" errorMessage="Enter BasicSalary" validate={{ required: true }} type="text" className="form-control" />
                                        </Col>


                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="Nationality" className="col-form-label">Basic Type</label>
                                        </Col>
                                        <Col sm="6">
                                        
                                        <Switch
                            uncheckedIcon={<Offsymbolb />}
                            checkedIcon={<OnSymbolb />}
                            className="me-3 mb-lg-8 mb-2"
                            onColor="#ec4561"
                            onChange={() =>
                              this.setState({ switch9: !this.state.switch9 })
                            }
                            checked={this.state.switch9}
                          />
                                        </Col>
                                        <div className="button-items">

                                        <Button
                        color="success"
                        className="btn btn-success"
                        type="button"
                        onClick={this.AddBasic}
                      >
                        Add
                      </Button>
                                        
                      </div>
                                      </Row>
                                         
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        data={this.state.EmpBasic}
                        columns={BasicColumns}
                        cellEdit={cellEditFactory({ mode: "click" })}
                      />
                    </div>
                                         
                                         
                                          
                                        </CardBody>
                                      </Card>
                                    </Col>
                                   
                                  </Row>


                                  <Row>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                        <h4 className="card-title mb-4">Other Details</h4>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="Member_Introducer" className="col-form-label">In Time (24 hrs Format)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="ActualInTime" label="" value={this.state.formData.ActualInTime === null ? ''   : this.state.formData.ActualInTime} placeholder="In Time (24 hrs Format)"  type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                          <Row>
                                            <Col sm="4" className="mb-3">
                                              <label htmlFor="introducerNo" className="col-form-label">Out Time (24 hrs Format)</label>
                                            </Col>
                                            <Col sm="6">
                                              <AvField name="ActualOutTime" label="" value={this.state.formData.ActualOutTime === null ? ''   : this.state.formData.ActualOutTime} placeholder="Out Time (24 hrs Format)"   type="text" className="form-control" />
                                            </Col>
                                          </Row>


                                          <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Max. Working Hours (Full Day)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MaxWorkingHours4FF" label="" value={this.state.formData.WorkingHours4FF === null ? ''   : this.state.formData.WorkingHours4FF} placeholder="Max. Working Hours (Full Day)"   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Min. Working Hours (Full Day)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MinWorkingHours4FF" label="" value={this.state.formData.MinWorkingHours4FF === null ? ''   : this.state.formData.MinWorkingHours4FF} placeholder="Min. Working Hours (Full Day)"   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Max. Working Hours (Half Day)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MaxWorkingHours4HF" label="" value={this.state.formData.MaxWorkingHours4HF === null ? ''   : this.state.formData.MaxWorkingHours4HF} placeholder="Max. Working Hours (Half Day)"   type="text" className="form-control" />
                                          </Col>
                                        </Row>


                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Min. Working Hours (Half Day)</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="MinWorkingHours4HF" label="" value={this.state.formData.MinWorkingHours4HF === null ? ''   : this.state.formData.MinWorkingHours4HF} placeholder="Min. Working Hours (Half Day)"   type="text" className="form-control" />
                                          </Col>
                                        </Row>
                                        <Row>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col lg="6">
                                    <Card>
                                      <CardBody>
                                       
                                       

                                      <Row>
                                      <Col sm="4" className="mb-3">
                                          <label htmlFor="country" className="col-form-label">Grace Period Mins(Over Time) </label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="GracePeriod" label="" value={this.state.formData.GracePeriod === null ? ''   : this.state.formData.GracePeriod} placeholder="Grace Period Mins(Over Time)"   type="text" className="form-control" />
                                        </Col>
                                      </Row>

                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="agentName" className="col-form-label">Weekly Holiday</label>
                                          </Col>
                                          <Col sm="6">
                                          <AvField name="ShiftId" label="" value={this.state.formData.ShiftId === null ? '-1'   : this.state.formData.ShiftId} errorMessage="Select Weekly Holiday" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.ShiftId 
                                              ? this.state.ShiftId .map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col sm="4" className="mb-3">
                                            <label htmlFor="introducerNo" className="col-form-label">Max Allowed Leaves/Month</label>
                                          </Col>
                                          <Col sm="6">
                                            <AvField name="AllowedLeaves" label="" value={this.state.formData.AllowedLeaves === null ? ''   : this.state.formData.AllowedLeaves} placeholder="Max Allowed Leaves/Month"   type="text" className="form-control" />
                                          </Col>
                                        </Row>

                                       


                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                                
                            </TabPane>
                            <TabPane tabId={3}>
                              <div>
                                  <Row>
                                    <Col lg="6">
                                        <Label for="basicpill-lastname-input2">
                                          Identity Confirmation
                                        </Label>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <AvField name="IDDocumentTypeId_1" label="Type of Document" value={this.state.formData.IDDocumentTypeId_1 === null ? '-1'   : this.state.formData.IDDocumentTypeId_1} errorMessage="Select IDDocument 1" validate={{ required: { value: true } }} type="select" className="form-select" >
                                      <option value={-1} defaultValue label={"Select"} />
                                      {this.state.typeofRegCert
                                        ? this.state.typeofRegCert.map(
                                            (option, key) => (
                                              <option key={option.Id} value={option.Id} label={option.Name} />
                                            )
                                          )
                                        : null}
                                    </AvField>
                                    </Col>
                                    <Col lg="6">
                                    <AvField name="ID_DocNo_1" label="Document No." value={this.state.formData.ID_DocNo_1 === undefined ? ''   : this.state.formData.ID_DocNo_1} placeholder="Enter Document No." type="text" className="form-control" />
                                    </Col>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ID_ImageURL_1_Thumbnail} />
                                    <AvField name="BrowseImage1" label="DocumentId 1" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'BrowseImage1', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <AvField name="IDDocumentTypeId_2" label="Type of Document" value={this.state.formData.IDDocumentTypeId_2 === null ? '-1'   : this.state.formData.IDDocumentTypeId_2} errorMessage="Select ID Document 2" validate={{ required: { value: true } }}   type="select" className="form-select" >
                                      <option value={-1} defaultValue label={"Select"} />
                                      {this.state.typeofRegCert
                                        ? this.state.typeofRegCert.map(
                                            (option, key) => (
                                              <option key={option.Id} value={option.Id} label={option.Name} />
                                            )
                                          )
                                        : null}
                                    </AvField>
                                    </Col>
                                    <Col lg="6">
                                    <AvField name="ID_DocNo_2" label="Document No." value={this.state.formData.ID_DocNo_2 === undefined ? ''   : this.state.formData.ID_DocNo_2} placeholder="Enter Document No."  type="text" className="form-control" />
                                    </Col>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ID_ImageURL_2_Thumbnail} />

                                    <AvField name="BrowseImage2" label="DocumentId 2" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'BrowseImage2', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" />

                                    
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ImageURL_Member_Thumbnail} />
                                    <AvField name="UserImage" label="Employee Photo" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'UserImage', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" />
                                    </Col>
                                    <Col lg="6">
                                    <img height="150" className="rounded me-2" alt={"image"} width="200" src={this.state.formData.ImageURL_Signature_Thumbnail} />
                                    <AvField name="Signature" label="Employee Signature" placeholder="Upload File" errorMessage="Upload File" onChange={(e) => Fn_ChangeStateValue(this.obj, 'Signature', e.target.files[0])} accept=".gif,.jpg,.jpeg,.png" type="file" className="form-control" />
                                    </Col>
                                  </Row>
                              </div>
                            </TabPane>
                          </TabContent>
                          <div>
                          <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Save
                        </Button>

                        {this.state.success_msg ? (
                      <SweetAlert
                        title="Member Saved Successfully!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                        You clicked the button!
                      </SweetAlert>
                    ) : null}
                          </div>
                        </AvForm>
                      </div>
                      <div className="actions clearfix">
                        <ul>

                      
                          
                         
                         
                         

                          <li
                            className={
                              this.state.activeTab === 1
                                ? "save disabled"
                                : "save"
                            }
                          >
                        
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(PlyAdjustment);
