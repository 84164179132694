import React, { useState, useRef } from "react";
import TableRowsPly from "./TableRowsPly";
import TableRowsVoucher from "./TableRowsVoucher";
function AddDeleteTableRowVoucher({ data, add, edit, LedgerA, LedgerR, del }) {
  const [rowsData, setRowsData] = useState([]);

  const deleteTableRows = index => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  return (
    <div className="table-responsive">
      <table className="" style={{ padding: "0", marginBottom: "10px" }}>
        <thead>
          <tr>
            <th>Cr LedgerA</th>
            <th>Dr LedgerA</th>
            <th>Cr LedgerR</th>
            <th>Dr LedgerR</th>
            <th>Amount</th>
            <th>Remarks</th>
            <th>
              {" "}
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={add}
              >
                +
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRowsVoucher
            rowsData={data}
            add={add}
            LedgerA={LedgerA}
            LedgerR={LedgerR}
            edit={edit}
            del={del}
          />
        </tbody>
      </table>
    </div>
  );
}
export default AddDeleteTableRowVoucher;
