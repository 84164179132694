import React, { Component } from "react";

import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";


// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import { API_HELPER } from "helpers/ApiHelper";
import axios from "axios";
import { API_WEB_URLS } from "constants/constAPI";

class OTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      prov : false,
    };
    this.obj = this;
   
    this.API_URL_SAVE =   "UserLogin/0/token";
    this.pushFormName = "/persontoperson";
    this.checkotp = this.checkotp.bind(this);
  }

  componentDidMount() {
   
  }


  checkotp= async ()=> {

    const obj = JSON.parse(sessionStorage.getItem("authUser1"));

    const res = await axios.get("https://api.ipify.org/?format=json");

    let vformData = new FormData();
    vformData.append("Id", obj.uid);
    vformData.append("OTP", this.state.OTP);
    vformData.append("IPAddress", res.data.ip);
    let CheckOtp = function() {
      return API_HELPER.apiPOST_Multipart(API_WEB_URLS.BASE+"CheckOtp/0/token/" , vformData).then(token => { return token } )
    }

    let checkotptoken = await CheckOtp();

  if(checkotptoken.data.response[0].Id>0){
    sessionStorage.setItem("authUser", JSON.stringify(obj));
    this.props.history.push("/dashboard");
  }
  else {
    alert('Invalid OTP!');
  }

  }







  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Two Step Authentication !</h5>
                          <p>Check OTP</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                OTP
                              </Label>
                              <input
                                name="OTP"
                                type="number"
                                className={
                                  "form-control"
                                }
                                onChange={(e)=>this.setState({OTP:e.target.value})}
                              />
                             
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={this.checkotp}
                              >
                                Proceed
                              </button>
                            </div>
                        </div>
                        
                     
                      
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p>
                    Don&apos;t have an account ?
                    <Link to="register" className="fw-medium text-primary">
                      Signup Now
                    </Link>
                  </p> */}
                  <p>
                    © {new Date().getFullYear()} bhuwal.in
                    <i className="mdi mdi-heart text-danger" />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}





export default withRouter(connect()(OTP)
);
