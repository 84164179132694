import React from "react";
import Select from "react-select";

// Function to map Ledger array to Select options
const mapLedgerToSelectOptions = (ledger) =>
  (ledger || []).map(item => ({
    value: item.Id,
    label: item.Name,
  }));

function TableRowsPly({ rowsData, add, Ledger, edit, del, F_VoucherTypeMaster }) {
  // Map Ledger array to Select options, handling null or undefined
  const ledgerOptions = mapLedgerToSelectOptions(Ledger);

  return rowsData.map((data, index) => {
    const { Id, F_LedgerMasterCr, F_LedgerMasterDr, Amount } = data;

    // Find default options
    const defaultCrAOption = ledgerOptions.find(option => option.value === F_LedgerMasterCr) || null;
    const defaultDrAOption = ledgerOptions.find(option => option.value === F_LedgerMasterDr) || null;

    return (
      <tr key={index}>
        <td
          style={{
            width: "500px",
            height: "auto",
            overflowY: "auto",
            whiteSpace: "normal",
            zIndex: "99999",
          }}
        >
          <Select
            options={ledgerOptions}
            onChange={evnt => edit(index, 'F_LedgerMasterCr', evnt ? evnt.value : null)}
            styles={{
              container: (provided) => ({
                ...provided,
                height: "auto", // Ensure container height adjusts automatically
                overflowY: "auto", // Allow vertical scrolling if content overflows
                whiteSpace: "normal", // Allow text to wrap
              }),
              singleValue: (provided) => ({
                ...provided,
                whiteSpace: "normal", // Allow text to wrap within single value container
                overflow: "hidden", // Hide any overflow
                textOverflow: "ellipsis", // Add ellipsis for overflow text
              }),
              menu: (provided) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                top: "100%", // Position below the input field
                maxHeight: "200px", // Adjust as needed for maximum height
                overflowY: "auto", // Allow vertical scrolling in the dropdown
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            value={defaultCrAOption}
            menuPortalTarget={document.body}
          />
        </td>

        <td
          style={{
            width: "500px",
            height: "auto",
            overflowY: "auto",
            whiteSpace: "normal",
            zIndex: "99999",
          }}
        >
          <Select
            options={ledgerOptions}
            onChange={evnt => edit(index, 'F_LedgerMasterDr', evnt ? evnt.value : null)}
            styles={{
              container: (provided) => ({
                ...provided,
                height: "auto", // Ensure container height adjusts automatically
                overflowY: "auto", // Allow vertical scrolling if content overflows
                whiteSpace: "normal", // Allow text to wrap
              }),
              singleValue: (provided) => ({
                ...provided,
                whiteSpace: "normal", // Allow text to wrap within single value container
                overflow: "hidden", // Hide any overflow
                textOverflow: "ellipsis", // Add ellipsis for overflow text
              }),
              menu: (provided) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                top: "100%", // Position below the input field
                maxHeight: "200px", // Adjust as needed for maximum height
                overflowY: "auto", // Allow vertical scrolling in the dropdown
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            value={defaultDrAOption}
            menuPortalTarget={document.body}
          />
        </td>

        <td>
          <input
            style={{ width: "200px" }}
            onChange={evnt => edit(index, 'Amount', evnt.target.value)}
            value={Amount}
            name="Amount"
            type="number"
            className="form-control"
          />
        </td>

        <td>
          <button
            type="button"
            className="btn btn-outline-success"
            onClick={add}
          >
            +
          </button>
        </td>
        <td>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={() => del(Id, index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
}

export default TableRowsPly;
