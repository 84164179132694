import React ,{useState , useRef} from "react";
import TableRowsCharges from "./TableRowCharges";
function AddDeleteTableRowsCharges({  data , addTableRows , Ledger , edit }){










    return(
        <div className="table-responsive">
                <table className="table">
                    <thead>
                      <tr>
                      <th>Charges</th>
                            <th>Amount</th>
                            <th><button type="button" className="btn btn-outline-success" onClick={addTableRows} >+</button></th>
                      </tr>
                    </thead>
                   <tbody>
                   <TableRowsCharges rowsData={data}  Ledger  =  {Ledger} edit={edit}  />
                   </tbody> 
                </table>
              
        </div>
    )
}
export default AddDeleteTableRowsCharges