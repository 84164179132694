/* eslint-disable */

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";

import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  Fn_ChangeStateValue,
  Fn_FillListData2,
  Fn_AddEditDataH,
  Fn_DeleteData,
  Fn_FillListDataGlobal,
} from "../../store/functions";
import AddDeleteTableRows from "./AddDeleteTableRow";
import AddDeleteTableRowsTax from "./AddDeleteTableRowtax";
import { toggleRightSidebar } from "store/actions";
import AddDeleteTableRowsCharges from "./AddDeleteTableRowCharges";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { event } from "jquery";

function showToastWithCloseButton(toastType, message) {
  toastr.options = {
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    progressBar: true,
    timeOut: 2000,
  };
  if (toastType == "success") toastr.success(message);
  else if (toastType == "error") toastr.error(message);
}

export const formatDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const sum = data => {
  let sum = 0; // initialize sum

  // Iterate through all elements
  // and add them to sum
  for (let i = 0; i < data.length; i++) sum += parseFloat(data[i].Amount);

  return sum.toFixed(2);
};

export const taxsum = data => {
  let sum = 0; // initialize sum

  // Iterate through all elements
  // and add them to sum
  for (let i = 0; i < data.length; i++) sum += parseFloat(data[i].amount);

  return sum.toFixed(2);
};

class pageAddEdit_Invoice extends Component {
  constructor(props) {
    super(props);
    var today = formatDate();
    this.state = {
      id: 0,
      formData: {},
      success_msg: false,
      F_MISScheme: 0,
      InvoiceData: [{}],
      IsDiff: false,
      IsCon: true,
      visible: false,
      edit: true,
      OtherAddress: "",
      Add1: false,
      Add2: false,
      Add3: false,
      save: false,
      cancel: false,
      add: true,
      einvoice: true,
      InvoiceSel: false,
      print: true,
      dd: false,
      InvoiceDataL: [
        {
          IsDirectInvoice: true,
          CodeNo: "",
          ItemType: "",
          Ply: "",
          Length: "",
          LengthSoot: "",
          Width: "",
          WidthSoot: "",
          Height: "",
          HeightSoot: "",
          CalculationType: "",
          Qty: "",
          RmgQty: "",
          Rate: "",
          PrintDesc: "",
          PrintRate: "",
          Amount: "",
          MainRate: "",
          CalRate: "",
          IGST: "",
          IsBatta: "",
          DescriptionL: "",
        },
      ],
      outline: "",
      Tax: [],
      Charges: [],
      TaxData: [
        {
          IndexRow: "",
          CGST: "",
          IGST: "",
          Amount: "",
        },
      ],
      InvoiceId: "",
      VoucherNo: "",
      VoucherDate: today,
      ChallanNo: "",
      ChallanDate: today,
      PONo: "",
      PODate: "",
      GRRNo: "",
      GRRDate: "",
      LedgerId: "",
      SalesLedgerId: "",
      Transport: "",
      VehicleNo: "",
      Through: "",
      Signatory: "",
      IsDiff: "",
      Add1: "",
      Add2: "",
      Add3: "",
      GSTTotalTaxAmount: [
        {
          tax: "",
          amount: "",
          igst: "",
        },
      ],

      VoucherGlobalOptions: [
        {
          IsVoucherNoEnable: false,
          VoucherNo: "",
          BackgroundColor: "",
          TextColor: "",
          IsActualSoot: false,
        },
      ],
      BoldToken: [
        {
          Token: "",
        },
      ],
      CustomerId: API_WEB_URLS.Customer,
      canceleinvoice: false,
      ReasonRemarks: "",
      CancelReasonId: -1,
      cancelinvoiceName: "Cancel Invoice",
      canceleinvoiceBtn: false,
      editbtnname: "EDIT",
      oldvouhcerdate: false,
      deletedlg: false,
      IsCRN: false,
      IsDBN: false,
      BattaRate: [
        {
          Rate: 0,
        },
      ],
      IsEway: false,

      // backgroundColor : 'black',
      // textcolor : '#ddb84a'
      // backgroundColor : '#E2D1F9',
      // textcolor : '#317773'
    };

    this.obj = this;
    this.formTitle = "Invoice";
    this.breadCrumbTitle = "Invoice";
    this.breadCrumbItem = "Add " + this.formTitle;

    this.API_URL_SAVE = API_WEB_URLS.INVOICEL + "/0/token";
    this.API_URL_SAVEH = API_WEB_URLS.INVOICEH + "/0/token";
    this.API_URL_SAVETAX = API_WEB_URLS.INVOICETAX + "/0/token";
    this.API_URL_TAXABLEAMOUNT = API_WEB_URLS.INVOICETAXABLE + "/0/token";
    this.API_URL_SAVECHARGE = API_WEB_URLS.INVOICECHARGE + "/0/token";
    this.pushFormName = "/addinvoice";
    this.rtPage_Print = "/membersynopsisprint";

    this.API_URL = API_WEB_URLS.MASTER + "/0/token/InvoiceMasterTax";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno = this.syno.bind(this);
    this.addrow = this.addrow.bind(this);
    this.editrow = this.editrow.bind(this);
    this.add = this.add.bind(this);
    this.cancel = this.cancel.bind(this);
    this.edit = this.edit.bind(this);
    this.addchargesrow = this.addchargesrow.bind(this);
    this.einvoice = this.einvoice.bind(this);
    this.handler2 = this.handler2.bind(this);
    this.valueset = this.valueset.bind(this);
    this.deleterow = this.deleterow.bind(this);
    this.delete = this.delete.bind(this);
    this.tog_fullscreen = this.tog_fullscreen.bind(this);
    this.validateCalculations = this.validateCalculations.bind(this);
    this.canceleinvoice = this.canceleinvoice.bind(this);
    this.proceedeinvoice = this.proceedeinvoice.bind(this);
    this.changeINV = this.changeINV.bind(this);
    this.copy = this.copy.bind(this);
    this.paste = this.paste.bind(this);

    //ref

    this.invoice = React.createRef();
    this.vno = React.createRef();
    this.vdate = React.createRef();
    this.chno = React.createRef();
    this.chdate = React.createRef();
    this.pono = React.createRef();
    this.podate = React.createRef();
    this.grrno = React.createRef();
    this.grrdate = React.createRef();
    this.ledger = React.createRef();
    this.salesledger = React.createRef();
    this.transport = React.createRef();
    this.vhno = React.createRef();
    this.through = React.createRef();
    this.signatory = React.createRef();
    this.diffcon = React.createRef();
    this.IsAdd1 = React.createRef();
    this.IsAdd2 = React.createRef();
    this.IsAdd3 = React.createRef();

    this.AddBtn = React.createRef();
    this.TranId = React.createRef();
    this.tranmode = React.createRef();
    this.trandist = React.createRef();

    this.Code = this.state.InvoiceDataL.map(() => React.createRef([]));
  }

  syno() {
    this.setState({
      success_msg: false,
    });
  }

  addchargesrow() {
    var i = 0;
    var array = this.state.InvoiceDataL;
    var largest = 0;

    for (i = 0; i < array.length; i++) {
      if (Number(array[i].IGST) > Number(largest)) {
        largest = array[i].IGST;
      }
    }

    this.setState({
      Charges: [
        ...this.state.Charges,
        {
          charges: "",
          amount: "",
          IGST: largest,
        },
      ],
    });
  }

  copy() {
    localStorage.setItem("copydata", JSON.stringify(this.state.InvoiceDataL));
    alert("Copied Successfully!");
  }

  paste() {
    const storedData = localStorage.getItem("copydata");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      // Assuming you want to set the retrieved data to InvoiceDataL
      this.setState({ InvoiceDataL: parsedData });
      //localStorage.setItem("copydata", null);
    } else {
      alert("No data to paste!");
    }
  }

  deleterow(index) {
    console.log(index);
    const rows = [...this.state.InvoiceDataL];
    rows.splice(index, 1);
    this.setState({ InvoiceDataL: rows });
  }

  addrow() {
    if (this.state.InvoiceDataL[0].Amount > 0) {
      let uu = {
        IsDirectInvoice: true,
        CodeNo: "",
        ItemType: "",
        Ply: "",
        Length: "",
        LengthSoot: "",
        Width: "",
        WidthSoot: "",
        Height: "",
        HeightSoot: "",
        CalculationType: "",
        Qty: "",
        RmgQty: "",
        Rate: "",
        PrintDesc: "",
        PrintRate: "",
        Amount: "",
        MainRate: "",
        CalRate: "",
        IsBatta: "",
        DescriptionL: "",
      };
      this.setState({ InvoiceDataL: [...this.state.InvoiceDataL, uu] });

      // var lk  =  JSON.stringify(this.state.InvoiceDataL);
      //     console.log(lk);
    } else {
      alert("Can't add row!");
    }
  }

  cancel() {
    Fn_FillListDataGlobal(
      this.obj,
      "VoucherGlobalOptions",
      API_WEB_URLS.MASTER + "/0/token/VoucherGlobalOptions/Id/0"
    );
    this.setState({
      add: false,
      edit: true,
      cancel: true,
      save: true,
      einvoice: true,
      InvoiceSel: false,
      print: true,
    });

    var today = formatDate();
    this.setState({
      InvoiceDataL: [
        {
          IsDirectInvoice: true,
          CodeNo: "",
          ItemType: "",
          Ply: "",
          Length: "",
          LengthSoot: "",
          Width: "",
          WidthSoot: "",
          Height: "",
          HeightSoot: "",
          CalculationType: "",
          Qty: "",
          RmgQty: "",
          Rate: "",
          PrintDesc: "",
          PrintRate: "",
          Amount: "",
          MainRate: "",
          CalRate: "",
          IGST: "",
          IsBatta: "",
          DescriptionL: "",
        },
      ],
      VoucherDate: today,
      ChallanNo: "",
      ChallanDate: today,
      PONo: "",
      PODate: "",
      GRRNo: "",
      GRRDate: "",
      F_LedgerMaster: "",
      SalesLedgerId: "",
      Transport: "",
      VehicleNo: "",
      Through: "",
      Signatory: "",
      LedgerId: "",
      OtherAddress: "",
      Add3: false,
      Add2: false,
      Add1: false,
      IsDiff: false,
      RoundedValue: 0,
      GrossAmount: 0,
      NetAmount: 0,
      TaxAmount: 0,
    });

    this.setState({
      formData: {
        InvoiceId: "",
        VoucherNo: "",
        VoucherDate: "",
        ChallanNo: "",
        ChallanDate: today,
        PONo: "",
        PODate: today,
        GRRNo: "",
        GRRDate: today,
        LedgerId: "",
        SalesLedgerId: "",
        Transport: "",
        VehicleNo: "",
        Through: "",
        Signatory: "",
      },

      NetAmount: "",
      RoundedValue: "",
      TaxAmount: "",
      GrossAmount: "",
      InvoiceData: [
        {
          InvoiceId: "",
          VoucherNo: "",
          VoucherDate: "",
          ChallanNo: "",
          ChallanDate: today,
          PONo: "",
          PODate: today,
          GRRNo: "",
          GRRDate: today,
          F_LedgerMaster: "",
          SalesLedgerId: "",
          Transport: "",
          VehicleNo: "",
          Through: "",
          Signatory: "",
        },
      ],
    });

    var iu = [];

    const rowsInput = [...iu];
    this.setState({ Tax: rowsInput, Charges: rowsInput });

    Fn_FillListData(
      this.obj,
      "Invoice",
      API_WEB_URLS.MASTER + "/0/token/Invoice/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "ChargesLedger",
      API_WEB_URLS.MASTER + "/0/token/ChargesLedger/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "Ledger",
      API_WEB_URLS.MASTER + "/0/token/LedgerForInvoice/Id/0"
    );
  }

  edit() {
    this.setState({
      save: false,
      add: true,
      edit: true,
      cancel: false,
      InvoiceSel: true,
      einvoice: true,
      print: true,
    });
  }

  add() {
    Fn_FillListDataGlobal(
      this.obj,
      "VoucherGlobalOptions",
      API_WEB_URLS.MASTER + "/0/token/VoucherGlobalOptions/Id/0"
    );

    var today = formatDate();
    this.setState({
      save: false,
      cancel: false,
      add: true,
      InvoiceSel: true,
      edit: true,
      canceleinvoiceBtn: true,
      delete: true,
      print: true,
      einvoice: true,
    });

    var today = formatDate();
    this.setState({
      InvoiceDataL: [
        {
          CodeNo: "",
          ItemType: "",
          Ply: "",
          Length: "",
          LengthSoot: "",
          Width: "",
          WidthSoot: "",
          Height: "",
          HeightSoot: "",
          CalculationType: "",
          Qty: "",
          RmgQty: "",
          Rate: "",
          PrintDesc: "",
          PrintRate: "",
          Amount: "",
          MainRate: "",
          CalRate: "",
          IGST: "",
          IsBatta: "",
          DescriptionL: "",
        },
      ],
      VoucherDate: today,
      ChallanNo: "",
      ChallanDate: today,
      PONo: "",
      PODate: "",
      GRRNo: "",
      GRRDate: "",
      F_LedgerMaster: "",
      SalesLedgerId: "",
      Transport: "",
      VehicleNo: "",
      Through: "",
      Signatory: "",
      LedgerId: "",
      OtherAddress: "",
      Add3: false,
      Add2: false,
      Add1: false,
      IsDiff: false,
      RoundedValue: 0,
      GrossAmount: 0,
      NetAmount: 0,
      TaxAmount: 0,
    });

    this.setState({
      formData: {
        InvoiceId: "",
        VoucherNo: "",
        VoucherDate: "",
        ChallanNo: "",
        ChallanDate: today,
        PONo: "",
        PODate: today,
        GRRNo: "",
        GRRDate: today,
        LedgerId: "",
        SalesLedgerId: "",
        Transport: "",
        VehicleNo: "",
        Through: "",
        Signatory: "",
      },

      InvoiceData: [
        {
          InvoiceId: "",
          VoucherNo: "",
          VoucherDate: "",
          ChallanNo: "",
          ChallanDate: today,
          PONo: "",
          PODate: today,
          GRRNo: "",
          GRRDate: today,
          F_LedgerMaster: "",
          SalesLedgerId: "",
          Transport: "",
          VehicleNo: "",
          Through: "",
          Signatory: "",
        },
      ],
    });

    var iu = [];

    const rowsInput = [...iu];
    this.setState({ Tax: rowsInput, Charges: rowsInput });
  }

  getAddress = event => {
    if (event.target.name == "Add1") {
      this.setState({
        Add1: !this.state.Add1,
        OtherAddress: this.state.ConsigneeAddress[0].Address1,
        Add2: false,
        Add3: false,
      });
      if (this.state.Add1 == true) {
        this.setState({ OtherAddress: "" });
      }
    } else if (event.target.name == "Add2") {
      this.setState({
        Add1: false,
        OtherAddress: this.state.ConsigneeAddress[0].Address2,
        Add2: !this.state.Add2,
        Add3: false,
      });
      if (this.state.Add2 == true) {
        this.setState({ OtherAddress: "" });
      }
    } else if (event.target.name == "Add3") {
      this.setState({
        Add1: false,
        OtherAddress: this.state.ConsigneeAddress[0].Address3,
        Add2: false,
        Add3: !this.state.Add3,
      });
      if (this.state.Add3 == true) {
        this.setState({ OtherAddress: "" });
      }
    }
  };

  canceleinvoice() {
    if (this.state.ReasonRemarks == "") {
      alert("Please fill ReasonRemarks");
    } else {
      let vformData4 = new FormData();

      vformData4.append("F_InvoiceMasterH", this.state.InvoiceId);
      vformData4.append("CnlRsn", this.state.CancelReasonId);
      vformData4.append("CnlRem", this.state.ReasonRemarks);

      const requestOptions = {
        method: "POST",
        body: vformData4,
      };
      var url = API_WEB_URLS.BASE + "EInvoiceCancel/0/token";
      fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.data.data.id == 1 || data.data.data.id == "1") {
            showToastWithCloseButton(
              "success",
              "E-Invoice Cancelled Successfully!"
            );
          } else {
            showToastWithCloseButton("success", data.data.data.name);
          }
        })
        .catch(error => {
          showToastWithCloseButton("error", "Somthing went wrong");
        });

      this.setState({ canceleinvoice: false });
    }
  }

  OnConChange = event => {
    var IsDiff = event.target.checked;

    if (event.target.checked == true) {
      this.setState({ IsCon: false, IsDiff: true });
    } else {
      this.setState({ IsCon: true, IsDiff: false });
    }
  };

  gst() {
    var arr = [];
    var CGSTName = "";
    var SGSTName = "";
    var IGSTName = "";

    for (let i = 0; i < this.state.InvoiceDataL.length; i++) {
      const lk = arr.findIndex(
        product =>
          Number(product.igst) == Number(this.state.InvoiceDataL[i].IGST)
      );

      if (lk == -1) {
        arr = [
          ...arr,
          {
            tax: CGSTName,
            amount: parseFloat(this.state.InvoiceDataL[i].Amount),
            igst: this.state.InvoiceDataL[i].IGST,
          },
        ];
      } else {
        var LastAmount = 0;

        const rowsInput2 = [...arr];

        LastAmount =
          parseFloat(rowsInput2[lk]["amount"]) +
          parseFloat(this.state.InvoiceDataL[i].Amount);

        rowsInput2[lk]["amount"] = LastAmount;
      }
    }

    //largest

    var array = this.state.InvoiceDataL;
    var largest = 0;

    for (let i = 0; i < array.length; i++) {
      if (Number(array[i].IGST) > Number(largest)) {
        largest = array[i].IGST;
      }
    }

    for (let i = 0; i < this.state.Charges.length; i++) {
      const lk = arr.findIndex(product => product.igst == largest);

      if (lk == -1) {
        //do nothing
      } else {
        var LastAmount = 0;

        const rowsInput2 = [...arr];

        LastAmount =
          parseFloat(rowsInput2[lk]["amount"]) +
          parseFloat(this.state.Charges[i].amount);

        rowsInput2[lk]["amount"] = LastAmount;
      }
    }

    var iu = [];

    for (let i = 0; i < arr.length; i++) {
      var su = parseFloat(arr[i].igst) / 2;

      if (su > 0) {
        CGSTName = "CGST " + parseFloat(arr[i].igst) / 2 + " %";
        SGSTName = "SGST " + parseFloat(arr[i].igst) / 2 + " %";

        let IGST = this.state.Ledger.findIndex(
          pro => pro.Id == this.state.LedgerId
        );
        IGST = this.state.Ledger[IGST].OwnState;
        if (IGST == 2) {
          IGSTName = "IGST " + parseFloat(arr[i].igst) + " %";

          iu = [
            ...iu,
            {
              tax: IGSTName,
              amount: (
                (parseFloat(arr[i].amount) * parseFloat(arr[i].igst)) /
                100
              ).toFixed(2),
              igst: arr[i].igst,
            },
          ];
        } else {
          iu = [
            ...iu,
            {
              tax: CGSTName,
              amount: (
                (parseFloat(arr[i].amount) * (parseFloat(arr[i].igst) / 2)) /
                100
              ).toFixed(2),
              igst: arr[i].igst,
            },
            {
              tax: SGSTName,
              amount: (
                (parseFloat(arr[i].amount) * (parseFloat(arr[i].igst) / 2)) /
                100
              ).toFixed(2),
              igst: arr[i].igst,
            },
          ];
        }
      }
    }

    const rowsInput = [...iu];

    const gsttax = [...arr];

    var ch = parseFloat(taxsum(this.state.Charges));
    var char = 0;
    if (ch != NaN && ch > 0) {
      char = ch;
    }

    var NetAmountBeforeRound =
      parseFloat(sum(this.state.InvoiceDataL)) +
      parseFloat(taxsum(iu)) +
      parseFloat(char);
    var NetAmountAterRound = Math.round(NetAmountBeforeRound);
    var RoundedValue = NetAmountAterRound - NetAmountBeforeRound;

    this.setState({
      Tax: rowsInput,
      GrossAmount:
        parseFloat(sum(this.state.InvoiceDataL)) +
        parseFloat(taxsum(this.state.Charges)),
      TaxAmount: taxsum(iu),
      NetAmount: NetAmountAterRound,
      RoundedValue: RoundedValue.toFixed(2),
      GSTTotalTaxAmount: gsttax,
    });
    // iu.forEach(val => this.state.Tax.push(Object.assign({}, val)));
  }

  editChargesrow = (index, evnt) => {
    const { name, value } = evnt.target;

    const rowsInput = [...this.state.Charges];
    rowsInput[index][name] = value;

    this.setState({ Charges: rowsInput });
    if (name == "amount") {
      this.gst();
    }
  };

  editrow = (index, evnt) => {
    const { name, value } = evnt.target;

    const rowsInput = [...this.state.InvoiceDataL];

    rowsInput[index][name] = value;

    this.setState({ InvoiceDataL: rowsInput });

    var MainRate = 0;

    if (this.state.VoucherGlobalOptions[0].IsActualSoot == true) {
      if (name == "LengthSoot") {
        var LengthSoot = evnt.target.value;

        if (LengthSoot == 1) rowsInput[index]["LengthSoot"] = "1/8";
        else if (LengthSoot == 2) rowsInput[index]["LengthSoot"] = "2/8";
        else if (LengthSoot == 3) rowsInput[index]["LengthSoot"] = "3/8";
        else if (LengthSoot == 4) rowsInput[index]["LengthSoot"] = "4/8";
        else if (LengthSoot == 5) rowsInput[index]["LengthSoot"] = "5/8";
        else if (LengthSoot == 6) rowsInput[index]["LengthSoot"] = "6/8";
        else if (LengthSoot == 7) rowsInput[index]["LengthSoot"] = "7/8";
      }

      if (name == "WidthSoot") {
        var LengthSoot = evnt.target.value;

        if (LengthSoot == 1) rowsInput[index]["WidthSoot"] = "1/8";
        else if (LengthSoot == 2) rowsInput[index]["WidthSoot"] = "2/8";
        else if (LengthSoot == 3) rowsInput[index]["WidthSoot"] = "3/8";
        else if (LengthSoot == 4) rowsInput[index]["WidthSoot"] = "4/8";
        else if (LengthSoot == 5) rowsInput[index]["WidthSoot"] = "5/8";
        else if (LengthSoot == 6) rowsInput[index]["WidthSoot"] = "6/8";
        else if (LengthSoot == 7) rowsInput[index]["WidthSoot"] = "7/8";
      }

      if (name == "HeightSoot") {
        var LengthSoot = evnt.target.value;

        if (LengthSoot == 1) rowsInput[index]["HeightSoot"] = "1/8";
        else if (LengthSoot == 2) rowsInput[index]["HeightSoot"] = "2/8";
        else if (LengthSoot == 3) rowsInput[index]["HeightSoot"] = "3/8";
        else if (LengthSoot == 4) rowsInput[index]["HeightSoot"] = "4/8";
        else if (LengthSoot == 5) rowsInput[index]["HeightSoot"] = "5/8";
        else if (LengthSoot == 6) rowsInput[index]["HeightSoot"] = "6/8";
        else if (LengthSoot == 7) rowsInput[index]["HeightSoot"] = "7/8";
      }
    } else {
      if (name == "LengthSoot") {
        var LengthSoot = evnt.target.value;

        if (LengthSoot == 1) rowsInput[index]["LengthSoot"] = "1/8";
        else if (LengthSoot == 2) rowsInput[index]["LengthSoot"] = "1/4";
        else if (LengthSoot == 3) rowsInput[index]["LengthSoot"] = "3/8";
        else if (LengthSoot == 4) rowsInput[index]["LengthSoot"] = "1/2";
        else if (LengthSoot == 5) rowsInput[index]["LengthSoot"] = "5/8";
        else if (LengthSoot == 6) rowsInput[index]["LengthSoot"] = "3/4";
        else if (LengthSoot == 7) rowsInput[index]["LengthSoot"] = "7/8";
      }

      if (name == "WidthSoot") {
        var LengthSoot = evnt.target.value;

        if (LengthSoot == 1) rowsInput[index]["WidthSoot"] = "1/8";
        else if (LengthSoot == 2) rowsInput[index]["WidthSoot"] = "1/4";
        else if (LengthSoot == 3) rowsInput[index]["WidthSoot"] = "3/8";
        else if (LengthSoot == 4) rowsInput[index]["WidthSoot"] = "1/2";
        else if (LengthSoot == 5) rowsInput[index]["WidthSoot"] = "5/8";
        else if (LengthSoot == 6) rowsInput[index]["WidthSoot"] = "3/4";
        else if (LengthSoot == 7) rowsInput[index]["WidthSoot"] = "7/8";
      }

      if (name == "HeightSoot") {
        var LengthSoot = evnt.target.value;

        if (LengthSoot == 1) rowsInput[index]["HeightSoot"] = "1/8";
        else if (LengthSoot == 2) rowsInput[index]["HeightSoot"] = "1/4";
        else if (LengthSoot == 3) rowsInput[index]["HeightSoot"] = "3/8";
        else if (LengthSoot == 4) rowsInput[index]["HeightSoot"] = "1/2";
        else if (LengthSoot == 5) rowsInput[index]["HeightSoot"] = "5/8";
        else if (LengthSoot == 6) rowsInput[index]["HeightSoot"] = "3/4";
        else if (LengthSoot == 7) rowsInput[index]["HeightSoot"] = "7/8";
      }
    }

    var Length = rowsInput[index]["Length"];
    var Height = rowsInput[index]["Height"];
    var Width = rowsInput[index]["Width"];

    var LengthSoot = rowsInput[index]["LengthSoot"];
    var HeightSoot = rowsInput[index]["HeightSoot"];
    var WidthSoot = rowsInput[index]["WidthSoot"];

    LengthSoot = eval(LengthSoot);
    if (LengthSoot == undefined) {
      LengthSoot = 0;
    }

    HeightSoot = eval(HeightSoot);
    if (HeightSoot == undefined) {
      HeightSoot = 0;
    }

    WidthSoot = eval(WidthSoot);
    if (WidthSoot == undefined) {
      WidthSoot = 0;
    }

    var cal = rowsInput[index]["CalculationType"];

    if (name == "CodeNo") {
      let vformData3 = new FormData();

      vformData3.append("Code", evnt.target.value);
      vformData3.append("F_LedgerMaster", this.state.F_LedgerMaster);

      var data = { arguList: { id: 0, formData: vformData3 } };

      var apiURL = "GetInvoiceLByCode/0/token";
      const { arguList } = data;
      const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: response => {
          if (response.data.response[0].Id > 0) {
            rowsInput[index]["ItemType"] = response.data.response[0].ItemType;
            rowsInput[index]["Ply"] = response.data.response[0].Ply;
            rowsInput[index]["Length"] = response.data.response[0].Length;
            rowsInput[index]["LengthSoot"] =
              response.data.response[0].LengthSoot;
            rowsInput[index]["Height"] = response.data.response[0].Height;
            rowsInput[index]["Width"] = response.data.response[0].Width;
            rowsInput[index]["WidthSoot"] = response.data.response[0].WidthSoot;
            rowsInput[index]["HeightSoot"] =
              response.data.response[0].HeightSoot;
            rowsInput[index]["CalculationType"] =
              response.data.response[0].CalculationType;
            rowsInput[index]["Qty"] = response.data.response[0].Qty;
            //  rowsInput[index]["Rate"] = response.data.response[0].Rate;
            rowsInput[index]["PrintRate"] = response.data.response[0].PrintRate;
            rowsInput[index]["PrintDesc"] = response.data.response[0].PrintDesc;
            rowsInput[index]["Amount"] = response.data.response[0].Amount;
            rowsInput[index]["MainRate"] = response.data.response[0].MainRate;
            //  rowsInput[index]["CalRate"] = response.data.response[0].CalRate;

            var LengthSoot = response.data.response[0].LengthSoot;
            var WidthSoot = response.data.response[0].WidthSoot;
            var HeightSoot = response.data.response[0].HeightSoot;

            LengthSoot = eval(LengthSoot);
            if (LengthSoot == undefined) {
              LengthSoot = 0;
            }

            HeightSoot = eval(HeightSoot);
            if (HeightSoot == undefined) {
              HeightSoot = 0;
            }

            WidthSoot = eval(WidthSoot);
            if (WidthSoot == undefined) {
              WidthSoot = 0;
            }

            rowsInput[index]["IGST"] = response.data.response[0].IGST;
            var PrevRate = rowsInput[index]["MainRate"];
            var PrintRate = rowsInput[index]["PrintRate"];

            var Rate = this.calculation(
              response.data.response[0].CalculationType,
              response.data.response[0].Length,
              response.data.response[0].Width,
              response.data.response[0].Height,
              LengthSoot,
              HeightSoot,
              WidthSoot,
              PrevRate,
              PrintRate
            );
            rowsInput[index]["Rate"] = Rate;
            rowsInput[index]["CalRate"] = Rate;

            this.setState({ InvoiceDataL: rowsInput });
          } else {
            showToastWithCloseButton("error", "No Style Found ");
          }
        },
      };
      this.props.callAdd_Data_Multipart(request);
    }

    //Rate

    if (name == "Rate") {
      rowsInput[index]["Rate"] = value;
      rowsInput[index]["CalRate"] = value;
      if (rowsInput[index]["Qty"] != "") {
        var Qty = parseFloat(rowsInput[index]["Qty"]);
        var Amount = Qty * parseFloat(rowsInput[index]["Rate"]);
        rowsInput[index]["Amount"] = Amount.toFixed(2);
      }
    }

    if (name == "ItemType") {
      var Unit = "";
      const index1 = evnt.target.selectedIndex;
      const el = evnt.target.childNodes[index1];
      const option = el.getAttribute("id");
      rowsInput[index]["IGST"] = option;

      if (API_WEB_URLS.Customer == 1) {
        if (evnt.target.value == 33) {
          rowsInput[index]["IsBatta"] = true;
        } else {
          rowsInput[index]["IsBatta"] = false;
        }
      } else if (API_WEB_URLS.Customer == 4) {
        if (evnt.target.value == 1000600005) {
          rowsInput[index]["IsBatta"] = true;
        } else {
          rowsInput[index]["IsBatta"] = false;
        }
      } else if (API_WEB_URLS.Customer == 8) {
        if (evnt.target.value == 11) {
          rowsInput[index]["IsBatta"] = true;
        } else {
          rowsInput[index]["IsBatta"] = false;
        }
      }

      for (var i = 0; i < this.state.ItemType.length; i++) {
        if (this.state.ItemType[i].Id === index1) {
          Unit = this.state.ItemType[i].Unit;
        }
      }
      if (API_WEB_URLS.Customer == 2) {
        rowsInput[index]["RmgQty"] = Unit;
      }
    }

    //PrintDesc

    if (name == "PrintDesc") {
      const index1 = evnt.target.selectedIndex;
      const el = evnt.target.childNodes[index1];
      const option = el.getAttribute("id");

      var PrevRate = 0;
      var NewRate = 0;

      if (option == null || option == "null" || option == "") {
        var LastPrintRate = rowsInput[index]["PrintRate"];
        PrevRate = rowsInput[index]["Rate"];
        NewRate = parseFloat(PrevRate) - parseFloat(LastPrintRate);
        rowsInput[index]["Rate"] = NewRate;
        rowsInput[index]["PrintRate"] = 0;
        rowsInput[index]["CalRate"] = NewRate;
      } else {
        if (rowsInput[index]["CalRate"] == "") {
          PrevRate = rowsInput[index]["Rate"];
        } else {
          PrevRate = rowsInput[index]["CalRate"];
        }
        NewRate = parseFloat(option) + parseFloat(PrevRate);
        rowsInput[index]["Rate"] = NewRate;

        rowsInput[index]["PrintRate"] = option;
      }

      if (rowsInput[index]["Qty"] != "") {
        var Rate = rowsInput[index]["Rate"];

        var Qty = rowsInput[index]["Qty"];

        var Amount = parseFloat(Rate) * parseFloat(Qty);

        Amount = parseFloat(Amount).toFixed(2);

        rowsInput[index]["Amount"] = Amount;
      }
    }

    //Ply

    if (name == "Ply") {
      if (rowsInput[index]["IsBatta"] == true) {
        rowsInput[index]["MainRate"] =
          parseFloat(this.state.BattaRate[0].Rate) * parseFloat(value);
        rowsInput[index]["Rate"] =
          parseFloat(this.state.BattaRate[0].Rate) * parseFloat(value);
        console.log(
          parseFloat(this.state.BattaRate[0].Rate) * parseFloat(value)
        );
      } else {
        const index1 = evnt.target.selectedIndex;
        const el = evnt.target.childNodes[index1];
        const option = el.getAttribute("id");

        rowsInput[index]["MainRate"] = option;
        rowsInput[index]["Rate"] = option;

        if (rowsInput[index]["Length"] != "") {
          var cal = rowsInput[index]["CalculationType"];

          var PrevRate = rowsInput[index]["MainRate"];
          var PrintRate = rowsInput[index]["PrintRate"];
          if (name != "Rate") {
            var Rate = this.calculation(
              cal,
              Length,
              Width,
              Height,
              LengthSoot,
              HeightSoot,
              WidthSoot,
              PrevRate,
              PrintRate
            );
            rowsInput[index]["Rate"] = Rate;
            rowsInput[index]["CalRate"] = Rate;
          }

          if (rowsInput[index]["Qty"] != "") {
            var Rate = rowsInput[index]["Rate"];

            var Qty = rowsInput[index]["Qty"];

            var Amount = parseFloat(Rate) * parseFloat(Qty);

            Amount = parseFloat(Amount).toFixed(2);

            rowsInput[index]["Amount"] = Amount;
          }
        }
      }
    }

    //CalculationType

    if (name == "CalculationType") {
      //SimpleBox

      const index1 = evnt.target.selectedIndex;
      const el = evnt.target.childNodes[index1];
      const Id = el.getAttribute("id");
    }

    if (
      rowsInput[index]["IsBatta"] == true ||
      rowsInput[index]["ItemType"] == 999 ||
      (rowsInput[index]["ItemType"] == 9 && rowsInput[index]["Length"] > 0)
    ) {
      if (
        API_WEB_URLS.Customer == 1 ||
        API_WEB_URLS.Customer == 3 ||
        API_WEB_URLS.Customer == 8
      ) {
        if (name != "Ply") {
          var Rate =
            (((parseFloat(Length) + parseFloat(LengthSoot)) *
              (parseFloat(Width) + parseFloat(WidthSoot))) /
              1550) *
            parseFloat(rowsInput[index]["MainRate"]);
          rowsInput[index]["Rate"] = Rate.toFixed(2);

          console.log(Rate);
        }

        var PrintRate = rowsInput[index]["PrintRate"];
        console.log(PrintRate);
        if (PrintRate != "") {
          var PrintRate = PrintRate;
          Rate = parseFloat(Rate) + parseFloat(PrintRate);
          rowsInput[index]["Rate"] = Rate.toFixed(2);
        }

        if (name == "Rate") {
          rowsInput[index]["Rate"] = value;
        }
      }
    } else {
      if (rowsInput[index]["CalculationType"] != "") {
        var cal = rowsInput[index]["CalculationType"];

        var PrevRate = rowsInput[index]["MainRate"];
        var PrintRate = rowsInput[index]["PrintRate"];
        if (name != "Rate") {
          var Rate = this.calculation(
            cal,
            Length,
            Width,
            Height,
            LengthSoot,
            HeightSoot,
            WidthSoot,
            PrevRate,
            PrintRate
          );
          rowsInput[index]["Rate"] = Rate;
          rowsInput[index]["CalRate"] = Rate;
        }
      }
    }

    if (rowsInput[index]["Qty"] != "") {
      var Rate = rowsInput[index]["Rate"];

      var Qty = rowsInput[index]["Qty"];

      var Amount = parseFloat(Rate) * parseFloat(Qty);

      Amount = parseFloat(Amount).toFixed(2);

      rowsInput[index]["Amount"] = Amount;
    }

    this.gst();
  };

  roundNext5Paisa(number) {
    // Multiply the number by 100 to work with the decimal places directly
    const multipliedNumber = number * 100;

    // Determine the closest 0.05 increment for the multiplied number
    const remainder = multipliedNumber % 5;
    let roundedNumber;

    if (remainder === 0) {
      // The number is already divisible by 0.05, so no rounding needed
      roundedNumber = multipliedNumber;
    } else {
      // Round up to the next 0.05 increment
      roundedNumber = multipliedNumber + (5 - remainder);
    }

    // Divide by 100 to get the final result with two decimal places
    return roundedNumber / 100;
  }

  calculation(
    Type,
    Length,
    Width,
    Height,
    LengthSoot,
    HeightSoot,
    WidthSoot,
    PrevRate,
    PrintRate
  ) {
    var Safai = 0;
    var PinningFleb = 0;

    var l = parseFloat(Length) + parseFloat(LengthSoot);
    var w = parseFloat(Width) + parseFloat(WidthSoot);
    var h = parseFloat(Height) + parseFloat(HeightSoot);
    var Rate1 = 0;
    var Rate2 = 0;
    var Calculated = true;

    const result = this.state.CalculationType.find(obj => obj.Id == Type);

    // Check if the object is found
    if (result) {
      PinningFleb = result.PinningFleb;
      Safai = result.Safai;
    }

    if (Type == 1) {
      var Rate1 =
        (parseFloat(Length) +
          parseFloat(Width) +
          PinningFleb +
          parseFloat(LengthSoot) +
          parseFloat(WidthSoot)) *
        2;
      var Rate2 =
        parseFloat(Width) +
        parseFloat(WidthSoot) +
        parseFloat(Height) +
        parseFloat(HeightSoot) +
        parseFloat(Safai);
    } else if (Type == 2) {
      var Rate1 =
        (parseFloat(Length) +
          parseFloat(Width) +
          PinningFleb +
          parseFloat(LengthSoot) +
          parseFloat(WidthSoot)) *
        2;
      var Rate2 =
        parseFloat(Width) +
        parseFloat(WidthSoot) +
        parseFloat(Height) +
        parseFloat(HeightSoot) +
        parseFloat(Safai);
    } else if (Type == 3) {
      if (API_WEB_URLS.Customer == 5 || API_WEB_URLS.Customer == 6) {
        var Rate1 =
          (parseFloat(Length) +
            parseFloat(Width) +
            PinningFleb +
            parseFloat(LengthSoot) +
            parseFloat(WidthSoot)) *
          2;
        var Rate2 =
          parseFloat(Width) +
          parseFloat(WidthSoot) +
          parseFloat(Height) +
          parseFloat(HeightSoot) +
          parseFloat(Safai);
      } else {
        var Rate1 =
          parseFloat(Height) +
          parseFloat(Length) +
          parseFloat(Height) +
          parseFloat(HeightSoot) +
          parseFloat(HeightSoot) +
          parseFloat(LengthSoot) +
          PinningFleb;
        var Rate2 =
          parseFloat(Height) +
          parseFloat(HeightSoot) +
          parseFloat(Width) +
          parseFloat(WidthSoot) +
          parseFloat(HeightSoot) +
          parseFloat(Height) +
          Safai;
      }
    } else if (Type == 4) {
      if (API_WEB_URLS.Customer == 5 || API_WEB_URLS.Customer == 6) {
        var Rate1 =
          (parseFloat(Length) +
            parseFloat(Width) +
            PinningFleb +
            parseFloat(LengthSoot) +
            parseFloat(WidthSoot)) *
          2;
        var Rate2 =
          parseFloat(Width) +
          parseFloat(WidthSoot) +
          parseFloat(Height) +
          parseFloat(HeightSoot) +
          parseFloat(Safai);
      }
    } else if (Type == 5) {
      //   var Rate  =  ( ((parseFloat(Length)+parseFloat(LengthSoot) + parseFloat(Width)+ parseFloat(WidthSoot) + PinningFleb) * (parseFloat(Width)+ parseFloat(WidthSoot) + parseFloat(Width)+ parseFloat(WidthSoot) + parseFloat(Height) + parseFloat(HeightSoot)) * 2 ) ) / 1550;

      if (
        API_WEB_URLS.Customer == 4 ||
        API_WEB_URLS.Customer == 5 ||
        API_WEB_URLS.Customer == 6
      ) {
        var Rate1 =
          (parseFloat(Length) +
            parseFloat(Width) +
            PinningFleb +
            parseFloat(LengthSoot) +
            parseFloat(WidthSoot)) *
          2;
        var Rate2 =
          parseFloat(Width) +
          parseFloat(WidthSoot) +
          parseFloat(Height) +
          parseFloat(HeightSoot) +
          parseFloat(Safai);
      } else {
        var Rate1 = (l + w + PinningFleb) * 2;
        var Rate2 = w * 2 + h + Safai;
      }
    } else if (Type == 6) {
      //var Rate  =  (((parseFloat(Length) + parseFloat(LengthSoot) + parseFloat(Width) + parseFloat(WidthSoot) + PinningFleb ) * (((parseFloat(Width) + parseFloat(WidthSoot) )/2) + parseFloat(Height) + parseFloat(HeightSoot)  )  ) * 2 ) / 1550;

      if (
        API_WEB_URLS.Customer == 4 ||
        API_WEB_URLS.Customer == 5 ||
        API_WEB_URLS.Customer == 6
      ) {
        var Rate1 = (l + w + PinningFleb) * 2;
        var Rate2 = w * 2 + h + Safai;
      } else {
        var Rate1 = (l + w + PinningFleb) * 2;
        var Rate2 = w / 2 + h + Safai;
      }
    } else if (Type == 7) {
      if (this.state.CustomerId == 2) {
        var Rate =
          ((parseFloat(Length) +
            parseFloat(LengthSoot) +
            parseFloat(Width) +
            parseFloat(WidthSoot) +
            2) *
            (parseFloat(Width) +
              parseFloat(WidthSoot) +
              parseFloat(Height) +
              parseFloat(HeightSoot)) *
            2) /
          1550;
      } else if (
        API_WEB_URLS.Customer == 4 ||
        API_WEB_URLS.Customer == 5 ||
        API_WEB_URLS.Customer == 6
      ) {
        var Rate1 = (l + w + PinningFleb) * 2;
        var Rate2 = w * 2 + h + Safai;
      } else {
        var Rate1 =
          (parseFloat(Length) +
            parseFloat(Width) +
            PinningFleb +
            parseFloat(LengthSoot) +
            parseFloat(WidthSoot)) *
          2;
        var Rate2 =
          parseFloat(Width) +
          parseFloat(WidthSoot) +
          parseFloat(Height) +
          parseFloat(HeightSoot) +
          parseFloat(Safai);
      }
    } else if (Type == 8) {
      if (
        API_WEB_URLS.Customer == 4 ||
        API_WEB_URLS.Customer == 5 ||
        API_WEB_URLS.Customer == 6 ||
        API_WEB_URLS.Customer == 8 ||
        API_WEB_URLS.Customer == 9 ||
        API_WEB_URLS.Customer == 10
      ) {
        var Rate1 = (l + w + PinningFleb) * 2;
        var Rate2 = w * 2 + h + Safai;
      }
    } else if (Type == 9) {
      var Rate1 =
        (parseFloat(Length) +
          parseFloat(Width) +
          PinningFleb +
          parseFloat(LengthSoot) +
          parseFloat(WidthSoot)) *
        2;
      var Rate2 =
        parseFloat(Width) +
        parseFloat(WidthSoot) +
        parseFloat(Height) +
        parseFloat(HeightSoot) +
        parseFloat(Safai);
    } else if (Type == 10) {
      // var Rate  =  ( ((parseFloat(length)+parseFloat(LengthSoot))  + ((parseFloat(Width)+parseFloat(WidthSoot)/2) ) + PinningFleb *2 ) * ( (  (parseFloat(Width)+parseFloat(WidthSoot)/2) + (parseFloat(Height)+ parseFloat(HeightSoot)) + Safai  ))  ) / 1550;

      if (
        API_WEB_URLS.Customer == 4 ||
        API_WEB_URLS.Customer == 5 ||
        API_WEB_URLS.Customer == 6
      ) {
        var Rate1 =
          (parseFloat(Length) +
            parseFloat(Width) +
            PinningFleb +
            parseFloat(LengthSoot) +
            parseFloat(WidthSoot)) *
          2;
        var Rate2 =
          parseFloat(Width) +
          parseFloat(WidthSoot) +
          parseFloat(Height) +
          parseFloat(HeightSoot) +
          parseFloat(Safai);
      }
    } else if (Type == 11) {
      var Rate1 =
        parseFloat(Height) +
        parseFloat(Length) +
        parseFloat(Height) +
        parseFloat(HeightSoot) +
        parseFloat(HeightSoot) +
        parseFloat(LengthSoot) +
        PinningFleb;
      var Rate2 =
        parseFloat(Height) +
        parseFloat(HeightSoot) +
        parseFloat(Width) +
        parseFloat(WidthSoot) +
        parseFloat(HeightSoot) +
        parseFloat(Height) +
        Safai;
    } else if (Type == 12) {
      // var Rate  =  ( ((parseFloat(length)+parseFloat(LengthSoot))  + ((parseFloat(Width)+parseFloat(WidthSoot)/2) ) + PinningFleb *2 ) * ( (  (parseFloat(Width)+parseFloat(WidthSoot)/2) + (parseFloat(Height)+ parseFloat(HeightSoot)) + Safai  ))  ) / 1550;

      if (API_WEB_URLS.Customer == 4) {
        var Rate1 = (l + w + PinningFleb) * 2;
        var Rate2 = w / 2 + h + Safai;
      } else if (API_WEB_URLS.Customer == 6) {
        var Rate1 = (l + w / 2 + PinningFleb) * 2;
        var Rate2 = w / 2 + h + Safai;
      } else {
        var Rate1 = (l + w + PinningFleb) * 2;
        var Rate2 = w / 2 + h + Safai;
      }
    } else if (Type == 13) {
      // var Rate  =  ( ((parseFloat(length)+parseFloat(LengthSoot))  + ((parseFloat(Width)+parseFloat(WidthSoot)/2) ) + PinningFleb *2 ) * ( (  (parseFloat(Width)+parseFloat(WidthSoot)/2) + (parseFloat(Height)+ parseFloat(HeightSoot)) + Safai  ))  ) / 1550;

      if (API_WEB_URLS.Customer == 4 || API_WEB_URLS.Customer == 6) {
        var Rate1 =
          (parseFloat(Length) +
            parseFloat(Width) +
            PinningFleb +
            parseFloat(LengthSoot) +
            parseFloat(WidthSoot)) *
          2;
        var Rate2 =
          parseFloat(Width) +
          parseFloat(WidthSoot) +
          parseFloat(Height) +
          parseFloat(HeightSoot) +
          parseFloat(Safai);
      }
    } else if (Type == 14) {
      if (API_WEB_URLS.Customer == 5 || API_WEB_URLS.Customer == 6) {
        var Rate1 = (l + w) * 2 + PinningFleb;
        var Rate2 = w + h + Safai;
      }
    } else if (Type == 15) {
      if (API_WEB_URLS.Customer == 5 || API_WEB_URLS.Customer == 6) {
        var Rate1 = (l + w + PinningFleb * 2) * 2;
        var Rate2 = w + h + Safai;
      }
    } else if (Type == 16) {
      if (API_WEB_URLS.Customer == 5 || API_WEB_URLS.Customer == 6) {
        var Rate1 = (l + w) * 2 + PinningFleb;
        var Rate2 = w * 2 + h + Safai;
      }
    } else if (Type == 17) {
      if (API_WEB_URLS.Customer == 5 || API_WEB_URLS.Customer == 6) {
        var Rate1 = (l + w + PinningFleb * 2) * 2;
        var Rate2 = w * 2 + h + Safai;
      }
    }

    if (this.state.PlyPartyH[0].Calculated == true) {
      Rate2 = Math.ceil(Rate2);
      if (Rate2 % 2 !== 0) {
        Rate2 += 1;
      }
    }

    var Rate = (Rate1 * Rate2) / 1550;
    var PrevRate = PrevRate;
    Rate = parseFloat(Rate).toFixed(4) * parseFloat(PrevRate);
    Rate = parseFloat(Rate).toFixed(2);

    if (PrintRate != "") {
      var PrintRate = PrintRate;
      Rate = parseFloat(Rate) + parseFloat(PrintRate);
    }

    console.log(Rate1, Rate2);

    if (this.state.VoucherGlobalOptions[0].RoundTo5Paisa == true) {
      Rate = this.roundNext5Paisa(Rate);
    }

    return Rate;
  }

  validateCalculations(invoiceData) {
    for (let i = 0; i < invoiceData.length; i++) {
      const record = invoiceData[i];
      const qty = parseFloat(record.Qty);
      const rate = parseFloat(record.Rate);
      const amount = parseFloat(record.Amount);

      const calculatedAmount = (qty * rate).toFixed(2);

      if (calculatedAmount != amount.toFixed(2)) {
        console.log(calculatedAmount);
        console.log(qty);
        console.log(rate);
        console.log(amount);
        alert(`Error: Amount mismatch for record ${i + 1}`);
        return 2;
      }
    }

    alert("Calculation validation successful");
    return 1;
  }

  componentDidMount() {
    //navbar-header

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });

    //Filling DropDowns
    Fn_FillListDataGlobal(
      this.obj,
      "VoucherGlobalOptions",
      API_WEB_URLS.MASTER + "/0/token/VoucherGlobalOptions/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "Invoice",
      API_WEB_URLS.MASTER + "/0/token/Invoice/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "BoldToken",
      API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "ChargesLedger",
      API_WEB_URLS.MASTER + "/0/token/ChargesLedger/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "SalesLedger",
      API_WEB_URLS.MASTER + "/0/token/SalesLedger/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "Ledger",
      API_WEB_URLS.MASTER + "/0/token/LedgerForInvoice/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "CalculationType",
      API_WEB_URLS.MASTER + "/0/token/CalculationType/Id/0"
    );

    if (this.state.CustomerId == 2) {
      Fn_FillListData(
        this.obj,
        "Unit",
        API_WEB_URLS.MASTER + "/0/token/Unit/Id/0"
      );
    }

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }

  componentDidUpdate() {
    if (
      this.state.VoucherGlobalOptions[0].BackgroundColor != "" &&
      this.state.VoucherGlobalOptions[0].BackgroundColor != undefined &&
      this.state.VoucherGlobalOptions[0].BackgroundColor != null
    ) {
      const formLabels = document.querySelectorAll(".col-form-label");

      // Loop through the selected elements and change their color
      formLabels.forEach(label => {
        label.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const formcontrol = document.querySelectorAll(".form-control");

      // Loop through the selected elements and change their color
      formcontrol.forEach(label => {
        label.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].BackgroundColor;
        label.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const formselect = document.querySelectorAll(".form-select");
      // Loop through the selected elements and change their color
      formselect.forEach(label => {
        label.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].BackgroundColor;
        label.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const header = document.querySelectorAll(".navbar-header");
      // Loop through the selected elements and change their color
      header.forEach(label => {
        label.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].BackgroundColor;
        label.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const sidebar = document.querySelectorAll(".vertical-menu");
      // Loop through the selected elements and change their color
      sidebar.forEach(label => {
        const anchorTags = label.querySelectorAll("li a");

        anchorTags.forEach(anchor => {
          anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
        });
        label.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].BackgroundColor;
        label.style.backgroundImage = "none";
      });

      const anchorTags = document.querySelectorAll("th");
      anchorTags.forEach(anchor => {
        anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const button = document.querySelectorAll(".btn-danger");
      button.forEach(anchor => {
        anchor.style.backgroundColor =
          this.state.VoucherGlobalOptions[0].TextColor;
        anchor.style.color = this.state.VoucherGlobalOptions[0].BackgroundColor;
      });

      const headeritem = document.querySelectorAll(".header-item");
      headeritem.forEach(anchor => {
        anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const ul = document.querySelectorAll("ul li a i");
      ul.forEach(anchor => {
        anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });

      const ul2 = document.querySelectorAll("#sidebar-menu ul li.menu-title");
      ul2.forEach(anchor => {
        anchor.style.color = this.state.VoucherGlobalOptions[0].TextColor;
      });
    }
  }

  OnLedgerChange = event => {
    this.setState({
      save: false,
      F_LedgerMaster: event.target.value,
      LedgerId: event.target.value,
    });

    Fn_FillListData(
      this.obj,
      "ItemType",
      API_WEB_URLS.MASTER + "/0/token/ItemType/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "Ply",
      API_WEB_URLS.MASTER +
        "/0/token/GetItemByPlyPartyDescription/Id/" +
        event.target.value
    );

    Fn_FillListData(
      this.obj,
      "PrintDesc",
      API_WEB_URLS.MASTER +
        "/0/token/GetPrintPartyDescription/Id/" +
        event.target.value
    );

    Fn_FillListData(
      this.obj,
      "ConsigneeAddress",
      API_WEB_URLS.MASTER +
        "/0/token/LedgerConsigneeAddress/Id/" +
        event.target.value
    );

    Fn_FillListData(
      this.obj,
      "BattaRate",
      API_WEB_URLS.MASTER + "/0/token/BattaRate/Id/" + event.target.value
    );

    Fn_FillListData(
      this.obj,
      "PlyPartyH",
      API_WEB_URLS.MASTER + "/0/token/PlyPartyH/Id/" + event.target.value
    );
  };

  OnInvoiceChange = event => {
    this.setState({
      add: false,
      edit: false,
      cancel: true,
      save: true,
      einvoice: false,
      print: false,
      ReasonRemarks: "",
      CancelReasonId: -1,
      delete: false,
    });

    var iu = [];

    const rowsInput = [...iu];
    this.setState({
      Tax: rowsInput,
      IsCon: true,
      Add1: false,
      Add2: false,
      Add3: false,
      IsDiff: false,
      OtherAddress: "",
    });

    Fn_FillListData2(
      this.obj,
      "InvoiceData",
      API_WEB_URLS.MASTER +
        "/0/token/GetDetailByInvoice/Id/" +
        event.target.value
    );

    this.setState({
      InvoiceData: [
        {
          Through: "",
        },
      ],
      formData: {},
      InvoiceId: event.target.value,
      RoundedValue: 0,
    });

    this.setState({
      InvoiceDataL: [
        {
          IsDirectInvoice: true,
          CodeNo: "",
          ItemType: "",
          Ply: "",
          Length: "",
          LengthSoot: "",
          Width: "",
          WidthSoot: "",
          Height: "",
          HeightSoot: "",
          CalculationType: "",
          Qty: "",
          RmgQty: "",
          Rate: "",
          PrintDesc: "",
          PrintRate: "",
          Amount: "",
          MainRate: "",
          CalRate: "",
          IsBatta: "",
          DescriptionL: "",
        },
      ],
    });

    Fn_FillListData(
      this.obj,
      "ItemType",
      API_WEB_URLS.MASTER + "/0/token/ItemType/Id/0"
    );

    Fn_FillListData(
      this.obj,
      "InvoiceDataL",
      API_WEB_URLS.MASTER + "/0/token/InvoiceL/Id/" + event.target.value
    );
    Fn_FillListData(
      this.obj,
      "Tax",
      API_WEB_URLS.MASTER + "/0/token/InvoiceMasterTax/Id/" + event.target.value
    );

    Fn_FillListData(
      this.obj,
      "Charges",
      API_WEB_URLS.MASTER + "/0/token/Charges/Id/" + event.target.value
    );
  };

  btnSave_onClick(event, formData) {
    let validationResult = this.validateCalculations(this.state.InvoiceDataL);

    if (validationResult == 1) {
      var today = formatDate();
      var GrrDate = this.state.GRRDate;
      var PODate = this.state.PODate;
      var SalesLedgerId = this.state.SalesLedgerId;

      if (GrrDate == "" || GrrDate == "null" || GrrDate == null) {
        GrrDate = today;
      } else {
      }
      if (PODate == "" || PODate == "null" || PODate == null) {
        PODate = today;
      } else {
      }
      if (SalesLedgerId == "" || SalesLedgerId == null) {
        SalesLedgerId = 0;
      } else {
      }

      if (event.key == "Enter") {
        event.preventDefault();
      } else {
        var GrossAmount = sum(this.state.InvoiceDataL);

        var TaxationAmount = taxsum(this.state.Tax);

        var NetAmount = this.state.NetAmount;

        if (formData.InvoiceId == "" || formData.InvoiceId == -1) {
          //InvoiceH
          let vformData1 = new FormData();

          vformData1.append("VoucherNo", this.state.VoucherNo);
          vformData1.append("VoucherDate", this.state.VoucherDate);
          vformData1.append("ChallanNo", this.state.ChallanNo);
          vformData1.append("ChallanDate", this.state.ChallanDate);
          vformData1.append("PONo", this.state.PONo);
          vformData1.append("PODate", PODate);
          vformData1.append("F_LedgerMaster", this.state.LedgerId);
          vformData1.append("F_LedgerMasterSales", SalesLedgerId);
          vformData1.append("Transport", this.state.Transport);
          vformData1.append("GRRNo", this.state.GRRNo);
          vformData1.append("GRRDate", GrrDate);
          vformData1.append("VehcileNo", this.state.VehicleNo);
          vformData1.append("Through", this.state.Through);
          vformData1.append("Signatory", this.state.Signatory);

          //Amount
          vformData1.append("GrossAmount", GrossAmount);
          vformData1.append("TaxationAmount", TaxationAmount);
          vformData1.append("NetAmount", NetAmount);
          vformData1.append(
            "RoundedValue",
            this.state.RoundedValue == null || this.state.RoundedValue == ""
              ? 0
              : this.state.RoundedValue
          );
          vformData1.append("Unit1", false);
          vformData1.append("Unit2", false);
          vformData1.append("Unit3", false);
          vformData1.append("OtherAddress", this.state.OtherAddress);
          vformData1.append("F_CompanyMaster", 1);
          vformData1.append("IsCRN", this.state.IsCRN);
          vformData1.append("IsDBN", this.state.IsDBN);

          Fn_AddEditDataH(
            this.obj,
            { arguList: { id: 0, formData: vformData1 } },
            this.API_URL_SAVEH,
            this.pushFormName,
            true,
            "Id",
            JSON.stringify(this.state.InvoiceDataL),
            JSON.stringify(this.state.Tax),
            this.state.Charges,
            this.state.GSTTotalTaxAmount
          );

          console.log(JSON.stringify(this.state.InvoiceDataL));
        } else {
          this.setState({
            save: true,
            einvoice: false,
            InvoiceSel: false,
            cancel: true,
            add: false,
            print: false,
          });

          //InvoiceH
          let vformData1 = new FormData();

          vformData1.append("VoucherNo", this.state.VoucherNo);
          vformData1.append("VoucherDate", this.state.VoucherDate);
          vformData1.append("ChallanNo", this.state.ChallanNo);
          vformData1.append("ChallanDate", this.state.ChallanDate);
          vformData1.append("PONo", this.state.PONo);
          vformData1.append("PODate", PODate);
          vformData1.append("F_LedgerMaster", this.state.LedgerId);
          vformData1.append("F_LedgerMasterSales", SalesLedgerId);
          vformData1.append("Transport", this.state.Transport);
          vformData1.append("GRRNo", this.state.GRRNo);
          vformData1.append("GRRDate", GrrDate);
          vformData1.append("VehcileNo", this.state.VehicleNo);
          vformData1.append("Through", this.state.Through);
          vformData1.append("Signatory", this.state.Signatory);

          //Amount
          vformData1.append("GrossAmount", GrossAmount);
          vformData1.append("TaxationAmount", TaxationAmount);
          vformData1.append("NetAmount", NetAmount);
          vformData1.append(
            "RoundedValue",
            this.state.RoundedValue == null || this.state.RoundedValue == ""
              ? 0
              : this.state.RoundedValue
          );

          //Consignee Address

          vformData1.append("Unit1", this.state.Add1);
          vformData1.append("Unit2", this.state.Add2);
          vformData1.append("Unit3", this.state.Add3);
          vformData1.append("OtherAddress", this.state.OtherAddress);

          Fn_AddEditData(
            this.obj,
            { arguList: { id: formData.InvoiceId, formData: vformData1 } },
            this.API_URL_SAVEH,
            this.pushFormName,
            true
          );

          //InvoiceL
          let vformData = new FormData();

          vformData.append("F_InvoiceMasterH", formData.InvoiceId);
          vformData.append("Data", JSON.stringify(this.state.InvoiceDataL));

          Fn_AddEditData(
            this.obj,
            { arguList: { id: 0, formData: vformData } },
            this.API_URL_SAVE,
            this.pushFormName,
            true
          );

          let vformData2 = new FormData();

          vformData2.append("F_InvoiceMasterH", formData.InvoiceId);
          vformData2.append("Data", JSON.stringify(this.state.Tax));

          Fn_AddEditData(
            this.obj,
            { arguList: { id: 0, formData: vformData2 } },
            this.API_URL_SAVETAX,
            this.pushFormName,
            true
          );

          if (this.state.GSTTotalTaxAmount.length > 0) {
            let vformData4 = new FormData();

            vformData4.append("F_InvoiceMasterH", formData.InvoiceId);
            vformData4.append(
              "Data",
              JSON.stringify(this.state.GSTTotalTaxAmount)
            );

            Fn_AddEditData(
              this.obj,
              { arguList: { id: 0, formData: vformData4 } },
              this.API_URL_TAXABLEAMOUNT,
              this.pushFormName,
              true
            );
          }

          if (this.state.Charges.length > 0) {
            let vformData3 = new FormData();

            vformData3.append("F_InvoiceMasterH", formData.InvoiceId);
            vformData3.append("Data", JSON.stringify(this.state.Charges));

            Fn_AddEditData(
              this.obj,
              { arguList: { id: 0, formData: vformData3 } },
              this.API_URL_SAVECHARGE,
              this.pushFormName,
              true
            );
          }
        }
      }
    }
  }

  tog_fullscreen() {
    this.setState(prevState => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  valueset(name, evnt) {
    if (name === "VoucherNo") this.setState({ VoucherNo: evnt.target.value });
    else if (name === "VoucherDate")
      this.setState({ VoucherDate: evnt.target.value });
    else if (name === "ChallanNo")
      this.setState({ ChallanNo: evnt.target.value });
    else if (name === "ChallanDate")
      this.setState({ ChallanDate: evnt.target.value });
    else if (name === "PONo") this.setState({ PONo: evnt.target.value });
    else if (name === "PODate") this.setState({ PODate: evnt.target.value });
    else if (name === "GRRNo") this.setState({ GRRNo: evnt.target.value });
    else if (name === "GRRDate") this.setState({ GRRDate: evnt.target.value });
    else if (name === "LedgerId")
      this.setState({ LedgerId: evnt.target.value });
    else if (name === "SalesLedgerId")
      this.setState({ SalesLedgerId: evnt.target.value });
    else if (name === "Transport")
      this.setState({ Transport: evnt.target.value });
    else if (name === "VehicleNo")
      this.setState({ VehicleNo: evnt.target.value });
    else if (name === "Through") this.setState({ Through: evnt.target.value });
    else if (name === "Signatory")
      this.setState({ Signatory: evnt.target.value });
    else if (name === "IsDiff") this.setState({ IsDiff: evnt.target.value });
    else if (name === "Add1") this.setState({ Add1: evnt.target.value });
    else if (name === "Add2") this.setState({ Add2: evnt.target.value });
    else if (name === "Add3") this.setState({ Add3: evnt.target.value });
    else if (name === "OtherAddress")
      this.setState({ OtherAddress: evnt.target.value });
  }

  handler2 = (index, evnt) => {
    if (evnt.key == "Enter") {
      if (index == "VoucherNo") this.vno.current.focus();
      else if (index == "VoucherDate") this.vdate.current.focus();
      else if (index == "ChallanNo") {
        this.chno.current.focus();
      } else if (index == "ChallanDate") this.chdate.current.focus();
      else if (index == "PONo") this.pono.current.focus();
      else if (index == "PODate") this.podate.current.focus();
      else if (index == "GRRNo") this.grrno.current.focus();
      else if (index == "GRRDate") this.grrdate.current.focus();
      else if (index == "LedgerId") this.ledger.current.focus();
      else if (index == "SalesLedgerId") this.salesledger.current.focus();
      else if (index == "Transport") this.transport.current.focus();
      else if (index == "VehicleNo") this.vhno.current.focus();
      else if (index == "Through") this.through.current.focus();
      else if (index == "Signatory") this.signatory.current.focus();
      else if (index == "IsDiff") this.diffcon.current.focus();
      else if (index == "Add1") {
        if (this.state.IsDiff == true) this.IsAdd1.current.focus();
        else {
          //alert("Press 2 Tabs to procceed item list!");
          this.Code[0].current.focus();
        }
      } else if (index == "TranMode") {
        this.tranmode.current.focus();
      } else if (index == "TranDist") {
        this.trandist.current.focus();
      } else if (index == "done") {
        alert("Press 2 Tabs to procceed item list!");
      } else if (index == "Add2") this.IsAdd2.current.focus();
      else if (index == "Add3") this.IsAdd3.current.focus();
      else if (index == "Add4") {
        this.TranId.current.focus();
      }

      evnt.preventDefault();
    }
  };

  delete() {
    this.setState({ deletedlg: false });

    Fn_DeleteData(
      this.obj,
      this.state.InvoiceId,
      "Masters/0/token/InvoiceH",
      "Masters/0/token/Invoice" + "/Id/0"
    );
  }

  proceedeinvoice() {
    this.setState({ oldvouhcerdate: false });

    var VoucherNo = this.state.VoucherNo;
    let vformData3 = new FormData();
    vformData3.append("VoucherNo", VoucherNo);

    var data = { arguList: { id: 0, formData: vformData3 } };

    var apiURL = "CheckInvoice/0/token";
    const { arguList } = data;
    const request = {
      arguList: arguList,
      apiURL: apiURL,
      callback: response => {
        if (response.data.response[0].Id > 0) {
          showToastWithCloseButton(
            "error",
            "E-Invoice Already Registered. AckNo - " +
              response.data.response[0].Id
          );
        } else {
          let vformData4 = new FormData();

          vformData4.append("F_InvoiceMasterH", this.state.InvoiceId);

          const requestOptions = {
            method: "POST",
            body: vformData4,
          };
          var url = API_WEB_URLS.BASE + "EInvoice/0/token";
          fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
              if (data.data.data.id == 1 || data.data.data.id == "1") {
                showToastWithCloseButton(
                  "success",
                  "E-Invoice Created Successfully!"
                );
              } else {
                showToastWithCloseButton("success", data.data.data.name);
              }
            })
            .catch(error => {
              showToastWithCloseButton("error", "Somthing went wrong");
            });
        }
      },
    };
    this.props.callAdd_Data_Multipart(request);
  }

  einvoice() {
    const VoucherDate = this.state.VoucherDate;

    const currentDate = new Date();
    const givenDate = new Date(VoucherDate);

    const isToday = givenDate.toDateString() === currentDate.toDateString();

    if (isToday == true) {
      this.proceedeinvoice();
    } else {
      this.setState({ oldvouhcerdate: true });
    }
  }

  focusTextInput() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    this.textInput.current.focus();
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  searchinvoice(event) {
    Fn_FillListData(
      this.obj,
      "Invoice",
      API_WEB_URLS.MASTER + "/0/token/SearchInv/Id/" + event.target.value
    );
  }

  changeINV(name, value) {
    if (name == "CRN") {
      if (this.state.IsCRN == true) {
        this.setState({
          VoucherNo: this.state.VoucherGlobalOptions[0].VoucherNo,
        });
      } else {
        var originalString = this.state.VoucherGlobalOptions[0].VoucherNo;

        const transformedString =
          originalString.slice(0, 3) + "CRN/" + originalString.slice(3);
        this.setState({ VoucherNo: transformedString });
      }

      if (this.state.IsDBN == true) {
        this.setState({ IsDBN: false });
      }
      this.setState({ IsCRN: !this.state.IsCRN });
    } else if (name == "DBN") {
      if (this.state.IsDBN == true) {
        this.setState({
          VoucherNo: this.state.VoucherGlobalOptions[0].VoucherNo,
        });
      } else {
        var originalString = this.state.VoucherGlobalOptions[0].VoucherNo;

        const transformedString =
          originalString.slice(0, 3) + "DBN/" + originalString.slice(3);
        this.setState({ VoucherNo: transformedString });
      }
      if (this.state.IsCRN == true) {
        this.setState({ IsCRN: false });
      }
      this.setState({ IsDBN: !this.state.IsDBN });
    }
  }

  render() {
    var parameters = [
      {
        name: "Id",
        values: [this.state.InvoiceId],
      },
    ];
    const options = [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      ,
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
    ];

    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{
            backgroundColor:
              this.state.VoucherGlobalOptions[0].BackgroundColor != "" &&
              this.state.VoucherGlobalOptions[0].BackgroundColor != undefined &&
              this.state.VoucherGlobalOptions[0].BackgroundColor != null
                ? this.state.VoucherGlobalOptions[0].BackgroundColor
                : "white",
          }}
        >
          <Container fluid>
            {/* Render Breadcrumb */}

            <Row>
              <Col lg="12">
                <AvForm
                  className="needs-validation"
                  onValidSubmit={this.btnSave_onClick}
                >
                  <Card
                    style={{
                      backgroundColor:
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          "" &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          undefined &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          null
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "#f1ced4",
                    }}
                  >
                    <CardBody>
                      <Col lg="12">
                        <Row style={{ marginTop: -10 }}>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Select Invoice
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="SInvoice"
                              onChange={evnt => this.searchinvoice(evnt)}
                              placeholder="Search By Invoice No."
                              value={this.state.SInvoice}
                              type="text"
                              className="form-control"
                            ></input>
                          </Col>
                          <Col sm="4">
                            <AvField
                              name="InvoiceId"
                              onKeyPress={evnt =>
                                this.handler2("VoucherNo", evnt)
                              }
                              ref={this.invoice}
                              onChange={this.OnInvoiceChange}
                              style={{ "font-size": 12 }}
                              label=""
                              value={
                                this.state.formData.InvoiceId === null
                                  ? "-1"
                                  : this.state.formData.InvoiceId
                              }
                              type="select"
                              className="form-select"
                              disabled={this.state.InvoiceSel}
                            >
                              <option
                                value={-1}
                                defaultValue
                                label={"Select"}
                              />
                              {this.state.Invoice
                                ? this.state.Invoice.map((option, key) => (
                                    <option
                                      key={option.Id}
                                      value={option.Id}
                                      label={option.Name}
                                    />
                                  ))
                                : null}
                            </AvField>
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Is CRN
                            </label>
                          </Col>
                          <Col sm="1">
                            <input
                              name="IsCRN"
                              onChange={evnt => this.changeINV("CRN", evnt)}
                              label=""
                              checked={this.state.IsCRN}
                              type="checkbox"
                            />
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Is DBN
                            </label>
                          </Col>
                          <Col sm="1">
                            <input
                              name="IsDBN"
                              onChange={evnt => this.changeINV("DBN", evnt)}
                              label=""
                              checked={this.state.IsDBN}
                              type="checkbox"
                            />
                          </Col>
                        </Row>

                        <Row style={{ marginTop: -14 }}>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              V No.
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              className="form-control"
                              type="text"
                              name="VoucherNo"
                              ref={this.vno}
                              onKeyPress={evnt =>
                                this.handler2("VoucherDate", evnt)
                              }
                              value={this.state.VoucherNo}
                              onChange={evnt =>
                                this.valueset("VoucherNo", evnt)
                              }
                              disabled={
                                this.state.VoucherGlobalOptions[0]
                                  .IsVoucherNoEnable
                              }
                            />
                            {/* <input name="VoucherNo"  onChange={(evnt)=>(this.handler2('VoucherDate' ,evnt))} value={this.state.VoucherNo}       ref={this.vno} label=""   type="text" className="form-control" >
                                          </input> */}

                            {/* <AvField name="VoucherNo"     label="" value={this.state.formData.VoucherNo === null ? this.state.formData.VoucherNo   : this.state.InvoiceData[0].VoucherNo}  type="text" className="form-control" >
                                          </AvField> */}
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              V Date
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="VoucherDate"
                              onChange={evnt =>
                                this.valueset("VoucherDate", evnt)
                              }
                              onKeyPress={evnt =>
                                this.handler2("ChallanNo", evnt)
                              }
                              ref={this.vdate}
                              label=""
                              value={this.state.VoucherDate}
                              type="date"
                              className="form-control"
                            ></input>
                            {/* <AvField name="VoucherDate"     label="" value={this.state.formData.VoucherDate === null ?    this.state.InvoiceData[0].VoucherDate : today}  type="date" className="form-control" >
                                          </AvField> */}
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Ch. No.
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="ChallanNo"
                              onChange={evnt =>
                                this.valueset("ChallanNo", evnt)
                              }
                              ref={this.chno}
                              onKeyPress={evnt =>
                                this.handler2("ChallanDate", evnt)
                              }
                              label=""
                              value={this.state.ChallanNo}
                              type="text"
                              className="form-control"
                            ></input>

                            {/* <AvField name="ChallanNo"   label="" value={this.state.formData.ChallanNo === null ? '-1'   : this.state.InvoiceData[0].ChallanNo}  type="text" className="form-control" >
                                          </AvField> */}
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Ch. Date
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="ChallanDate"
                              onChange={evnt =>
                                this.valueset("ChallanDate", evnt)
                              }
                              ref={this.chdate}
                              onKeyPress={evnt => this.handler2("PONo", evnt)}
                              label=""
                              value={this.state.ChallanDate}
                              type="date"
                              className="form-control"
                            ></input>
                            {/* <AvField name="ChallanDate"   label="" value={this.state.formData.ChallanDate === null ? this.state.InvoiceData[0].ChallanDate : today}  type="date" className="form-control" >
                                          </AvField>  */}
                          </Col>
                        </Row>

                        <Row style={{ marginTop: -14 }}>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              PO No.
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="PONo"
                              onChange={evnt => this.valueset("PONo", evnt)}
                              ref={this.pono}
                              onKeyPress={evnt => this.handler2("PODate", evnt)}
                              label=""
                              value={this.state.PONo}
                              type="text"
                              className="form-control"
                            ></input>

                            {/* <AvField name="PONo"   label="" value={this.state.formData.PONo === null ? '-1'   : this.state.InvoiceData[0].PONo}  type="text" className="form-control" >
                                          </AvField> */}
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              PO Date
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="PODate"
                              onChange={evnt => this.valueset("PODate", evnt)}
                              ref={this.podate}
                              onKeyPress={evnt => this.handler2("GRRNo", evnt)}
                              label=""
                              value={this.state.PODate}
                              type="date"
                              className="form-control"
                            ></input>

                            {/* <AvField name="PODate"   label="" value={this.state.formData.PODate === null ?  this.state.InvoiceData[0].PODate : today}  type="date" className="form-control" >
                                          </AvField> */}
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              GRR No.
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="GRRNo"
                              onChange={evnt => this.valueset("GRRNo", evnt)}
                              ref={this.grrno}
                              onKeyPress={evnt =>
                                this.handler2("GRRDate", evnt)
                              }
                              label=""
                              value={this.state.GRRNo}
                              type="text"
                              className="form-control"
                            ></input>

                            {/* <AvField name="GRRNo"   label="" value={this.state.formData.GRRNo === null ? '-1'   : this.state.InvoiceData[0].GRRNo}  type="text" className="form-control" >
                                          </AvField> */}
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              GRR Date
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="GRRDate"
                              onChange={evnt => this.valueset("GRRDate", evnt)}
                              ref={this.grrdate}
                              onKeyPress={evnt =>
                                this.handler2("LedgerId", evnt)
                              }
                              label=""
                              value={this.state.GRRDate}
                              type="date"
                              className="form-control"
                            ></input>

                            {/* <AvField name="GRRDate"    label="" value={this.state.formData.GRRDate === null ? this.state.InvoiceData[0].GRRDate   : today}  type="date" className="form-control" >
                                          </AvField> */}
                          </Col>
                        </Row>

                        <Row style={{ marginTop: -14 }}>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Ledger
                            </label>
                          </Col>
                          <Col sm="5">
                            <select
                              name="LedgerId"
                              ref={this.ledger}
                              menuIsOpen={true}
                              onKeyPress={evnt =>
                                this.handler2("Transport", evnt)
                              }
                              onChange={this.OnLedgerChange}
                              label=""
                              value={this.state.LedgerId}
                              type="select"
                              className="form-select"
                            >
                              <option
                                value={-1}
                                defaultValue
                                label={"Select"}
                              />
                              {this.state.Ledger
                                ? this.state.Ledger.map((option, key) => (
                                    <option
                                      key={option.Id}
                                      value={option.Id}
                                      label={option.Name}
                                    />
                                  ))
                                : null}
                            </select>
                            {/* 
<AvField name="LedgerId"  onChange={this.OnLedgerChange}  label="" value={this.state.formData.LedgerId === null ? '-1'   : this.state.InvoiceData[0].F_LedgerMaster}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.Ledger
                                              ? this.state.Ledger.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField> */}
                          </Col>
                          {/* <Col sm="1" className="mb-3">
                                          <label htmlFor="maritalStatus" className="col-form-label">Sales Led.</label>
                                        </Col>
                                        <Col sm="2">
                                          <select name="SalesLedgerId" onChange={(evnt)=>(this.valueset('SalesLedgerId' ,evnt))} ref={this.salesledger} onKeyPress={(evnt)=>(this.handler2('Transport' ,evnt))}  label="" value={this.state.SalesLedgerId}  type="select" className="form-select" >
                                            <option value={0} defaultValue label={"Select"} />
                                            {this.state.SalesLedger
                                              ? this.state.SalesLedger.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </select>
                                          </Col> */}
                          {/* <AvField name="SalesLedgerId"   label="" value={this.state.formData.SalesLedgerId === null ? '-1'   : this.state.InvoiceData[0].F_LedgerMasterSales}  type="select" className="form-select" >
                                            <option value={0} defaultValue label={"Select"} />
                                            {this.state.SalesLedger
                                              ? this.state.SalesLedger.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField> */}

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Transport
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="Transport"
                              onChange={evnt =>
                                this.valueset("Transport", evnt)
                              }
                              ref={this.transport}
                              onKeyPress={evnt =>
                                this.handler2("VehicleNo", evnt)
                              }
                              label=""
                              value={this.state.Transport}
                              type="text"
                              className="form-control"
                            ></input>

                            {/* <AvField name="Transport"   label="" value={this.state.formData.Transport === null ? '-1'   : this.state.InvoiceData[0].Transport}  type="text" className="form-control" >
                                          </AvField> */}
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Vh. No.
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="VehicleNo"
                              onChange={evnt =>
                                this.valueset("VehicleNo", evnt)
                              }
                              ref={this.vhno}
                              onKeyPress={evnt =>
                                this.handler2("Through", evnt)
                              }
                              label=""
                              value={this.state.VehicleNo}
                              type="text"
                              className="form-control"
                            ></input>
                            {/* <AvField name="VehicleNo"   label="" value={this.state.formData.VehicleNo === null ? '-1'   : this.state.InvoiceData[0].VehicleNo}  type="text" className="form-control" >
                                          </AvField> */}
                          </Col>
                        </Row>

                        <Row style={{ marginTop: -14 }}>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Through
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="Through"
                              onChange={evnt => this.valueset("Through", evnt)}
                              ref={this.through}
                              onKeyPress={evnt =>
                                this.handler2("Signatory", evnt)
                              }
                              label=""
                              value={this.state.Through}
                              type="text"
                              className="form-control"
                            ></input>

                            {/* <AvField name="Through"  label="" value={this.state.formData.Through === '' ? this.state.InvoiceData[0].Through   : this.state.InvoiceData[0].Through}  type="text" className="form-control" >
                                          </AvField> */}
                          </Col>
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Signatory
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="Signatory"
                              onChange={evnt =>
                                this.valueset("Signatory", evnt)
                              }
                              ref={this.signatory}
                              onKeyPress={evnt => this.handler2("IsDiff", evnt)}
                              label=""
                              value={this.state.Signatory}
                              type="text"
                              className="form-control"
                            ></input>
                            {/* <AvField name="Signatory"   label="" value={this.state.formData.Signatory === null ? '-1'   : this.state.InvoiceData[0].Signatory}  type="text" className="form-control" >
                                          </AvField> */}
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              diff.con.
                            </label>
                          </Col>
                          <Col sm="2">
                            <input
                              name="IsDiff"
                              ref={this.diffcon}
                              onKeyPress={evnt => this.handler2("Add1", evnt)}
                              onChange={this.OnConChange}
                              label=""
                              checked={this.state.IsDiff}
                              type="checkbox"
                            />
                            {/* <AvField name="IsDiff"   onChange={this.OnConChange}   label="" value={this.state.formData.IsDiff === null ? '-1'   : this.state.InvoiceData[0].IsDiff}  type="checkbox"  /> */}
                          </Col>
                        </Row>

                        <Row
                          style={{ marginTop: -14 }}
                          hidden={this.state.IsCon}
                        >
                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Add1
                            </label>
                          </Col>
                          <Col sm="1">
                            <input
                              name="Add1"
                              ref={this.IsAdd1}
                              onKeyPress={evnt => this.handler2("Add2", evnt)}
                              onChange={this.getAddress}
                              label=""
                              checked={this.state.Add1}
                              type="checkbox"
                            ></input>

                            {/* <AvField name="Add1"    onChange={this.getAddress}  label=""  value={this.state.formData.Add1 === null ? this.state.Add1   : this.state.Add1} checked={this.state.Add1}  type="checkbox" className="form-control" >
                                          </AvField> */}
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Add2
                            </label>
                          </Col>
                          <Col sm="1">
                            <input
                              name="Add2"
                              ref={this.IsAdd2}
                              onKeyPress={evnt => this.handler2("Add3", evnt)}
                              onChange={this.getAddress}
                              label=""
                              checked={this.state.Add2}
                              type="checkbox"
                            ></input>

                            {/* <AvField name="Add2"   onChange={this.getAddress}   label=""  value={this.state.formData.Add2 === null ? this.state.Add2   : this.state.Add2} checked={this.state.Add2} type="checkbox" className="form-control" >
                                          </AvField> */}
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Add3
                            </label>
                          </Col>
                          <Col sm="1">
                            <input
                              name="Add3"
                              ref={this.IsAdd3}
                              onKeyPress={evnt => this.handler2("TranId", evnt)}
                              onChange={this.getAddress}
                              label=""
                              checked={this.state.Add3}
                              type="checkbox"
                            ></input>
                            {/* <AvField name="Add3"   onChange={this.getAddress}   label=""  value={this.state.formData.Add3 === null ? this.state.Add3   : this.state.Add3} checked={this.state.Add3} type="checkbox" className="form-control" >
                                          </AvField> */}
                          </Col>

                          <Col sm="1" className="mb-3">
                            <label
                              htmlFor="maritalStatus"
                              className="col-form-label"
                            >
                              Oth.
                            </label>
                          </Col>

                          <Col sm="4">
                            <input
                              name="OtherAddress"
                              onChange={evnt =>
                                this.valueset("OtherAddress", evnt)
                              }
                              onKeyPress={evnt => this.handler2("TranId", evnt)}
                              label=""
                              value={
                                this.state.OtherAddress === null
                                  ? ""
                                  : this.state.OtherAddress
                              }
                              type="text"
                              className="form-control"
                            ></input>
                            {/* <AvField name="OtherAddress"    label=""  value={this.state.OtherAddress === null ? '' : this.state.OtherAddress}  type="text" className="form-control" disabled>
                                          </AvField> */}
                          </Col>
                        </Row>
                      </Col>
                    </CardBody>
                  </Card>
                  <Card
                    style={{
                      backgroundColor:
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          "" &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          undefined &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          null
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "#92d2e8",
                    }}
                  >
                    <CardBody>
                      <AddDeleteTableRows
                        itemType={this.state.ItemType}
                        ply={this.state.Ply}
                        printdesc={this.state.PrintDesc}
                        calculationtype={this.state.CalculationType}
                        data={this.state.InvoiceDataL}
                        add={this.addrow}
                        edit={this.editrow}
                        deleter={this.deleterow}
                        plyquantity={this.state.PlyQuantity}
                        Unit={this.state.Unit}
                        CustomerId={this.state.CustomerId}
                        Code={this.Code}
                      />
                    </CardBody>
                  </Card>

                  <Card
                    style={{
                      backgroundColor:
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          "" &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          undefined &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          null
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "rgb(233, 207, 241)",
                    }}
                  >
                    <CardBody>
                      <Row>
                        <Col sm="4">
                          <AddDeleteTableRowsCharges
                            data={this.state.Charges}
                            addTableRows={this.addchargesrow}
                            Ledger={this.state.ChargesLedger}
                            edit={this.editChargesrow}
                          />
                        </Col>

                        <Col sm="4">
                          <AddDeleteTableRowsTax data={this.state.Tax} />
                        </Col>

                        <Col sm="4">
                          <Row>
                            <Col sm="4">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Gross. Amt.
                              </label>
                            </Col>
                            <Col sm="5">
                              <AvField
                                name="GrossAmount"
                                label=""
                                value={this.state.GrossAmount}
                                type="text"
                                className="form-control"
                                disabled
                              ></AvField>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="4" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Tax. Amt.
                              </label>
                            </Col>
                            <Col sm="5">
                              <AvField
                                name="TaxAmount"
                                label=""
                                value={this.state.TaxAmount}
                                type="text"
                                className="form-control"
                                disabled
                              ></AvField>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="4" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Rounded Off
                              </label>
                            </Col>
                            <Col sm="5">
                              <AvField
                                name="Roundedvalue"
                                label=""
                                value={this.state.RoundedValue}
                                type="text"
                                className="form-control"
                                disabled
                              ></AvField>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="4" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Net. Amt.
                              </label>
                            </Col>
                            <Col sm="5">
                              <AvField
                                name="NetAmount"
                                label=""
                                value={this.state.NetAmount}
                                type="text"
                                className="form-control"
                                disabled
                              ></AvField>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: -14 }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ width: "60px" }}> EWAY</th>
                              <th>
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  onClick={() => {
                                    this.setState({ IsEway: true });
                                  }}
                                >
                                  +
                                </button>
                              </th>
                            </tr>
                          </thead>
                        </table>

                        {this.state.IsEway == true ? (
                          <>
                            <Col sm="1" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Trans. Id
                              </label>
                            </Col>
                            <Col sm="2">
                              <input
                                name="TransportId"
                                ref={this.TranId}
                                onKeyPress={evnt =>
                                  this.handler2("TranMode", evnt)
                                }
                                onChange={e =>
                                  Fn_ChangeStateValue(
                                    this.obj,
                                    "TransportId",
                                    e.target.value
                                  )
                                }
                                label=""
                                value={this.state.TransportId}
                                type="text"
                                className="form-control"
                              ></input>
                            </Col>
                            <Col sm="1" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Mode
                              </label>
                            </Col>
                            <Col sm="2">
                              <select
                                name="TransportMode"
                                ref={this.tranmode}
                                onKeyPress={evnt =>
                                  this.handler2("TranDist", evnt)
                                }
                                menuIsOpen={true}
                                onChange={e =>
                                  Fn_ChangeStateValue(
                                    this.obj,
                                    "TransportMode",
                                    e.target.value
                                  )
                                }
                                value={this.state.TransportMode}
                                type="select"
                                className="form-select"
                              >
                                <option
                                  value={-1}
                                  defaultValue
                                  label={"Select Transport Mode"}
                                />
                                <option value={1} label={"Road"} />
                                <option value={2} label={"Rail"} />
                                <option value={3} label={"Air"} />
                                <option value={4} label={"Ship"} />
                              </select>
                            </Col>

                            <Col sm="1" className="mb-3">
                              <label
                                htmlFor="maritalStatus"
                                className="col-form-label"
                              >
                                Trans. Dist.
                              </label>
                            </Col>
                            <Col sm="2">
                              <input
                                name="TransportDistance"
                                ref={this.trandist}
                                onKeyPress={evnt => this.handler2("done", evnt)}
                                onChange={e =>
                                  Fn_ChangeStateValue(
                                    this.obj,
                                    "TransportDistance",
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control"
                              />
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    </CardBody>
                  </Card>

                  <Card
                    style={{
                      backgroundColor:
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          "" &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          undefined &&
                        this.state.VoucherGlobalOptions[0].BackgroundColor !=
                          null
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "#a8ead4",
                    }}
                  >
                    <CardBody>
                      <div>
                        <Button
                          type="button"
                          disabled={this.state.add}
                          color="danger"
                          ref={this.AddBtn}
                          style={{ marginLeft: 10 }}
                          onClick={this.add}
                          className="mr-1 waves-effect waves-light"
                        >
                          Add
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          disabled={this.state.edit}
                          style={{ marginLeft: 10 }}
                          onClick={this.edit}
                          className="mr-1 waves-effect waves-light"
                        >
                          {this.state.editbtnname}
                        </Button>
                        <Button
                          disabled={this.state.save}
                          type="submit"
                          color="danger"
                          style={{ marginLeft: 10 }}
                          className="mr-1 waves-effect waves-light"
                        >
                          Save
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          disabled={this.state.cancel}
                          style={{ marginLeft: 10 }}
                          onClick={this.cancel}
                          className="mr-1 waves-effect waves-light"
                        >
                          Cancel
                        </Button>

                        {API_WEB_URLS.Customer == 10 ||
                        API_WEB_URLS.Customer == 9 ? null : (
                          <Button
                            type="button"
                            color="danger"
                            disabled={this.state.einvoice}
                            style={{ marginLeft: 10 }}
                            onClick={this.einvoice}
                            className="mr-1 waves-effect waves-light"
                          >
                            E-Invoice
                          </Button>
                        )}

                        <Button
                          type="button"
                          color="danger"
                          disabled={this.state.print}
                          style={{ marginLeft: 10 }}
                          onClick={this.tog_fullscreen}
                          className="mr-1 waves-effect waves-light"
                        >
                          Print
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          // disabled={this.state.delete}
                          style={{ marginLeft: 10 }}
                          onClick={this.copy}
                          className="mr-1 waves-effect waves-light"
                        >
                          Copy
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          //  disabled={this.state.delete}
                          style={{ marginLeft: 10 }}
                          onClick={this.paste}
                          className="mr-1 waves-effect waves-light"
                        >
                          Paste
                        </Button>

                        <Button
                          type="button"
                          color="danger"
                          disabled={this.state.delete}
                          style={{ marginLeft: 10 }}
                          onClick={() => this.setState({ deletedlg: true })}
                          className="mr-1 waves-effect waves-light"
                        >
                          Delete
                        </Button>

                        {API_WEB_URLS.Customer == 10 ||
                        API_WEB_URLS.Customer == 9 ? null : (
                          <Button
                            type="button"
                            color="danger"
                            disabled={this.state.canceleinvoiceBtn}
                            style={{ marginLeft: 10 }}
                            onClick={() =>
                              this.setState({ canceleinvoice: true })
                            }
                            className="mr-1 waves-effect waves-light"
                          >
                            {this.state.cancelinvoiceName}
                          </Button>
                        )}

                        {this.state.success_msg ? (
                          <SweetAlert
                            title="Data Saved Successfully!"
                            success
                            confirmBtnBsStyle="success"
                            onConfirm={this.syno}
                          >
                            You clicked the button!
                          </SweetAlert>
                        ) : null}

                        {this.state.canceleinvoice ? (
                          <>
                            <SweetAlert
                              warning
                              showCancel
                              confirmBtnText="Yes, Cancel it!"
                              confirmBtnBsStyle="success"
                              cancelBtnBsStyle="danger"
                              onConfirm={() => this.canceleinvoice()}
                              onCancel={() =>
                                this.setState({ canceleinvoice: false })
                              }
                              title="Are you sure to cancel ?"
                            >
                              <Col sm="2" className="mb-3">
                                <label
                                  htmlFor="maritalStatus"
                                  className="col-form-label"
                                >
                                  VoucherNo:{" "}
                                  {this.state.InvoiceData[0].VoucherNo}
                                </label>
                              </Col>
                              <Col sm="4" className="mb-3">
                                <label
                                  htmlFor="maritalStatus"
                                  className="col-form-label"
                                >
                                  Cancel Reason
                                </label>
                              </Col>

                              <Col sm="8" className="mb-3">
                                <select
                                  name="CancelReasonId"
                                  menuIsOpen={true}
                                  onChange={e =>
                                    Fn_ChangeStateValue(
                                      this.obj,
                                      "CancelReasonId",
                                      e.target.value
                                    )
                                  }
                                  value={this.state.CancelReasonId}
                                  type="select"
                                  className="form-select"
                                >
                                  <option
                                    value={-1}
                                    defaultValue
                                    label={"Select Reason"}
                                  />
                                  <option value={1} label={"Duplicate"} />
                                  <option
                                    value={2}
                                    label={"Data Entry Mistake"}
                                  />
                                  <option value={3} label={"Order Cancel"} />
                                  <option value={4} label={"Others"} />
                                </select>
                              </Col>

                              <Col sm="4" className="mb-3">
                                {" "}
                                <label
                                  htmlFor="maritalStatus"
                                  className="col-form-label"
                                >
                                  Reason Remarks
                                </label>
                              </Col>
                              <Col sm="8" className="mb-3">
                                <input
                                  name="ReasonRemarks"
                                  onChange={e =>
                                    Fn_ChangeStateValue(
                                      this.obj,
                                      "ReasonRemarks",
                                      e.target.value
                                    )
                                  }
                                  label="Reason Remarks"
                                  value={
                                    this.state.ReasonRemarks == ""
                                      ? this.state.ReasonRemarks
                                      : this.state.ReasonRemarks
                                  }
                                  type="text"
                                  className="form-control"
                                ></input>
                              </Col>
                            </SweetAlert>
                          </>
                        ) : null}

                        {this.state.oldvouhcerdate ? (
                          <>
                            <SweetAlert
                              warning
                              showCancel
                              confirmBtnText="Yes, Proceed!"
                              confirmBtnBsStyle="success"
                              cancelBtnBsStyle="danger"
                              onConfirm={() => this.proceedeinvoice()}
                              onCancel={() =>
                                this.setState({ oldvouhcerdate: false })
                              }
                              title="Old VoucherDate Are you sure to Proceed ?"
                            ></SweetAlert>
                          </>
                        ) : null}

                        {this.state.deletedlg ? (
                          <>
                            <SweetAlert
                              warning
                              showCancel
                              confirmBtnText="Yes, Proceed!"
                              confirmBtnBsStyle="success"
                              cancelBtnBsStyle="danger"
                              onConfirm={() => this.delete()}
                              onCancel={() =>
                                this.setState({ deletedlg: false })
                              }
                              title="Are you sure to delete ?"
                            >
                              Data will can't be retrieve !
                            </SweetAlert>
                          </>
                        ) : null}
                      </div>
                    </CardBody>
                  </Card>

                  <Card
                    style={{
                      backgroundColor:
                        API_WEB_URLS.Customer === 4
                          ? this.state.VoucherGlobalOptions[0].BackgroundColor
                          : "#a8ead4",
                    }}
                  >
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>

                      <div>
                        <Modal
                          size="xl"
                          isOpen={this.state.modal_fullscreen}
                          toggle={this.tog_fullscreen}
                          className="modal-fullscreen"
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title mt-0"
                              id="exampleModalFullscreenLabel"
                            >
                              Print
                            </h5>
                            <button
                              onClick={() =>
                                this.setState({ modal_fullscreen: false })
                              }
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            {API_WEB_URLS.Customer == 1 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/TrueCartoons/TrueCartoonsBillNew"}
                                printMode={true}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : API_WEB_URLS.Customer == 7 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Shanti/ShantiBill"}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : API_WEB_URLS.Customer == 2 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Chandra/ChandraBill"}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : API_WEB_URLS.Customer == 4 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Usha/UshaBill"}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : API_WEB_URLS.Customer == 3 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Anna/AnnaBillNew"}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : API_WEB_URLS.Customer == 5 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Ankit/AnkitBill"}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : API_WEB_URLS.Customer == 6 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Shanti/ShantiBill"}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : API_WEB_URLS.Customer == 8 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Krishna/KrishnaBill"}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : API_WEB_URLS.Customer == 9 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Sakshi/SakshiBill"}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : API_WEB_URLS.Customer == 10 ? (
                              <BoldReportViewerComponent
                                id="reportviewer-container"
                                reportServiceUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/reportservice/api/Viewer"
                                }
                                reportServerUrl={
                                  "https://boldreports.shinewellinnovation.com/reporting/api/site/site1"
                                }
                                serviceAuthorizationToken={
                                  "bearer " + this.state.BoldToken[0].Token
                                }
                                reportPath={"/Shree/ShreeBill"}
                                parameters={parameters}
                              ></BoldReportViewerComponent>
                            ) : null}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              onClick={this.tog_fullscreen}
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </Modal>
                      </div>
                    </CardBody>
                  </Card>
                </AvForm>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_Invoice);
