export const IS_LOADING = 'IS_LOADING';
export const AUTH_DATA = 'AUTH_DATA';
export const PROFILE_DATA = 'PROFILE_DATA';
export const GET_ALL_LIST = 'GET_ALL_LIST';
//
export const FILL_GRID_DATA = 'FILL_GRID_DATA';
export const GET_DATA = 'GET_DATA';
export const ADD_DATA_MULTIPART = 'ADD_DATA_MULTIPART';
export const EDIT_DATA_MULTIPART = 'EDIT_DATA_MULTIPART';
export const ADD_DATA = 'ADD_DATA';
export const EDIT_DATA = 'EDIT_DATA';
export const DELETE_DATA = 'DELETE_DATA';
//