import React ,{useState , useRef} from "react";
import TableRowsPrint from "./TableRowsPrint";
function AddDeleteTableRowsPrint({  data , add , edit   , PrintDescription , print, del}){



    const [rowsData, setRowsData] = useState([]);
  
  
   const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
   }

   

 
    return(
        <div className="table-responsive">
                <table className="" style={{"padding": "0" , "marginBottom" : "10px"}}>
                    <thead>
                      <tr>
                      <th>PrintDiscription</th>
                      &nbsp;
                            <th>Rate</th> 


                            <th><button type="button" className="btn btn-outline-success" onClick={add} >+</button></th>
                      </tr>
                    </thead>
                    <tbody>
                   <TableRowsPrint rowsData={data}   add ={add} printdescription={PrintDescription}  print={print} edit={edit} del={del}/>
                   </tbody> 
                </table>
              
        </div>
    )
}
export default AddDeleteTableRowsPrint