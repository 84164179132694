import React ,{useState , useRef} from "react";
import TableRows from "./TableRow";
function AddDeleteTableRows({ itemType , ply , printdesc, calculationtype , data , add , edit , deleter , plyquantity}){



    const [rowsData, setRowsData] = useState([]);
  


    const addTableRows = ()=>{
  

     

        const rowsInput={
            CodeNo:"",
            ItemType : '',
            Ply : '',
            Length : '',
            LengthSoot : '',
            LengthManual : '',
            Width: '',
            WidthSoot : '',
            widthManual : '',
            Height : '',
            HeightSoot : '',
            HeightManual : '',
            Pinning :'',
            PinningSoot : '',
            Type : '',
            InOut : '',
            CalculationType : '',
            DPSP : '',
            Box : '',
            TLength : '',
            TLM : '',
            LPlus : '',
            THeight : '',
            THM : '',
            HPlus : '',
            L : '',
            X : '',
            H : '',
            SheetQty : '',
            IsCompleted : '',
            Paste : '',
            Print : '',
            Printdesc : ''

        } 
        Array.prototype.push.apply(rowsData, data);
       
        setRowsData([...rowsData, rowsInput])
        console.log(rowsData);

    }

    const controls = useRef([]);

    const handler = (index ,evnt) => {

    
            if (evnt.key == 'Enter') {

              const next = controls.current[index + 'itemtype'];
              next.focus();
              
      
        evnt.preventDefault();
      }
    };


   
   const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
   }
 







    return(
        <div className="table-responsive">
                <table className="" style={{"padding": "0" , "marginBottom" : "10px"}}>
                    <thead>
                      <tr>
                      <th>Sr.</th>
                      <th >Code No.</th>
                                        <th >Item Type</th>
                                        <th >Ply</th>
                                        <th >Ply Mar</th>
                                        <th >Length</th>
                                        <th ></th>
                                        <th >L (M)</th>
                                        <th ></th>
                                        <th >Weight</th>
                                        <th ></th>
                                        <th >W (M)</th>
                                        <th ></th>
                                        <th >Height</th>
                                        <th ></th>
                                        <th >H (M)</th>
                                        <th ></th>
                                        <th >Pinning</th>
                                        <th ></th>
                                        <th >Type</th>
                                        <th >In/Out</th>
                                        <th >Calculation</th>
                                        <th >DP/SP</th>
                                        <th >Box</th>
                                        <th >T Length</th>
                                        <th >T L(M)</th>
                                        <th >L(+)</th>
                                        <th >T Height</th>
                                        <th >T H(M)</th>
                                        <th >H(+)</th>
                                        <th >L</th>
                                        <th >X</th>
                                        <th >H</th>
                                        <th >Sheet Qty</th>
                                        <th >Completed</th>
                                        <th >Paste</th>
                                        <th >Print</th>
                                        <th >Print Desc</th>
                                        <th>Del</th>
                                        <th>Add</th>
                          {/* <th><button type="button" className="btn btn-outline-success" onClick={addTableRows} >+</button></th> */}
                      </tr>
                    </thead>
                   <tbody>
                   <TableRows rowsData={data}  deleteTableRows={deleter} handleChange={edit} addTableRows ={add} itemType ={itemType} printdesc={printdesc}  ply={ply}  calculationtype={calculationtype} plyquantity={plyquantity}/>
                   </tbody> 
                </table>
              
        </div>
    )
}
export default AddDeleteTableRows