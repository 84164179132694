import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "react-switch";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
  Fn_ChangeStateValue,
} from "../../store/functions";

export const DateString = dd => {
  let d1 = dd.replace("-", "");
  let d2 = d1.replace("-", "");

  return d2;
};

class pageAddEdit_ItemType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,

      formData: {},
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1: "",
      BrowseImage2: "",
      UserImage: "",
      Signature: "",
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid: 0,
      name: "",
      email: "",
      aid: 0,
      fo3: false,
      Gender: 0,
      MaritalStatus: 0,
      success_msg: false,
      F_MISScheme: 0,
      IsOvertime: false,
      EmpBasic: [],
    };
    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Item";
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/ItemType";
    this.API_URL_SAVE = API_WEB_URLS.ITEMTYPEMASTER + "/0/token";
    this.pushFormName = "/ItemType";
    this.rtPage_Print = "/membersynopsisprint";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.btnGoBack_onClick = this.btnGoBack_onClick.bind(this);
  }

  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });


    //Filling DropDowns
     Fn_FillListData(
      this.obj,
      "CompanyMaster",
      API_WEB_URLS.MASTER + "/0/token/Company/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "GroupMaster",
      API_WEB_URLS.MASTER + "/0/token/Group/Id/0"
    );
    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }

     Fn_FillListData(
       this.obj,
       "Unit",
       API_WEB_URLS.MASTER + "/0/token/Unit/Id/0"
     );

  }

  btnSave_onClick(event, formData) {
    let vformData = new FormData();

    vformData.append("Name", formData.ItemName);
    vformData.append("GroupName", formData.GroupName);
    vformData.append("F_CompanyMaster", formData.F_CompanyMaster);
    vformData.append("F_GroupMaster", formData.F_GroupMaster);
    vformData.append("Alias", formData.Alias);
    vformData.append("PrintName", formData.PrintName);
    vformData.append("HSNCode", formData.HSNCode);
    vformData.append("F_UnitMaster", formData.F_UnitMaster);
    vformData.append("ReorderQty", formData.ReorderQty);
    vformData.append("MaxQty", formData.MaxQty);
    vformData.append("Rate1", formData.Rate1);
    vformData.append("Rate2", formData.Rate2);
    vformData.append("Rate3", formData.Rate3);
    vformData.append("PurchaseRate", formData.PurchaseRate);

    if (!this.state.id) {
      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        this.pushFormName,
        true,
        "memberid"
      );
    } else
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, formData: vformData } },
        this.API_URL_SAVE,
        this.pushFormName,
        true,
        "kuku"
      );
  }

  btnGoBack_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <TabContent
                            activeTab={this.state.activeTab}
                            className="body"
                          >
                            <TabPane tabId={1}>
                              <Row>
                                <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4"></h4>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Company"
                                            className="col-form-label"
                                          >
                                            Company
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField
                                            name="F_CompanyMaster"
                                            label=""
                                            value={
                                              this.state.formData
                                                .F_CompanyMaster === null
                                                ? "-1"
                                                : this.state.formData
                                                    .F_CompanyMaster
                                            }
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.CompanyMaster
                                              ? this.state.CompanyMaster.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="GroupName"
                                            className="col-form-label"
                                          >
                                            Group Name
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField
                                            name="F_GroupMaster"
                                            label=""
                                            value={
                                              this.state.formData
                                                .F_GroupMaster === null
                                                ? "-1"
                                                : this.state.formData
                                                    .F_GroupMaster
                                            }
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.GroupMaster
                                              ? this.state.GroupMaster.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="ItemName"
                                            className="col-form-label"
                                          >
                                            Item Name
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="ItemName"
                                            label=""
                                            value={
                                              this.state.formData.ItemName ===
                                              null
                                                ? ""
                                                : this.state.formData.ItemName
                                            }
                                            placeholder="Enter ItemName"
                                            errorMessage="Enter ItemName"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Alias"
                                            className="col-form-label"
                                          >
                                            Alias
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="Alias"
                                            label=""
                                            value={
                                              this.state.formData.Alias === null
                                                ? ""
                                                : this.state.formData.Alias
                                            }
                                            placeholder="Enter Alias"
                                            errorMessage="Enter Alias"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="PrintName"
                                            className="col-form-label"
                                          >
                                            Print Name
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="PrintName"
                                            label=""
                                            value={
                                              this.state.formData.PrintName ===
                                              null
                                                ? ""
                                                : this.state.formData.PrintName
                                            }
                                            placeholder="Enter PrintName"
                                            errorMessage="Enter PrintName"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="firstName"
                                            className="col-form-label"
                                          >
                                            Unit
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="F_UnitMaster"
                                            label=""
                                            value={
                                              this.state.formData
                                                .F_UnitMaster === null
                                                ? "-1"
                                                : this.state.formData
                                                    .F_UnitMaster
                                            }
                                            type="select"
                                            className="form-select"
                                          >
                                            <option
                                              value={-1}
                                              defaultValue
                                              label={"Select"}
                                            />
                                            {this.state.Unit
                                              ? this.state.Unit.map(
                                                  (option, key) => (
                                                    <option
                                                      key={option.Id}
                                                      value={option.Id}
                                                      label={option.Name}
                                                    />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <h4 className="card-title mb-4"></h4>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="firstName"
                                            className="col-form-label"
                                          >
                                            HSN Code
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="HSNCode"
                                            label=""
                                            value={
                                              this.state.formData.HSNCode ===
                                              null
                                                ? ""
                                                : this.state.formData.HSNCode
                                            }
                                            placeholder="Enter Code"
                                            errorMessage="Enter Code"
                                            validate={{
                                              required: { value: false },
                                            }}
                                            type="Number"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="ReorderQty"
                                            className="col-form-label"
                                          >
                                            Reorder Qty
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="ReorderQty"
                                            label=""
                                            value={
                                              this.state.formData.ReorderQty ===
                                              null
                                                ? ""
                                                : this.state.formData.ReorderQty
                                            }
                                            placeholder="Enter ReorderQty"
                                            errorMessage="Enter ReorderQty"
                                            validate={{
                                              required: { value: false },
                                            }}
                                            type="Number"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="MaxQty"
                                            className="col-form-label"
                                          >
                                            MaxQty
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="MaxQty"
                                            label=""
                                            value={
                                              this.state.formData.MaxQty ===
                                              null
                                                ? ""
                                                : this.state.formData.MaxQty
                                            }
                                            placeholder="Enter MaxQty"
                                            errorMessage="Enter MaxQty"
                                            validate={{
                                              required: { value: false },
                                            }}
                                            type="Number"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Rate1"
                                            className="col-form-label"
                                          >
                                            Rate1
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="Rate1"
                                            label=""
                                            value={
                                              this.state.formData.Rate1 === null
                                                ? ""
                                                : this.state.formData.Rate1
                                            }
                                            placeholder="Enter Rate1"
                                            errorMessage="Enter Rate1"
                                            validate={{
                                              required: { value: false },
                                            }}
                                            type="Number"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Rate2"
                                            className="col-form-label"
                                          >
                                            Rate2
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="Rate2"
                                            label=""
                                            value={
                                              this.state.formData.Rate2 === null
                                                ? ""
                                                : this.state.formData.Rate2
                                            }
                                            placeholder="Enter Rate2"
                                            errorMessage="Enter Rate2"
                                            validate={{
                                              required: { value: false },
                                            }}
                                            type="Number"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="Rate3"
                                            className="col-form-label"
                                          >
                                            Rate3
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="Rate3"
                                            label=""
                                            value={
                                              this.state.formData.Rate3 === null
                                                ? ""
                                                : this.state.formData.Rate3
                                            }
                                            placeholder="Enter Rate3"
                                            errorMessage="Enter Rate3"
                                            validate={{
                                              required: { value: false },
                                            }}
                                            type="Number"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label
                                            htmlFor="PurchaseRate"
                                            className="col-form-label"
                                          >
                                            Purchase Rate
                                          </label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField
                                            name="PurchaseRate"
                                            label=""
                                            value={
                                              this.state.formData
                                                .PurchaseRate === null
                                                ? ""
                                                : this.state.formData
                                                    .PurchaseRate
                                            }
                                            placeholder="Enter PurchaseRate"
                                            errorMessage="Enter PurchaseRate"
                                            validate={{
                                              required: { value: false },
                                            }}
                                            type="Number"
                                            className="form-control"
                                          />
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                              <Row></Row>
                              <Row></Row>
                            </TabPane>
                          </TabContent>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                            >
                              Save
                            </Button>
                            &nbsp;
                            <Button
                              type="button"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                              onClick={this.btnGoBack_onClick}
                            >
                              Go Back
                            </Button>
                            {this.state.success_msg ? (
                              <SweetAlert
                                title="Member Saved Successfully!"
                                success
                                confirmBtnBsStyle="success"
                                onConfirm={this.syno}
                              >
                                You clicked the button!
                              </SweetAlert>
                            ) : null}
                          </div>
                        </AvForm>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              this.state.activeTab === 1
                                ? "save disabled"
                                : "save"
                            }
                          ></li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_ItemType);
