import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import { withRouter } from "react-router-dom";

// Editable
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_DisplayData,
  Fn_AddEditData,
  Fn_FillListData,
} from "../../store/functions";

export const DateString = (dd) => {
  let d1 = dd.replace("-", "");
  let d2 = d1.replace("-", "");

  return d2;
};

class pageAddEdit_Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: { OpeningBalanceCr: 0, OpeningBalanceDr: 0 },
      activeTab: 1,
      passedSteps: [1],
      BrowseImage1: "",
      BrowseImage2: "",
      UserImage: "",
      Signature: "",
      // dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid: 0,
      name: "",
      email: "",
      aid: 0,
      fo3: false,
      Gender: 0,
      MaritalStatus: 0,
      success_msg: false,
      F_MISScheme: 0,
      IsOvertime: false,
      EmpBasic: [],
      BalanceType: ["Cr", "Dr"],
      toggleSwitch: false, // new state for the toggle switch
    };
    this.obj = this;
    this.formTitle = "Ledger";
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Ledger";
    this.API_URL_SAVE = API_WEB_URLS.Ledger + "/0/token";
    this.pushFormName = "/Ledger";
    this.rtPage_Print = "/membersynopsisprint";

    // Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.btnGoBack_onClick = this.btnGoBack_onClick.bind(this);
    this.handleOpeningBalanceCrChange = this.handleOpeningBalanceCrChange.bind(
      this
    );
    this.handleOpeningBalanceDrChange = this.handleOpeningBalanceDrChange.bind(
      this
    );
    this.handleToggleSwitch = this.handleToggleSwitch.bind(this); // bind the toggle switch handler

    this.API_URLDEL = API_WEB_URLS.MASTER + "/0/token/Ledger";
  }

  componentDidMount() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    this.setState({
      name: obj.username,
      email: obj.email,
      aid: obj.uid,
      role: obj.role,
    });

    Fn_FillListData(
      this.obj,
      "Ledger",
      API_WEB_URLS.MASTER + "/0/token/SubLedger/Id/0"
    );
    Fn_FillListData(
      this.obj,
      "LedgerGroup",
      API_WEB_URLS.MASTER + "/0/token/LedgerGroup/Id/0"
    );

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id",'Group');
    } else {
      this.setState({ id: 0 });
    }
  }

  btnSave_onClick(event, formData) {
    let vformData = new FormData();
    
    vformData.append("Name", formData.Name);
    vformData.append("F_SubLedgerGroup", formData.F_SubLedgerGroup || 0);
    vformData.append("F_LedgerGroupMaster", formData.F_LedgerGroupMaster || 0);
    vformData.append("OpeningBalanceCr", formData.OpeningBalanceCr || 0);
    vformData.append("OpeningBalanceDr", formData.OpeningBalanceDr || 0);
    vformData.append("UserId", this.state.aid);
    if (!this.state.id) {
      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, formData: vformData } },
        this.API_URL_SAVE,
        this.pushFormName,
        true,
        "memberid"
      );
    } else {
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, formData: vformData } },
        this.API_URL_SAVE,
        this.pushFormName,
        true,
        "kuku"
      );
    }
  }

  btnGoBack_onClick = (event) => {
    event.preventDefault();
    this.props.history.push(this.pushFormName);
  };

  btnCancel_onClick = (event) => {
    event.preventDefault();
    this.props.history.push(this.pushFormName);
  };

  handleOpeningBalanceCrChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        OpeningBalanceCr: value,
        OpeningBalanceDr: value ? "0" : prevState.formData.OpeningBalanceDr,
      },
    }));
  }

  handleOpeningBalanceDrChange(event) {
    const { value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        OpeningBalanceDr: value,
        OpeningBalanceCr: value ? "0" : prevState.formData.OpeningBalanceCr,
      },
    }));
  }

  handleToggleSwitch() {
    this.setState((prevState) => ({
      toggleSwitch: !prevState.toggleSwitch,
    }));
  }

  render() {
    const { toggleSwitch, formData, Ledger, LedgerGroup } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid style={{margin:'0px'}}>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card style={{width:'100vw'}}>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={this.btnSave_onClick}
                        >
                          <Row>
                            <Col lg="6">
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="toggleSwitch"
                                        className="col-form-label"
                                      >
                                        {toggleSwitch ? "Primary" : "Secondary"}
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <div
                                        onClick={this.handleToggleSwitch}
                                        className="custom-toggle"
                                      >
                                        <div
                                          className={`custom-toggle-slider ${
                                            toggleSwitch ? "on" : "off"
                                          }`}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  {toggleSwitch ? (
                                    <Row>
                                      <Col sm="4" className="mb-3">
                                        <label
                                          htmlFor="F_LedgerGroupMaster"
                                          className="col-form-label"
                                        >
                                          Ledger Group Master
                                        </label>
                                      </Col>
                                      <Col sm="6">
                                        <AvField
                                          name="F_LedgerGroupMaster"
                                          value={
                                            formData.F_LedgerGroupMaster ===
                                            null
                                              ? "-1"
                                              : formData.F_LedgerGroupMaster
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                          type="select"
                                          className="form-select"
                                        >
                                          <option value={-1} label={"Select"} />
                                          {LedgerGroup
                                            ? LedgerGroup.map((option) => (
                                                <option
                                                  key={option.Id}
                                                  value={option.Id}
                                                  label={option.Name}
                                                />
                                              ))
                                            : null}
                                        </AvField>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <Row>
                                      <Col sm="4" className="mb-3">
                                        <label
                                          htmlFor="F_SubLedgerGroup"
                                          className="col-form-label"
                                        >
                                          Sub Ledger
                                        </label>
                                      </Col>
                                      <Col sm="6">
                                        <AvField
                                          name="F_SubLedgerGroup"
                                          value={
                                            formData.F_SubLedgerGroup === null
                                              ? "-1"
                                              : formData.F_SubLedgerGroup
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                          type="select"
                                          className="form-select"
                                        >
                                          <option value={-1} label={"Select"} />
                                          {Ledger
                                            ? Ledger.map((option) => (
                                                <option
                                                  key={option.Id}
                                                  value={option.Id}
                                                  label={option.Name}
                                                />
                                              ))
                                            : null}
                                        </AvField>
                                      </Col>
                                    </Row>
                                  )}
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="Name"
                                        className="col-form-label"
                                      >
                                        Name
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="Name"
                                        value={
                                          formData.Name === null
                                            ? ""
                                            : formData.Name
                                        }
                                        placeholder=" Name"
                                        errorMessage="Enter Name"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="OpeningBalanceCr"
                                        className="col-form-label"
                                      >
                                        Opening Balance Cr
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="OpeningBalanceCr"
                                        value={
                                          formData.OpeningBalanceCr === null
                                            ? ""
                                            : formData.OpeningBalanceCr
                                        }
                                        placeholder=" Opening Balance Cr"
                                        errorMessage="Enter Opening Balance Cr"
                                        type="number"
                                        className="form-control"
                                        onChange={
                                          this.handleOpeningBalanceCrChange
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="4" className="mb-3">
                                      <label
                                        htmlFor="OpeningBalanceDr"
                                        className="col-form-label"
                                      >
                                        Opening Balance Dr
                                      </label>
                                    </Col>
                                    <Col sm="6">
                                      <AvField
                                        name="OpeningBalanceDr"
                                        value={
                                          formData.OpeningBalanceDr === null
                                            ? ""
                                            : formData.OpeningBalanceDr
                                        }
                                        placeholder=" Opening Balance Dr"
                                        errorMessage="Enter Opening Balance Dr"
                                        type="number"
                                        className="form-control"
                                        onChange={
                                          this.handleOpeningBalanceDrChange
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                            >
                              Save
                            </Button>
                            &nbsp;
                            <Button
                              type="button"
                              color="primary"
                              className="mr-1 waves-effect waves-light"
                              onClick={this.btnGoBack_onClick}
                            >
                              Go Back
                            </Button>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <style jsx>{`
          .custom-toggle {
            display: inline-block;
            width: 50px;
            height: 25px;
            background: #ccc;
            border-radius: 25px;
            position: relative;
            cursor: pointer;
          }

          .custom-toggle-slider {
            width: 23px;
            height: 23px;
            background: white;
            border-radius: 50%;
            position: absolute;
            top: 1px;
            left: 1px;
            transition: all 0.3s;
          }

          .custom-toggle-slider.on {
            left: 26px;
            background: #007bff;
          }

          .custom-toggle-slider.off {
            left: 1px;
            background: #6c757d;
          }
        `}</style>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_Ledger);
